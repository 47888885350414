import { Logger } from "aws-amplify";

import {
  SAVE_SETLIST_ERROR,
  SAVE_SETLIST_REQUEST,
  SAVE_SETLIST_SUCCESS,
  OPEN_SNACKBAR
} from "../types";
import concertify from "concertify/core";

const logger = new Logger("saveSetlistAction");

export default (performanceId, status, works) => async dispatch => {
  dispatch({
    type: SAVE_SETLIST_REQUEST
  });
  try {
    await concertify.performances.updateSetlist(performanceId, status, works);
    dispatch({
      type: SAVE_SETLIST_SUCCESS,
      payload: { Status: status, Works: works }
    });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "success",
      message: "The setlist was succesfully updated."
    });
  } catch (err) {
    logger.error(err);
    dispatch({ type: SAVE_SETLIST_ERROR });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message:
        "Something went wrong while trying to update this setlist. Please try again later."
    });
  }
};
