// @flow
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle
} from "@material-ui/core";

import { connect } from "react-redux";
import { styles } from "./styles";

type ConfirmationDialogProps = {
  /** If true, the Dialog is open */
  open: boolean,
  /** Custom Dialog title */
  dialogTitle?: string,
  /** Custom dialog text */
  customText?: string,
  /** @ignore */
  onClose?: void,
  /** Function to be called when the action is confirmed */
  onConfirm?: void
};

/** Component representing confirmation dialog, to be called when the action that is to be taken, requires additional confirmation from the user. */

export const UnconnectedConfirmationDialog = ({
  onConfirm,
  onClose,
  open,
  dialogTitle,
  customText
}: ConfirmationDialogProps) => {
  const classes = styles();
  return (
    <Dialog open={open}>
      <DialogTitle id="ConfirmationDialog" disableTypography>
        <Typography variant="h6" className={classes.root}>
          {dialogTitle ? dialogTitle : "Confirmation dialog"}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography variant="body1">
          {customText
            ? customText
            : "Are you sure you would like to perform this action?"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          id="cancel-action"
          type="button"
          data-role="cancel-action"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          id="confirm-action"
          variant="contained"
          type="button"
          data-role="confirm-action"
          onClick={onConfirm}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnconnectedConfirmationDialog.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  open: false,
  dialogTitle: "",
  customText: ""
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "StatusConfirmationDialog"
  };
};

export default connect<*, *, *, *, *, *>(
  mapStateToProps,
  null
)(UnconnectedConfirmationDialog);
