import React from "react";

import { Field } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const DateField = ({ PickerProps, KeyboardButtonProps, ...rest }) => (
  <Field {...rest}>
    {({ input }) => (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          onChange={input.onChange}
          value={input.value}
          {...PickerProps}
          KeyboardButtonProps={KeyboardButtonProps}
        />
      </MuiPickersUtilsProvider>
    )}
  </Field>
);

export default DateField;
