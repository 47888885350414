// @flow strict

import React, { useEffect, useState, Fragment } from "react";

import { connect } from "react-redux";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";

import EventNoteIcon from "@material-ui/icons/EventNote";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";

import { CLEAR_PERFORMANCE, REUSE_SETLIST } from "redux/actions/types";
import closeDialog from "redux/actions/closeDialog/closeDialog";
import addWorkToSetlist from "redux/actions/addWorkToSetlist";
import getLatestPerformancesForArtist from "redux/actions/getLatestPerformancesForArtist";
import { IsEmpty } from "utils";
import XClosableDialog from "../XClosableDialog";

const useStyles = makeStyles(theme => ({
  dialogActions: {
    padding: "16px 24px"
  },
  setlistSubtitle: {
    color: theme.palette.grey[700],
    marginBottom: 21,
    fontSize: 12
  },
  setlistList: {
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    height: 360,
    padding: 0,
    overflow: "auto"
  },
  circularProgressContainer: {
    height: "100%"
  },
  venueName: {
    color: theme.palette.grey[900],
    fontSize: 16,
    margin: "8px 0px"
  },
  setlistInfoIcon: {
    marginRight: 8,
    color: theme.palette.grey[700]
  },
  setlistInfoText: {
    color: theme.palette.grey[700],
    fontSize: 14
  }
}));

const SetlistListItem = ({ setlistData, isSelected, onClick }) => {
  const classes = useStyles();
  return (
    <ListItem
      id="reuseSetlistEntry"
      button
      selected={isSelected}
      onClick={onClick(setlistData.PerformanceId, setlistData.Setlist.Works)}
    >
      <Grid container alignItems="flex-start">
        <Typography className={classes.venueName}>
          {setlistData.Venue.Name}
        </Typography>
        <Grid container spacing={2}>
          <Grid container item xs={4} direction="row" justify="flex-start">
            <EventNoteIcon
              fontSize="small"
              className={classes.setlistInfoIcon}
            />
            <Typography className={classes.setlistInfoText}>
              {moment(setlistData.Date).format("MMMM Do YYYY")}
            </Typography>
          </Grid>
          <Grid container item xs={4} direction="row" justify="flex-start">
            <LocationOnIcon
              fontSize="small"
              className={classes.setlistInfoIcon}
            />
            <Typography className={classes.setlistInfoText}>
              {setlistData.Venue.Address.City +
                ", " +
                setlistData.Venue.Address.CountryCode}
            </Typography>
          </Grid>
          <Grid container item xs={4} direction="row" justify="flex-start">
            <QueueMusicIcon
              fontSize="small"
              className={classes.setlistInfoIcon}
            />
            <Typography
              id="reuseSetlistWorksLength"
              date-role="reuse-setlist-works-length"
              className={classes.setlistInfoText}
            >
              {setlistData.Setlist.Works.length}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

type ReuseSetlistDialogProps = {
  open: boolean,
  performanceDetails: Object,
  onAddWork: Function,
  onClearPerformance: Function,
  onLoadSetlists: Function
};

export const ReuseSetlistDialog = ({
  open,
  performanceDetails,
  onAddWork,
  onClearPerformance,
  onLoadSetlists
}: ReuseSetlistDialogProps) => {
  const [selectedSetlist, setSelectedSetlist] = useState({});
  const classes = useStyles();

  useEffect(() => {
    return function cleanup() {
      onClearPerformance();
    };
    // eslint-disable-next-line
  }, []);

  const selectSetlist = (performanceId, setlist) => () => {
    setSelectedSetlist({ performanceId, setlist });
  };

  const onDialogClose = () => {
    if (!IsEmpty(selectedSetlist)) {
      setSelectedSetlist({});
    }
  };

  const onDialogOpen = () => {
    if (IsEmpty(performanceDetails.previousSetlistForArtist)) {
      onLoadSetlists(
        performanceDetails.currentPerformanceDetails.PerformingArtist.Name
      );
    }
  };

  return (
    <XClosableDialog
      maxWidth="sm"
      fullWidth
      open={open}
      title={"Re-use setlist"}
      onExited={onDialogClose}
      onEntering={onDialogOpen}
    >
      <DialogContent>
        <Typography
          id="reuseSetlistSubtitle"
          data-role="setlist-subtitle"
          className={classes.setlistSubtitle}
        >
          {"Choose a previous setlist of " +
            (IsEmpty(
              performanceDetails.currentPerformanceDetails.PerformingArtist
            )
              ? ""
              : performanceDetails.currentPerformanceDetails.PerformingArtist
                  .Name)}
        </Typography>
        <List id="reuseSetlistList" className={classes.setlistList}>
          {performanceDetails.isFetchingSetlists ? (
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.circularProgressContainer}
            >
              <CircularProgress color="secondary" />
            </Grid>
          ) : IsEmpty(performanceDetails.previousSetlistForArtist) ? (
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.circularProgressContainer}
            >
              <Typography
                id="reuseSetlistNoSetlistMessage"
                className={classes.setlistSubtitle}
              >
                {"No setlists for this artist"}
              </Typography>
            </Grid>
          ) : (
            <Fragment>
              {performanceDetails.previousSetlistForArtist.map(
                previousSetlist => {
                  return (
                    <SetlistListItem
                      onClick={selectSetlist}
                      isSelected={
                        previousSetlist.PerformanceId ===
                        selectedSetlist.performanceId
                      }
                      setlistData={{ ...previousSetlist }}
                    />
                  );
                }
              )}
            </Fragment>
          )}
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container alignItems="flex-start">
          <Button
            id="reuseSetlistConfirm"
            data-role="confirm-setlist-button"
            disabled={IsEmpty(selectedSetlist)}
            onClick={onAddWork(selectedSetlist.setlist)}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
        </Grid>
      </DialogActions>
    </XClosableDialog>
  );
};

ReuseSetlistDialog.defaultProps = {
  open: false,
  onAddWork: () => {},
  onClearPerformance: () => {},
  onLoadSetlists: () => {},
  performanceDetails: {
    currentPerformanceDetails: {},
    previousSetlistForArtist: []
  }
};

// Redux version

const mapStateToProps = state => {
  return {
    performanceDetails: state.performanceDetailsReducer,
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === REUSE_SETLIST
  };
};

const mapDispatchToProps = dispatch => ({
  onAddWork: work => async () => {
    await dispatch(addWorkToSetlist(work));
    await dispatch(closeDialog());
  },
  onClearPerformance: () => dispatch({ type: CLEAR_PERFORMANCE }),
  onLoadSetlists: artistName =>
    dispatch(getLatestPerformancesForArtist(artistName))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReuseSetlistDialog);
