import theme from "../ConcertifyMuiTheme";

export default {
  formContainer: {
    margin: 0,
    paddingTop: "10%",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  formSection: {
    padding: 10,
    width: "fit-content"
  },
  headerSection: {},
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "10px"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.common.white,
    marginTop: 10,
    marginBottom: 0
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.grey[300],
    marginTop: 5,
    marginBottom: 0
  },
  infoMessageContainer: {
    marginTop: 24,
    marginBottom: 14
  },
  infoMessage: {
    fontFamily: "Roboto",
    textAlign: "center",
    color: theme.palette.common.white,
    fontSize: 20
  },
  bodySection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  footerSection: {
    marginTop: 10
  },
  footerSectionPrimaryContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  footerSectionSecondaryContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 0,
    color: theme.palette.common.white,
    paddingTop: "10px"
  },
  circularProgressOnButton: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 44
  },
  button: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    minWidth: 380,
    margin: "4px 0"
  },
  textField: {
    margin: "10px 0",
    width: 380,
    "& input": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[800],
      borderRadius: 5
    }
  },
  link: {
    color: theme.palette.secondary.light100,
    cursor: "pointer",
    marginLeft: 5,
    height: 25,
    textDecoration: "none"
  }
};
