import concertify from "concertify/core";
import { OPEN_SNACKBAR } from "redux/actions/types";

import {
  UPDATE_PERFORMANCE_REQUEST,
  UPDATE_PERFORMANCE_ERROR,
  UPDATE_PERFORMANCE_SUCCESS
} from "redux/actions/types";

export default (performanceId, updatedAttrs) => async dispatch => {
  dispatch({
    type: UPDATE_PERFORMANCE_REQUEST
  });
  try {
    await concertify.performances.update(performanceId, updatedAttrs);
    dispatch({ type: UPDATE_PERFORMANCE_SUCCESS, payload: updatedAttrs });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "success",
      message: "Venue information updated successfully"
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: UPDATE_PERFORMANCE_ERROR, payload: e });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message:
        "Something went wrong while trying to update this performance. Please try again later."
    });
  }
};
