import React from "react";

import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { countries } from "../utils";

const styles = theme => ({
  textField: {
    marginTop: "-5px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  menu: {
    width: 200
  }
});

const CountryPicker = props => {
  const { classes } = props;
  return (
    <div>
      <TextField
        InputLabelProps={{ shrink: true }}
        select
        label="Select"
        className={classes.textField}
        value={props.currentCountry && props.currentCountry.toLowerCase()}
        onChange={props.onCountryChange}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
        helperText="Please select your Country"
        margin="normal"
      >
        {Object.entries(countries).map(option => (
          <MenuItem key={option[0]} value={option[0]}>
            {option[1]}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

CountryPicker.propTypes = {
  currentCountry: PropTypes.string,
  onCountrychange: PropTypes.func
};

export default withStyles(styles)(CountryPicker);
