// @flow strict

import React from "react";
import Auth from "@aws-amplify/auth";
import { SignUp } from "aws-amplify-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/styles";
import Logo from "components/Logo";
import RootStyle from "../style";

const styles = () => ({
  ...RootStyle
});

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signUp"];
    this.signUpFields = [
      {
        label: "First Name",
        key: "name",
        required: true,
        displayOrder: 1,
        type: "string"
      },
      {
        label: "Last Name",
        key: "family_name",
        required: true,
        displayOrder: 2,
        type: "string"
      },
      {
        label: "Email",
        key: "email",
        required: true,
        displayOrder: 3,
        type: "email"
      },
      {
        label: "Password",
        key: "password",
        required: true,
        displayOrder: 4,
        type: "password"
      }
    ];
  }

  validateEmail(email) {
    //eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleSignUp = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ requestPending: true });
    if (!this.inputs.dial_code) {
      this.inputs.dial_code = this.getDefaultDialCode();
    }
    const validation = this.validate();
    if (validation && validation.length > 0) {
      this.setState({ requestPending: false });
      return this.error(
        `The following fields need to be filled out: ${validation.join(", ")}`
      );
    }

    const isEmailValid = this.validateEmail(this.inputs.email);

    if (!isEmailValid) {
      this.setState({ requestPending: false });
      return this.error(`Invalid email address`);
    }

    if (!Auth || typeof Auth.signUp !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    const signup_info = {
      username: this.inputs.email.replace("@", "."),
      password: this.inputs.password,
      attributes: {}
    };

    const inputKeys = Object.keys(this.inputs);
    const inputVals = Object.values(this.inputs);

    inputKeys.forEach((key, index) => {
      if (
        !["username", "password", "checkedValue", "dial_code"].includes(key)
      ) {
        if (
          key !== "phone_line_number" &&
          key !== "dial_code" &&
          key !== "error"
        ) {
          const newKey = `${this.needPrefix(key) ? "custom:" : ""}${key}`;
          signup_info.attributes[newKey] = inputVals[index];
        }
      }
    });

    Auth.signUp(signup_info)
      .then(data => {
        this.setState({ requestPending: false });
        // @ts-ignore
        this.changeState("confirmSignUp", data.user.username);
      })
      .catch(err => {
        this.setState({ requestPending: false });
        if (
          typeof err !== "string" &&
          !!(
            err.message &&
            err.message.match("PreSignUp failed with error Domain not allowed:")
          )
        ) {
          err.message =
            "Thank you! Please check your email for further instructions! If you don’t receive an email, please contact support@concertify.io.";
        }
        return this.error(err);
      });
  };

  showComponent(theme) {
    const { classes } = this.props;
    const { requestPending } = this.state;
    return (
      <div className={classes.formContainer}>
        <div className={classes.formSection}>
          <div className={classes.headerSection}>
            <div className={classes.logoContainer}>
              <Logo size="large" />
            </div>
            <h3 className={classes.title}>Create a new account</h3>
          </div>
          <form onSubmit={this.handleSignUp}>
            <div className={classes.bodySection}>
              <TextField
                className={classes.textField}
                name="name"
                variant="outlined"
                placeholder="First Name"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
              <TextField
                className={classes.textField}
                name="family_name"
                variant="outlined"
                placeholder="Last Name"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
              <TextField
                className={classes.textField}
                name="email"
                variant="outlined"
                placeholder="Email"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
              <TextField
                className={classes.textField}
                name="password"
                variant="outlined"
                placeholder="Password"
                autoComplete="new-password"
                type="password"
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.footerSection}>
              <div className={classes.footerSectionPrimaryContent}>
                {requestPending ? (
                  <div className={classes.circularProgressOnButton}>
                    <CircularProgress size={25} color="secondary" />
                  </div>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Create account
                  </Button>
                )}
              </div>
              <div className={classes.footerSectionSecondaryContent}>
                <span>Have an account?</span>
                <a
                  href="/#"
                  className={classes.link}
                  onClick={() => {
                    super.changeState("signIn");
                  }}
                >
                  Sign in
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomSignUp);
