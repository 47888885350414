import React from "react";

import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

const MoneyField = ({ placeholder, ...props }) => (
  <Field
    placeholder={placeholder || "500,325.50"}
    {...props}
    component={TextField}
  />
);

export default MoneyField;
