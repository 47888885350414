// @flow strict
import React from "react";
import type { Node } from "react";

import { connect } from "react-redux";
import closeDialog from "redux/actions/closeDialog";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  title: {
    color: theme.palette.grey[700],
    fontSize: 24
  }
}));

type XClosableDialogProps = {
  /** If true, the Dialog is open. */
  open: boolean,
  /** Title text of the dialog */
  title?: string,
  /** Content which should be displayed in dialog*/
  children?: Node,
  /** @ignore */
  onClose?: void
};

/** Component representing dialog which has implemented title part with custom title text and icon for closing it.
 * For base is used "Dialog" component from @material-ui library. You are able to pass any other "Dialog" prop and it will be forwarded to it inside this component. */
export const XClosableDialog = ({
  open,
  onClose,
  title,
  children,
  ...rest
}: XClosableDialogProps) => {
  const classes = styles();
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>
        <Grid container alignItems="center" justify="space-between">
          <span data-name="dialog-title" className={classes.title}>
            {title}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

XClosableDialog.defaultProps = {
  open: false
};

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeDialog())
});

export default connect<*, *, *, *, *, *>(
  null,
  mapDispatchToProps
)(XClosableDialog);
