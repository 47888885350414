import React from "react";
import PropTypes from "prop-types";

import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";

import ReduxSnackbar from "components/Snackbar";

const styles = {
  root: {
    height: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#D9DFE3"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    minHeight: "100%",
    background: "#e7ebed",
    borderLeft: "1px solid #c5cfd3",
    borderRight: "1px solid #c5cfd3"
  }
};

const MainWrapper = ({ classes, children }) => (
  <div className={classes.root}>
    <Container disableGutters maxWidth="lg" className={classes.wrapper}>
      {children}
    </Container>
    <ReduxSnackbar />
  </div>
);

MainWrapper.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object
};

export default withStyles(styles)(MainWrapper);
