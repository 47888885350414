import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  FormControlLabel
} from "@material-ui/core";

import { connect } from "react-redux";
import { styles } from "./styles";

export const TermsOfServiceDialog = ({ onAccept, open }) => {
  const classes = styles();
  const [checked, setChecked] = useState(false);

  return (
    <Dialog open={open} scroll="paper" className={classes.dialog}>
      <DialogTitle id="TermsOfServiceDialog" disableTypography>
        <Typography variant="h6" className={classes.dialogTitle}>
          Terms of Service
        </Typography>
        <Typography variant="body2" className={classes.dialogSubtitle}>
          You must read carefully and accept the Terms of Service before using
          Concertify:
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} className={classes.dialogContent}>
        <ol start="1">
          <li>
            <h3>SCOPE AND BACKGROUND OF AGREEMENT</h3>
            <p>
              Mind Your Rights Oy (Business ID 2863486-5) (“MYR”) is a Finnish
              technology company specializing in creating services and solutions
              for music rights management. MYR’s vision is to make global
              managing of music rights faster as well as more efficient and
              transparent for music authors and publishers and has developed a
              Software as a Service called Concertify for such purposes.
            </p>
            <p>
              These Terms of Service (“Agreement”) govern User Organization’s
              use of Concertify. Please read this Agreement carefully. This
              Agreement contain the terms and conditions between MYR and User
              Organization regarding User Organization’s access and use of
              Concertify described below.
            </p>
            <p>
              By accepting this Agreement as part of the registration/login
              procedure to Concertify (by clicking “I have read and accept the
              Terms of Service”), User Organization will be bound by this
              Agreement and accepts the terms of this Agreement in their
              entirety.
            </p>
            <p>
              MYR and User Organization are individually referred to as a
              “Party” and collectively the “Parties”.
            </p>
          </li>
          <li>
            <h3>DEFINITIONS</h3>
            <ol start="1">
              <li>
                <p>
                  <strong>Author</strong> - User Organization’s member or
                  customer or a third party collective management organization’s
                  member or customer that the User Organization is representing
                  based on a reciprocal representation agreement whose Personal
                  Data is processed by MYR on behalf of the User Organization
                  under this Agreement.
                </p>
              </li>
              <li>
                <p>
                  <strong>Authorized User</strong> – an individual authorized by
                  User Organization to use Concertify who is acting in his/her
                  capacity as a representative of User Organization (such as
                  User Organization’s employee, member, customer or User
                  Organization’s member’s/customer’s representative).
                </p>
              </li>
              <li>
                <p>
                  <strong>Concertify</strong> – Standard Solution of Concertify
                  provided by MYR to User Organization under this Agreement.
                </p>
              </li>
              <li>
                <p>
                  <strong>Data Protection Legislation</strong> – EU Regulation
                  2016/679 on the protection of natural persons with regard to
                  the processing of personal data and on the free movement of
                  such data (the “GDPR”) and any other applicable European or
                  foreign data protection laws as amended.
                </p>
              </li>
              <li>
                <p>
                  <strong>Intellectual Property</strong> – any and all patents,
                  utility models, design rights, copyrights (including the right
                  to amend, modify, develop and assign), trademarks, trade
                  names, inventions, trade secrets, domain names, designs,
                  know-how, database rights and any other industrial or
                  intellectual property rights (including applications thereof
                  and both registered and unregistered rights).
                </p>
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong> – any information relating to
                  Authorized Users and/or Authors which MYR processes on behalf
                  of User Organization in the course and within the scope of
                  providing Concertify to User Organization.
                </p>
              </li>
              <li>
                <p>
                  <strong>Security Breach </strong> – any accidental,
                  unauthorised or unlawful destruction, loss, alteration, or
                  disclosure of, or access to the Personal Data.
                </p>
              </li>
              <li>
                <p>
                  <strong>User Organization</strong> – a collective management
                  organization using Concertify pursuant to this Agreement
                </p>
              </li>
              <li>
                <p>
                  <strong>User Organization Content</strong> – any content or
                  information User Organization, Authorized Users and/or a third
                  party authorized by User Organization, if applicable, submit
                  to MYR to be processed in connection with the provision of
                  Concertify.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>GRANT OF RIGHTS</h3>
            <ol start="1">
              <li>
                <p>
                  Subject to compliance with the terms agreed herein, MYR grants
                  and User Organization hereby accepts a non-exclusive,
                  non-transferable, and non-sublicensable right to use
                  Concertify during the term of this Agreement solely for User
                  Organization’s own business purposes and not for the purposes
                  of providing a service competing with Concertify.
                </p>
              </li>
              <li>
                <p>
                  All rights not expressly granted to User Organization are
                  reserved by MYR and its licensors, as the case may be. MYR
                  reserves the right to make changes, modifications, reduction
                  in functionality and enhancements to Concertify, at any time,
                  and from time to time without prior notice.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>RESPONSIBILITIES OF USER ORGANIZATION</h3>
            <ol start="1">
              <li>
                <p>
                  User Organization shall ensure that the Authorized Users use
                  Concertify in compliance with this Agreement. Misuse of
                  Concertify by User Organization or any Authorized User may
                  lead to termination of this Agreement or suspension or denial
                  of access to Concertify.
                </p>
              </li>
              <li>
                <p>
                  User Organization is responsible for preparing its hardware,
                  connections, software and data systems to meet the operating
                  environment of Concertify. The use of Concertify requires a
                  functioning connectivity to internet.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>USER ACCOUNTS</h3>
            <ol start="1">
              <li>
                <p>
                  MYR grants User Organization one or several user accounts with
                  usernames and passwords. The amount of user accounts shall be
                  separately agreed between the Parties. Only Authorised Users
                  are allowed to access and use Concertify through User
                  Organization’s user accounts.
                </p>
              </li>
              <li>
                <p>
                  User Organization is responsible for all activity occurring
                  under User Organization’s designated user accounts and shall
                  comply with all applicable laws and regulations in connection
                  with Authorized Users’ use of Concertify. User Organization
                  shall notify MYR immediately of any unauthorized use of any
                  password or user account provided to the User Organization or
                  any other known or suspected breach of security with respect
                  to Concertify.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>USER ORGANIZATION CONTENT</h3>
            <ol start="1">
              <li>
                <p>
                  User Organization, Authorized Users and third parties
                  authorized by User Organization, if applicable, provide MYR
                  with User Organization Content in the course of this Agreement
                  in order to enable the provision of Concertify in accordance
                  with this Agreement.
                </p>
              </li>
              <li>
                <p>
                  User Organization grants MYR a non-exclusive,
                  non-sublicensable and, save as otherwise expressly set out in
                  this Agreement, non-transferable right to use (subject to
                  applicable Data Protection Legislation) User Organization
                  Content during the term of this Agreement for the purpose of
                  providing Concertify. All rights relating to User Organization
                  Content not expressly granted to MYR are reserved by User
                  Organization and/or its licensors, as the case may be.
                </p>
              </li>
              <li>
                <p>
                  For clarity, the right granted to MYR in Section 6.2 covers
                  MYR’s right to grant access to User Organization Content to
                  Authorized Users, other users of Concertify when necessary for
                  the purpose of providing Concertify to User Organization as
                  well as to MYR’s authorized subprocessors subject to Data
                  Protection Legislation.
                </p>
              </li>
              <li>
                <p>
                  User Organization is responsible for having obtained all
                  necessary rights to add User Organization Content to
                  Concertify to be used in accordance with this Agreement.
                </p>
              </li>
              <li>
                <p>
                  Subject to Data Protection Legislation, MYR has the right to
                  collect and generate anonymous data and statistics from User
                  Organization Content (“Aggregate Data”) for updates and
                  development of Concertify. The Intellectual Property Rights
                  and title to Aggregate Data shall belong to MYR.
                </p>
              </li>
              <li>
                <p>
                  If any User Organization Content violates this Agreement,
                  intellectual property rights or any applicable law, MYR shall
                  have the right to delete such User Organization Content from
                  Concertify.
                </p>
              </li>
              <li>
                <p>
                  Concertify is not intended to be used as the sole storage
                  location for User Organization Content.
                </p>
              </li>
              <li>
                <p>
                  User Organization Content includes also Personal Data. MYR
                  processes such Personal Data on behalf of User Organization as
                  a data processor and accordingly is subject to Data Protection
                  Legislation. Terms relating to processing of Personal Data are
                  set out below in Section 7.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>DATA PROCESSING TERMS</h3>
            <ol start="1">
              <li>
                <p>
                  MYR processes certain Personal Data on behalf of User
                  Organization as data processor or data sub-processor for the
                  purpose of providing Concertify for the use of User
                  Organization (“Purpose”). The terms and conditions of this
                  Section 7 concern the data processing activities of MYR as
                  data processor or data sub-processor with respect to the
                  Personal Data it processes on behalf of the User Organization
                  acting as a data controller or data processor.
                </p>
              </li>
              <li>
                <p>
                  In connection with the Purpose, MYR may process Personal Data
                  of Authors and Authorized Users.
                </p>
              </li>
              <li>
                <p>
                  The Personal Data may include i) Authors’ and Authorized
                  Users’ first and last names; ii) Authors’ performer names;
                  iii) titles of the musical works the right owners of which
                  Authors are; iv) CAE numbers of Authors; v) place and date of
                  Authors’ live performances; vi) Authorized Users’ user
                  credentials; vii) communication data of Authorized Users,
                  including but not limited to data relating to royalties
                  payable to Authors and the estimated date on which the
                  royalties are paid.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>
                General requirements relating to the processing of Personal Data
              </p>
              <li>
                <p>
                  User Organization shall be considered as the data controller
                  and MYR as the data processor or data sub-processor with
                  respect to, and to the extent, the aforementioned data
                  provided by Authorized Users, other users of Concertify and/or
                  and/or third party service providers, if applicable, include
                  Personal Data.
                </p>
              </li>
              <li>
                <p>
                  MYR may also process certain personal data as a data
                  controller in connection with the Purpose. Such personal data
                  may include, inter alia, data of User Organization’s contact
                  persons, Authorized Users’ user credentials and payment
                  details. MYR processes such personal data in accordance with
                  its Privacy Policy in force from time to time and available on
                  MYR’s website.
                </p>
              </li>
              <li>
                <p>
                  User Organization may process certain Personal Data also as a
                  processor on behalf of a third-party controller. In case MYR
                  processes such data as a sub-processor of User Organization,
                  this Section 7 shall apply, mutatis mutandis, to such
                  processing, i.e. the rights and responsibilities of the
                  Parties set out in this Section shall also apply when User
                  Organization acts in the role of a data processor and MYR acts
                  in the role of a data sub-processor.
                </p>
              </li>
              <li>
                <p>
                  User Organization shall be responsible for the lawful
                  collection, processing and use, and for the accuracy of the
                  Personal Data, as well as for preserving the rights of the
                  individuals concerned. If and to the extent legally required,
                  User Organization shall inform the individuals concerned
                  regarding the processing of their Personal Data by MYR and
                  shall obtain their consent if necessary.
                </p>
              </li>
              <li>
                <p>
                  User Organization shall ensure that User Organization is
                  entitled to transfer the Personal Data to MYR so that MYR may
                  lawfully process the Personal Data on behalf of User
                  Organization in accordance with this Agreement.
                </p>
              </li>
              <li>
                <p>
                  MYR shall not use Personal Data for any purpose other than
                  that of providing Concertify and will not assert liens or
                  other rights over or sell or disclose the Personal Data to any
                  third parties, without User Organization’s prior written
                  approval. MYR shall process Personal Data in accordance with
                  this Agreement and documented instructions from User
                  Organization. User Organization’s instructions must be
                  commercially reasonable, compliant with Data Protection
                  Legislation and consistent with this Agreement. MYR shall not
                  be obliged to verify whether any instruction given by User
                  Organization is consistent with applicable laws, as User
                  Organization is responsible for such compliance verification
                  of its instructions. However, if MYR detects that any
                  instruction given by User Organization is non-compliant with
                  the requirements of any Data Protection Legislation applicable
                  to MYR’s operations, MYR shall inform User Organization in
                  writing.
                </p>
              </li>
              <li>
                <p>
                  MYR and User Organization shall comply with the Data
                  Protection Legislation.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>MYR’s obligations</p>
              <li>
                <span>
                  MYR shall implement and maintain appropriate technical and
                  organizational security measures to protect the Personal Data
                  within its area of responsibility, in order to safeguard the
                  Personal Data against unauthorised or unlawful processing or
                  access and against accidental loss, destruction or damage.
                  Such measures include where necessary and appropriate, taking
                  into account the state of the art, the costs of implementation
                  and the nature, scope, context and purposes of processing as
                  well as the risk of varying likelihood and severity for the
                  rights and freedoms of natural persons, the following
                  measures:
                  <ol type="i">
                    <li>
                      <p>
                        access right controls to systems containing Personal
                        Data;
                      </p>
                    </li>
                    <li>
                      <p>
                        the pseudonymisation and encryption of Personal Data;
                      </p>
                    </li>
                    <li>
                      <p>
                        the ability to ensure the ongoing confidentiality,
                        integrity, availability and resilience of processing
                        systems and Concertify;
                      </p>
                    </li>
                    <li>
                      <p>
                        the ability to restore the availability and access to
                        Personal Data in a timely manner in the event of a
                        physical or technical incident;
                      </p>
                    </li>
                    <li>
                      <p>
                        a process for regularly testing, assessing and
                        evaluating the effectiveness of technical and
                        organizational measures for ensuring the security of the
                        processing.
                      </p>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <p>
                  To respond to requests from individuals exercising their
                  rights as foreseen in Data Protection Legislation, such as the
                  right of access and the right to rectification or erasure, MYR
                  shall provide User Organization with commercially reasonable
                  assistance, without undue delay, taking into account the
                  nature of the processing.
                </p>
              </li>
              <li>
                <p>
                  MYR shall further provide User Organization with commercially
                  reasonable assistance in ensuring compliance with User
                  Organization’s obligations to perform security and data
                  protection assessments, breach notifications and prior
                  consultations of the competent supervisory authority, as set
                  out in the applicable Data Protection Legislation, taking into
                  account the nature of the processing and the information
                  available to MYR. In case such assistance requires extensive
                  measures from MYR, User Organization shall pay additional
                  reasonable remuneration to MYR for handling such assistance
                  requests
                </p>
              </li>
              <li>
                <p>
                  MYR shall ensure that the persons processing Personal Data
                  have committed themselves to confidentiality or are under an
                  appropriate statutory obligation of confidentiality.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>Transfers of Personal Data</p>
              <li>
                <p>
                  The Parties accept that Personal Data may be processed and
                  accessible by certain third party user organizations of
                  Concertify outside the European Economic Area in order to
                  enable the provision of Concertify in accordance with the
                  Purpose. In case Personal Data is processed outside the
                  European Economic Area and the processing is subject to the
                  GDPR, the Personal Data may only be transferred outside the
                  EEA (i) where the transfer is subject to a GDPR Article 49
                  derogation; (ii) where the country is recognized by the
                  European Commission as providing an adequate level of
                  protection for personal data; (iii) where appropriate
                  safeguards are provided by standard contractual clauses,
                  adopted or approved by the European Commission and applicable
                  to the processing of Personal Data outside the EEA; or (iv)
                  where another appropriate safeguard foreseen under the GDPR
                  applies to such transfer. User Organization shall be
                  responsible for ensuring that the transfer of Personal Data
                  outside the EEA through Concertify to applicable ex-EEA
                  organizations is conducted in accordance with the GDPR.
                </p>
              </li>
              <li>
                <p>
                  Personal Data shall not be transferred outside the EEA to any
                  other transferees or for any other purpose.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>Audits</p>
              <li>
                <p>
                  User Organization shall have the right, not more than once in
                  every calendar year, to audit the facilities and processing
                  activities of MYR under the Agreement to examine the level of
                  protection and security provided for Personal Data processed
                  under the Agreement and to assess the compliance of MYR with
                  the terms and conditions relating to Personal Data set out
                  herein. User Organization agrees to notify MYR of any audits
                  no later than 30 days prior to commencing the audit. User
                  Organization shall bear the costs for any such audit.
                </p>
              </li>
              <li>
                <p>
                  Where an audit may lead to the disclosure of business or trade
                  secrets of MYR or threaten intellectual property rights of
                  MYR, User Organization shall employ an independent expert to
                  carry out the audit, and the expert shall agree to be bound to
                  confidentiality to MYR’s benefit.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>Sub-processors</p>
              <li>
                <p>
                  <strong>General authorization</strong>. User Organization
                  gives its general authorization to allow MYR to involve MYR’s
                  affiliated companies and other subcontractors as
                  sub-processors to process Personal Data in connection with the
                  provision of Concertify, to the extent such appointment does
                  not lead to non-compliance with any applicable law or MYR’s
                  obligations under this Agreement.
                </p>
              </li>
              <li>
                <p>
                  MYR ensures that the involved sub-processors are properly
                  qualified, will be under a data processing agreement with MYR,
                  and comply with data processing obligations similar to the
                  ones which apply to MYR under this Agreement. MYR shall be
                  liable towards User Organization for the processing of
                  Personal Data carried out by MYR’s sub-processors.
                </p>
              </li>
              <li>
                <p>
                  <strong>Change of sub-processor</strong>. MYR is free to
                  choose and change its sub-processors. Upon request, MYR shall
                  inform User Organization of sub-processors currently involved.
                  In case there is a later change of sub-processor (addition or
                  replacement), MYR shall notify User Organization of such
                  change. In case User Organization objects such change of
                  sub-processor on reasonable grounds, User Organization has the
                  right to request change of the sub-processor. If MYR is not
                  willing to change the sub-processor User Organization has
                  objected, each Party has the right to terminate this
                  Agreement.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>Security Breaches</p>
              <li>
                <span>
                  <p>
                    MYR shall, without undue delay after having become aware of
                    it, inform User Organization in writing about Security
                    Breaches relating to Personal Data. MYR’s notification about
                    the Security Breach to User Organization shall include at
                    least the following:
                  </p>
                  <ol type="i">
                    <li>description of the nature of the Security Breach;</li>
                    <li>
                      name and contact details of MYR’s contact point where more
                      information can be obtained;
                    </li>
                    <li>
                      description of the measures taken by MYR to address the
                      Security Breach, including, where appropriate, measures to
                      mitigate its possible adverse effects
                    </li>
                  </ol>
                </span>
              </li>
              <p style={{ fontStyle: "italic" }}>
                Deletion and return of Personal Data
              </p>
              <li>
                <p>
                  Within a reasonable time after the termination or expiry of
                  this Agreement or after User Organization has permanently
                  ceased to use Concertify, MYR shall permanently delete
                  Personal Data from its storage media, except to the extent
                  that MYR is under a statutory obligation to continue storing
                  such Personal Data.
                </p>
              </li>
              <p style={{ fontStyle: "italic" }}>Liability</p>
              <li>
                <p>
                  Each Party agrees to indemnify and keep indemnified and defend
                  at its own expense the other Party against: (i) administrative
                  fines paid by the indemnified Party and imposed on it by the
                  competent supervisory authority; and (ii) damages paid by the
                  indemnified Party to data subjects based on a settlement
                  (agreed by the indemnifying party) or final judgement, if the
                  claim against the indemnified party results solely from breach
                  of this Agreement or applicable Data Protection Legislation by
                  the indemnifying Party, and only to the extent such breach is
                  attributable to the indemnifying Party. The indemnifying Party
                  shall provide, at its own cost, all reasonable support to the
                  indemnified Party in defending the claim.
                </p>
              </li>
              <li>
                <p>
                  Section 24. above provides the indemnified Party’s exclusive
                  remedy for all claims against it by any competent supervisory
                  authority and data subjects.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>FEEDBACK</h3>
            <ol start="1">
              <li>
                <p>
                  User Organization may evaluate and report to MYR its views on
                  and input regarding Concertify, including in regard to
                  availability, performance and functionality thereof (“
                  <strong>Feedback</strong>”).
                </p>
              </li>
              <li>
                <p>
                  All Feedback provided by User Organization shall be treated by
                  User Organization as confidential information and by MYR on a
                  non-confidential and unrestricted basis. MYR shall receive all
                  ownership rights and Intellectual Property Rights in the
                  Feedback (including all derivatives and improvements thereof).
                  User Organization acknowledges that MYR may use the Feedback
                  for the purposes of incorporating the Feedback into Concertify
                  (including all improvements thereof) and utilizing the
                  Feedback when further developing Concertify itself or through
                  a third party. However, nothing in this Agreement shall
                  constitute an obligation for MYR to use any Feedback provided
                  by User Organization in any way.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>LIMITATIONS ON USE</h3>
            <ol start="1">
              <li>
                <span>
                  <p>User Organization and Authorized Users shall not:</p>
                  <ol type="i">
                    <li>
                      <p>
                        license, sublicense, sell, transfer, assign, distribute
                        or otherwise commercially exploit or make available to
                        any third party Concertify in any way;
                      </p>
                    </li>
                    <li>
                      <p>
                        modify or make derivative works based upon Concertify;
                      </p>
                    </li>
                    <li>
                      <p>reverse engineer Concertify;</p>
                    </li>
                    <li>
                      <p>
                        access Concertify in order to build a competitive
                        product or service;
                      </p>
                    </li>
                    <li>
                      <p>
                        circumvent or attempt to circumvent any usage control or
                        anti-copy features of Concertify;
                      </p>
                    </li>
                    <li>
                      <p>
                        probe, scan or test the vulnerability of Concertify;
                      </p>
                    </li>
                    <li>
                      <p>
                        use Concertify or the content available through
                        Concertify in any manner that could damage, disable,
                        overburden or impair Concertify;
                      </p>
                    </li>
                    <li>
                      <p>
                        use any data mining, robots, scraping, or similar data
                        gathering or extraction methods;
                      </p>
                    </li>
                    <li>
                      <p>
                        interfere with other users’ use and enjoyment of
                        Concertify;
                      </p>
                    </li>
                    <li>
                      <p>
                        use Concertify for transmitting any unauthorized
                        advertising, promotional materials, junk mail, spam,
                        chain letters, contests, pyramid schemes, or any other
                        form of solicitation or mass messaging;
                      </p>
                    </li>
                    <li>
                      <p>
                        use Concertify in ways that violate intellectual
                        property rights, trade secrets or privacy of third
                        parties;
                      </p>
                    </li>
                    <li>
                      <p>
                        use Concertify for unauthorized, inappropriate or
                        unethical purposes or activities; or
                      </p>
                    </li>
                    <li>
                      <p>
                        use Concertify to transmit any material that contains
                        adware, malware, spyware, software viruses, worms or any
                        other computer code designed to interrupt, destroy, or
                        limit the functionality of computer software or
                        equipment.
                      </p>
                    </li>
                  </ol>
                </span>
              </li>
            </ol>
          </li>
          <li>
            <h3>FEES AND PAYMENT</h3>
            <ol start="1">
              <li>
                <p>
                  The service fee applicable to User Organization’s use of
                  Concertify, if any, is separately agreed between the Parties.
                </p>
              </li>
              <li>
                <p>
                  10.2. Unless separately agreed between the Parties or
                  explicitly mentioned in Concertify’s service descriptions,
                  User Organization is not entitled to receive, inter alia,
                  customer support services, consultation services or
                  integration or implementation services. In case such services
                  are included in MYR’s service offering, MYR shall have the
                  right to charge the applicable fees and charges relating to
                  these services.
                </p>
              </li>
              <li>
                <p>
                  The applicable service fees are invoiced by MYR on a quarterly
                  basis in advance, unless separately agreed otherwise by the
                  Parties.
                </p>
              </li>
              <li>
                <p>
                  The term of payment of each invoice shall be thirty (30) days
                  net from the date of the invoice. All payments shall be made
                  in Euros.
                </p>
              </li>
              <li>
                <p>
                  Interest on any amounts overdue shall be eight (8)% higher
                  than the interest rate applied by the European Central Bank to
                  its most recent main refinancing operation carried out before
                  the first calendar day of each half-year rounded up to the
                  nearest half percentage point, which is in force at the time
                  in question.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>CONFIDENTIALITY</h3>
            <ol start="1">
              <li>
                <p>
                  Neither Party shall disclose to third parties any material or
                  information received from the other Party and marked as
                  confidential or which should be understood to be confidential
                  (including but not limited to technical, commercial and legal
                  information, and information on marketing, customers,
                  financial status, business plans and product and service
                  development of the other Party or other legal entities that
                  are owned or controlled by the other Party, or of their
                  customers or partners, as well as business and trade secrets,
                  regardless of the same being protected or protectable by
                  copyright, patent, trademark or otherwise) (“Confidential
                  Information”) and shall not use Confidential Information for
                  any other purposes than those stated in this Agreement.
                </p>
              </li>
              <li>
                <p>
                  The confidentiality obligation shall, however, not be applied
                  to material and information, (a) which is generally available
                  or otherwise public; or (b) which the Party has received from
                  a third party without any obligation of confidentiality; or
                  (c) which was in the possession of the receiving Party prior
                  to receipt of the same from the other Party without any
                  obligation of confidentiality related thereto; or (d) which a
                  Party has independently developed without using material or
                  information received from the other Party.
                </p>
              </li>
              <li>
                <p>
                  For clarity, User Organization Content is not considered
                  Confidential Information of User Organization. MYR’s right to
                  use and disclose User Organization Content is defined in
                  Section 6 of this Agreement.
                </p>
              </li>
              <li>
                <p>
                  Each Party shall limit disclosure of the Confidential
                  Information to its employees, subcontractors, consultants,
                  officers, agents, affiliates and other professional advisors
                  on a need to know basis only, provided that all such persons
                  receiving Confidential Information shall be made aware of its
                  confidential nature and the restrictions and obligations set
                  out herein and shall be under similar restrictions and
                  obligations than those set forth herein.
                </p>
              </li>
              <li>
                <p>
                  The confidentiality obligation set out herein shall survive
                  the termination of this Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>AVAILABILITY</h3>
            <ol start="1">
              <li>
                <p>
                  MYR will strive to have Concertify available for User
                  Organization’s use 24 hours a day, 7 days a week during the
                  term of this Agreement.{" "}
                </p>
              </li>
              <li>
                <p>
                  Notwithstanding the above mentioned, MYR shall have the right
                  to temporarily suspend the provision of Concertify at any time
                  without any obligation to compensate any damages or service
                  level failures to User Organization.
                </p>
              </li>
              <li>
                <p>
                  MYR shall have the right to temporarily deny User
                  Organization’s and any Authorized User’s access to Concertify
                  without any prior notice to User Organization or Authorized
                  Users, if MYR suspects that one or several Authorized Users
                  burden or uses Concertify in a manner which may jeopardize the
                  availability of Concertify to the other users of Concertify.
                </p>
              </li>
              <li>
                <p>
                  User Organization acknowledges that interruptions to the
                  availability of Concertify may also occur due to no fault of
                  MYR, for example, in the event of data connection disruptions
                  or interruptions to the availability of systems or components
                  delivered by third parties.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
            <ol start="1">
              <li>
                <p>
                  MYR (and its licensors, where applicable) shall own all right,
                  title and interest, including all related Intellectual
                  Property Rights, in and to Concertify and the Feedback,
                  including to any and all enhancements, suggestions,
                  modifications, extensions and/or derivative works thereof.
                  This Agreement does not convey any rights of ownership in or
                  related to Concertify or Feedback to User Organization.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>DICLAIMER AND WARRANTIES</h3>
            <ol start="1">
              <li>
                <p>
                  To the maximum extent permitted under applicable law,
                  Concertify, including any and all content thereof, is provided
                  “as is” and “as available” and MYR hereby disclaims all
                  express or implied representations, warranties and guarantees
                  with regard to Concertify and of merchantability, satisfactory
                  quality, noninfringement and fitness for a particular purpose.
                  MYR does not warrant that Concertify, including any and all
                  content thereof, is or will be error-free, will meet User
                  Organization’s or Authorized Users’ requirements, or be timely
                  or secure.
                </p>
              </li>
              <li>
                <p>
                  Notwithstanding the above, MYR warrants that it has the right
                  to enter into this Agreement and that User Organization and
                  Authorized Users have the right to use Concertify in
                  accordance with this Agreement.
                </p>
              </li>
              <li>
                <p>
                  User Organization warrants that it is authorized to enter into
                  this Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>LIMITATION OF LIABILITY</h3>
            <ol start="1">
              <li>
                <p>
                  MYR and its licensors shall not be liable to User
                  Organization, whatever the cause thereof, for any loss of
                  revenue, profit, business, goodwill or reputation or loss
                  caused as a result of interruptions in business or any other
                  consequential or indirect damages arising under this
                  Agreement. This limitation shall not apply in cases of
                  intentional misconduct or gross negligence.
                </p>
              </li>
              <li>
                <p>
                  MYR’s total and aggregate liability, during the term of this
                  Agreement, arising under or relating to this Agreement shall
                  under no circumstances exceed EUR 10 000.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>TERM AND TERMINATION</h3>
            <ol start="1">
              <li>
                <p>
                  This Agreement shall enter into force immediately upon User
                  Organization’s acceptance (e.g. by email) and shall continue
                  to stay in force for an initial period of twelve (12) months
                  (“Initial Period”). Thereafter, the Agreement shall
                  automatically continue to stay in force until further notice
                  unless User Organization has terminated the Agreement at the
                  latest three (3) months prior to the expiry of the Initial
                  Period. After the Initial Period the Agreement may be
                  terminated by User Organization with three (3) months’ prior
                  written notice.
                </p>
              </li>
              <li>
                <p>
                  However, MYR may suspend or terminate this Agreement and
                  Concertify, with or without cause, at any time upon written
                  notice to User Organization.{" "}
                </p>
              </li>
              <li>
                <span>
                  <p>
                    In addition, User Organization may terminate this Agreement
                    with immediate effect upon written notice to MYR, if MYR:
                  </p>
                  <ol start="1">
                    <li>
                      <p>
                        commits a material breach of this Agreement, which is
                        capable of being cured, but is not cured within thirty
                        (30) days after MYR has been notified of the breach;
                      </p>
                    </li>
                    <li>
                      <p>
                        commits a material breach that is not capable of being
                        cured; or
                      </p>
                    </li>
                    <li>
                      <p>
                        becomes insolvent, applies for or is adjudicated in
                        bankruptcy or liquidation or corporate restructuring or
                        otherwise ceases to carry on its business.
                      </p>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <p>
                  The termination of this Agreement shall not affect any accrued
                  rights of MYR.
                </p>
              </li>
              <li>
                <p>
                  User Organization acknowledges and agrees that, after the
                  termination or expiry of this Agreement, User Organization’s
                  and Authorized Users’ access to Concertify will be
                  automatically terminated.{" "}
                </p>
              </li>
              <li>
                <p>
                  The provisions of this Agreement which by their nature
                  reasonably should survive the termination or other expiration
                  of this Agreement shall survive any expiration or termination.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>FORCE MAJEURE</h3>
            <ol start="1">
              <li>
                <p>
                  Neither Party shall be liable for any delays or
                  non-performance of its obligations or any damages caused by an
                  impediment beyond its reasonable control, which it could not
                  have reasonably taken into account at the time of entering
                  into the Agreement, and whose consequences it could not
                  reasonably have avoided or overcome (“Force Majeure”). For
                  instance, errors in public communication networks or
                  electricity supply shall constitute such an impediment.
                  Strike, lockout, boycott and other industrial action shall
                  constitute a Force Majeure event also when the Party concerned
                  is the target of such action. A Force Majeure event suffered
                  by a subcontractor of a Party shall also discharge such Party
                  from liability, if the work to be performed under
                  subcontracting cannot be done or acquired from another source
                  without incurring unreasonable costs or significant loss of
                  time. Each Party shall without delay inform the other Party in
                  writing of a Force Majeure event and the termination of the
                  Force Majeure event.
                </p>
              </li>
              <li>
                <p>
                  If the Force Majeure event has lasted for more than sixty (60)
                  days, the Parties may terminate the Agreement by providing the
                  other Party with written notice thereof.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>SUBCONTRACTORS</h3>
            <ol start="1">
              <li>
                <p>
                  MYR reserves the right to provide Concertify through
                  subcontractors. MYR is responsible for such subcontractors and
                  shall ensure that they comply with this Agreement, and any
                  disclaimers, warranty exclusions and limitations of liability
                  of MYR under this Agreement shall apply to such
                  subcontractors.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>APPLICABLE LAW AND DISPUTE RESOLUTION</h3>
            <ol start="1">
              <li>
                <p>
                  The Agreement is governed by the laws of Finland, excluding
                  its choice of law rules.
                </p>
              </li>
              <li>
                <p>
                  Any dispute arising in connection with the Agreement shall be
                  finally settled by arbitration in accordance with the
                  Arbitration Rules of the Finland Chamber of Commerce. The
                  arbitration tribunal shall consist of one arbitrator and the
                  arbitration language shall be English. The seat of arbitration
                  shall be Helsinki, Finland.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h3>MISCELLANEOUS</h3>
            <ol start="1">
              <li>
                <p>
                  <strong>Entire Agreement.</strong> This Agreement sets forth
                  the entire Agreement and understanding among the Parties
                  relative to the subject matter contained herein, unless
                  otherwise stated in this Agreement, and supersedes all other
                  agreements, oral and written, heretofore made between the
                  Parties.
                </p>
              </li>
              <li>
                <p>
                  <strong>Severability.</strong> If one or more provisions of
                  this Agreement is or becomes either in whole or in part
                  illegal, invalid or unenforceable, this shall not affect the
                  validity of the remaining provisions. The provision that is in
                  whole or in part illegal, invalid or unenforceable shall be
                  replaced by a valid provision that approximates as closely as
                  possible the economic intent of the original provision.
                </p>
              </li>
              <li>
                <p>
                  <strong>Changes to the Agreement.</strong> MYR is entitled to
                  amend this Agreement at any time by providing User
                  Organization with a notice thereof by email. The changes will
                  take effect 30 days after User Organization has been notified
                  thereof. Notwithstanding the above, MYR shall not make changes
                  to the service fees separately agreed between the Parties, if
                  applicable, during the first 12 months of validity of this
                  Agreement.
                </p>
              </li>
              <li>
                <p>
                  <strong>Notices.</strong> Any notice or other communication
                  required or permitted to be given under this Agreement must be
                  made in English in writing to the contact persons communicated
                  separately by the Parties to each other. Any notice shall be
                  deemed to have been delivered: (i) upon receipt, when
                  delivered personally or through registered mail; (ii) within 7
                  days if delivered by first class mail; or the following
                  business day, if delivered per email and no automatic
                  malfunction or out-of-office reply was received.
                </p>
              </li>
              <li>
                <p>
                  <strong>No Partnership.</strong> Nothing contained herein
                  shall be construed as creating any agency, partnership or
                  other form of joint enterprise between the Parties.
                </p>
              </li>
              <li>
                <p>
                  <strong>No Waiver.</strong> No waiver of any provision of this
                  Agreement shall be deemed, or shall constitute, a waiver of
                  any other provision, nor shall any waiver constitute a
                  continuing waiver. No waiver shall be binding unless executed
                  in writing by the Party making the waiver.
                </p>
              </li>
              <li>
                <p>
                  <strong>Assignment.</strong> User Organization may not assign
                  this Agreement or any rights or obligations hereunder without
                  the prior written consent of MYR. MYR has the right to assign
                  this Agreement to its affiliates or successors as part of a
                  restructuring, merger, acquisition, asset sale or other
                  corporate reorganization.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                value={true}
                color="secondary"
                onChange={() => setChecked(!checked)}
              />
            }
            label="I have read and accept the Terms of Service"
          />
        </div>
        <div className={classes.dialogButton}>
          <Button
            color="secondary"
            variant="contained"
            type="button"
            disabled={!checked}
            data-role="accept-terms-and-conditions"
            onClick={() => onAccept()}
          >
            CONTINUE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

TermsOfServiceDialog.defaultProps = {
  open: false
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "TermsOfService"
  };
};

export default connect(mapStateToProps, null)(TermsOfServiceDialog);
