import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

import Work from "components/works/Work";
import WorksSearchInput from "components/works/search/WorksSearchInput/ReduxWorksSearchInput";
import SaveChangesButton from "./SaveChangesButton";
import StatusBadge from "components/status/StatusBadge";
import ReuseSetlistDialog from "components/dialogs/ReuseSetlistDialog";

import { connect } from "react-redux";
import saveSetlist from "redux/actions/saveSetlist";
import {
  ADD_WORK,
  REMOVE_WORK,
  CLEAR_SETLIST,
  CLEAR_SEARCH_RESULTS,
  OPEN_DIALOG,
  ADD_USERGENERATED_WORK,
  REUSE_SETLIST
} from "redux/actions/types";
import openDialog from "redux/actions/openDialog";
import { IsEmpty } from "utils";

const SEARCH_RESULT_WORK_DATA_ROLE = "work-instance-add";

export const AddWorkPrompt = ({ classes, onAddUserWork }) => (
  <Typography variant="body1">
    Looks like we don't know that one yet. Here's what you can do:
    <ul>
      <li>
        Please check the spelling of the title. Some musical works might appear
        under slightly different names.
      </li>
      <li>
        <span className={classes.addWorkButton} onClick={onAddUserWork}>
          <u>
            <strong>Add</strong>
          </u>
        </span>{" "}
        the song to the setlist manually.
      </li>
    </ul>
  </Typography>
);

const statusToVariant = status => {
  switch (status) {
    case "confirmed":
      return "green";
    case "unconfirmed":
      return "orange";
    default:
      return "gray";
  }
};

const statusToLabel = status =>
  ["confirmed", "unconfirmed"].includes(status) ? status : "unconfirmed";

export const SetlistSection = ({
  classes,
  loading,
  performance,
  searchResults,
  onReuseSetlist,
  onAddWork,
  saveSetlist,
  onRemoveWork,
  onClearSetlist,
  onClearSearchInput,
  onAddUserWork,
  setlistStatus
}) => {
  const isSetlistEmpty =
    !performance.Setlist ||
    !performance.Setlist.Works ||
    performance.Setlist.Works.length === 0;
  const performanceLoaded = !IsEmpty(performance);

  const [workSearchQuery, setWorkSearchQuery] = useState("");
  const [currentWorkIndex, setCurrentWorkIndex] = useState(0);

  const moveUp = () => {
    if (currentWorkIndex > 0) {
      setCurrentWorkIndex(currentWorkIndex - 1);
    }
  };

  const moveDown = () => {
    if (currentWorkIndex < searchResults.length - 1) {
      setCurrentWorkIndex(currentWorkIndex + 1);
    }
  };

  const onKeyPressed = e => {
    e.preventDefault();
    if (e.keyCode === 38) {
      moveUp();
    } else if (e.keyCode === 40) {
      moveDown();
    }
  };

  const clearAndFocusWorkSearchInput = () => {
    setWorkSearchQuery("");
    onClearSearchInput();
    setCurrentWorkIndex(0);
  };

  const addWork = work => () => {
    onAddWork(work);
    clearAndFocusWorkSearchInput();
  };

  return (
    <div className={classes.setlistSection}>
      <ReactPlaceholder showLoadingAnimation ready={!loading}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">Setlist</Typography>
          </Grid>
          <Grid item>
            <StatusBadge
              loading={loading}
              variant={statusToVariant(setlistStatus)}
              label={statusToLabel(setlistStatus)}
            />
          </Grid>
        </Grid>
      </ReactPlaceholder>
      <div>
        <div className={classes.margins}>
          {isSetlistEmpty ? <NoSetlistInfo /> : <ExtendSetlistInfo />}
        </div>
        <WorksSearchInput
          searchQuery={workSearchQuery}
          onClear={clearAndFocusWorkSearchInput}
          onChange={setWorkSearchQuery}
        />
      </div>
      <ReactPlaceholder showLoadingAnimation rows={3} ready={!loading}>
        {!isSetlistEmpty && (
          <div>
            {performance.Setlist &&
              performance.Setlist.Works &&
              performance.Setlist.Works.length > 0 && (
                <div data-role="works-list">
                  <br />
                  {performance.Setlist.Works.map((work, i) => (
                    <Work
                      key={i}
                      index={i}
                      data-cy={`setlist-work-${i}`}
                      data-role="work-instance-close"
                      title={work.Title}
                      iswc={work.ISWC}
                      duration={
                        work.PerformanceDuration || work.OriginalDuration
                      }
                      rightsOwners={work.RightsOwners.map(o =>
                        `${o.FirstName || ""} ${o.LastName || ""}`.trim()
                      )}
                      onClose={e => onRemoveWork(i)}
                    />
                  ))}
                </div>
              )}
          </div>
        )}
      </ReactPlaceholder>
      <ReuseSetlistDialog />
      {searchResults && searchResults.length === 0 && (
        <div>
          <br />
          <Grid container spacing={2} className={classes.warning}>
            <Grid item>
              <WarningOutlinedIcon />
            </Grid>
            <Grid item>
              <AddWorkPrompt classes={classes} onAddUserWork={onAddUserWork} />
            </Grid>
          </Grid>
        </div>
      )}
      {searchResults && searchResults.length > 0 && (
        <List onKeyDown={onKeyPressed}>
          <br />
          {searchResults.map((work, i) => (
            <Work
              key={i}
              addMode={true}
              data-role={SEARCH_RESULT_WORK_DATA_ROLE}
              title={work.Title}
              iswc={work.ISWC}
              focused={i === currentWorkIndex}
              duration={work.OriginalDuration}
              rightsOwners={
                (work.RightsOwners &&
                  work.RightsOwners.map(o =>
                    `${o.FirstName || ""} ${o.LastName || ""}`.trim()
                  )) ||
                []
              }
              readOnly={true}
              onAdd={addWork(work)}
              onClick={() => setCurrentWorkIndex(i)}
            />
          ))}
        </List>
      )}
      <br />
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={classes.actions}
      >
        <Grid item>
          <Tooltip
            title="Songs in setlist already"
            disableHoverListener={isSetlistEmpty && !IsEmpty(performance)}
          >
            <span>
              <Button
                disabled={!isSetlistEmpty || !performanceLoaded}
                variant="contained"
                color="secondary"
                onClick={onReuseSetlist}
              >
                Re-use setlist
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item>
          <SaveChangesButton
            classes={classes}
            bothDisabled={!performanceLoaded}
            confirmedButtonDisabled={isSetlistEmpty}
            onSaveAsDraft={e =>
              saveSetlist(performance.PerformanceId, performance.Setlist.Works)
            }
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.clearSetlistButton}
            disabled={isSetlistEmpty}
            onClick={onClearSetlist}
            variant="contained"
          >
            Clear setlist
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const NoSetlistInfo = () => (
  <Typography variant="body1">
    We don't currently have any setlist information for this performance. You
    can use the search box below to add musical works.
  </Typography>
);

const ExtendSetlistInfo = () => (
  <Typography variant="body1">
    If this setlist is incomplete or you would like to extend it, you can use
    the search box below to look for and add musical works.
  </Typography>
);

SetlistSection.defaultProps = {
  classes: {},
  loading: false
};

const mapStateToProps = state => ({
  performance: state.performanceDetailsReducer.currentPerformanceDetails,
  searchResults: state.worksSearchReducer.searchResults,
  setlistHasChanged: state.performanceDetailsReducer.setlistHasChanged,
  setlistStatus:
    state.performanceDetailsReducer.currentPerformanceDetails.Setlist &&
    state.performanceDetailsReducer.currentPerformanceDetails.Setlist.Status,
  loading: state.performanceDetailsReducer.isFetching
});

const mapDispatchToProps = dispatch => ({
  onReuseSetlist: () => dispatch(openDialog(REUSE_SETLIST)),
  onAddUserWork: () =>
    dispatch({ type: OPEN_DIALOG, variant: ADD_USERGENERATED_WORK }),
  onAddWork: work => dispatch({ type: ADD_WORK, payload: work }),
  onRemoveWork: index => dispatch({ type: REMOVE_WORK, payload: index }),
  onClearSetlist: () => dispatch({ type: CLEAR_SETLIST }),
  onClearSearchInput: () => dispatch({ type: CLEAR_SEARCH_RESULTS }),
  saveSetlist: (performanceId, newWorks) =>
    dispatch(saveSetlist(performanceId, newWorks))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetlistSection);
