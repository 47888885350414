import UserService from "services/UserService";
import {
  getCMOName,
  getCMOPerformingArtists
} from "redux/actions/artistsAndCMOs";
import {
  CURRENT_AUTH_USER_REQUEST,
  CURRENT_AUTH_USER_SUCCESS,
  CURRENT_AUTH_USER_ERROR,
  CURRENT_AUTH_USER_CLEAR
} from "./types";

export const currentAuthenticatedUser = () => async (dispatch, getState) => {
  dispatch({ type: CURRENT_AUTH_USER_REQUEST });
  try {
    const user = await UserService.currentAuthenticatedUser();
    const cmoName = dispatch(getCMOName(user.cmoCode));
    const performingArtists = dispatch(getCMOPerformingArtists(user.cmoCode));
    user["cmoName"] = cmoName;
    user["performingArtists"] = performingArtists;
    dispatch({
      type: CURRENT_AUTH_USER_SUCCESS,
      payload: { ...user }
    });
  } catch (e) {
    dispatch({
      type: CURRENT_AUTH_USER_ERROR
    });
    throw new Error(e);
  }
};

export const clearCurrentAuthenticatedUser = () => async dispatch => {
  dispatch({
    type: CURRENT_AUTH_USER_CLEAR
  });
};
