import concertify from "concertify/core";

import {
  SEARCH_WORKS_REQUEST,
  SEARCH_WORKS_SUCCESS,
  SEARCH_WORKS_ERROR
} from "redux/actions/types";

const searchWorks = query => async dispatch => {
  dispatch({ type: SEARCH_WORKS_REQUEST });
  try {
    const matches = await concertify.works.query(query);
    dispatch({
      type: SEARCH_WORKS_SUCCESS,
      payload: matches
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: SEARCH_WORKS_ERROR,
      payload: e
    });
  }
};

export default searchWorks;
