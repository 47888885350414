import {
  INITIATE_DATA_FROM_SERVER_SUCCESS,
  CLEAR_DATA_FROM_SERVER
} from "redux/actions/types";

const initialState = {
  loaded: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIATE_DATA_FROM_SERVER_SUCCESS:
      return {
        ...state,
        loaded: true
      };
    case CLEAR_DATA_FROM_SERVER:
      return {
        loaded: false
      };
    default:
      return state;
  }
}
