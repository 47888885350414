import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    color: "#000",
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #b8b8b8",
    background: "#F0F4F7",
    minHeight: 10,
    borderRadius: 0,
    boxShadow: "none",
    padding: 10
  }
};

const GrayRow = withStyles(styles)(({ classes, children, ...rest }) => (
  <Paper classes={{ root: classes.root }} {...rest}>
    {children}
  </Paper>
));

GrayRow.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
};

export default GrayRow;
