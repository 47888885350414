import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => {
  return {
    dialogActions: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingLeft: "25px"
    },
    dialog: {
      minWidth: "650px",
      maxHeight: "70%",
      margin: "auto"
    },
    dialogTitle: {
      paddingBottom: "10px"
    },
    dialogSubtitle: {
      paddingBottom: "5px"
    },
    dialogContent: {
      fontSize: "0.85rem"
    },
    dialogButton: {
      marginLeft: "0 !important",
      paddingBottom: "15px",
      paddingTop: "10px"
    }
  };
});

export { styles };
