import {
  LOAD_PERFORMANCES_REQUEST,
  LOAD_PERFORMANCES_SUCCESS,
  LOAD_PERFORMANCES_ERROR,
  CLEAR_PERFORMANCES
} from "redux/actions/types";

const initialState = {
  performances: [],
  totalCount: 0,
  loading: false,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_PERFORMANCES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_PERFORMANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        performances: action.payload.Performances,
        totalCount: action.payload.TotalCount
      };
    case LOAD_PERFORMANCES_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case CLEAR_PERFORMANCES:
      return initialState;
    default:
      return state;
  }
}
