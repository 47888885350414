import React, { createRef } from "react";
import { useUpdateEffect } from "react-use";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

const WorksSearchInput = props => {
  const {
    classes,
    searching,
    searchQuery,
    onSearch,
    onClear,
    onChange
  } = props;
  const workSearchInputRef = createRef();

  useUpdateEffect(() => {
    if (searchQuery === "") workSearchInputRef.current.focus();
  }, [searchQuery]);

  return (
    <TextField
      inputRef={workSearchInputRef}
      id="workSearchInput"
      fullWidth
      value={searchQuery}
      label="Search for musical works"
      helperText={"Input a musical work's title and press enter."}
      variant="outlined"
      margin="none"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {searching ? (
              <CircularProgress
                color="secondary"
                size={25}
                style={{ marginRight: 15 }}
              />
            ) : (
              <IconButton
                onClick={e => {
                  if (onClear) {
                    onClear();
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
      onChange={e => {
        onChange(e.target.value);
        if (e.target.value === "" && onClear) {
          onClear();
        }
      }}
      onKeyPress={e =>
        e.key === "Enter" ? onSearch(searchQuery.toLowerCase()) : null
      }
      className={classes.worksInput}
    />
  );
};

WorksSearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func
};

WorksSearchInput.defaultProps = {
  classes: {},
  onSearch: () => null
};

export default WorksSearchInput;
