// @flow strict
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { styles } from "./styles";

type BackDropDialogProps = {
  /** If true, BackDrop dialog is open */
  open: boolean
};

/** Component representing backdrop loader for the states where action loading takes some time. The main purpose is to
 * give user better UX, so the user would know that there is a process happening in the background.
 */

export const BackDropDialog = ({ open, ...rest }: BackDropDialogProps) => {
  const classes = styles();
  return (
    <div>
      <Backdrop
        data-role="backdrop-dialog"
        className={classes.backdrop}
        open={open}
        {...rest}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

BackDropDialog.defaultProps = {
  open: false
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "BackDropDialog"
  };
};

export default connect<*, *, *, *, *, *>(mapStateToProps, null)(BackDropDialog);
