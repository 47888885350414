import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import Amplify, { I18n } from "@aws-amplify/core";
import { Provider } from "react-redux";
import configureStore from "store";
import vocabularies from "vocabularies";
import theme from "components/ConcertifyMuiTheme";
import { MuiThemeProvider } from "@material-ui/core/styles";

// Amplify refers to Cognito as Auth, S3 as Storage, and API Gateway as API
Amplify.configure({
  Auth: {
    mandatorySignIn: true, // sign-in is required to interact with the app
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  Analytics: {
    disabled: true
  }
});

Amplify.Logger.LOG_LEVEL =
  process.env.NODE_ENV === "development" ? "DEBUG" : "INFO";

I18n.putVocabularies(vocabularies);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={configureStore}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
