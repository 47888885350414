import React from "react";

import red from "@material-ui/core/colors/red";
import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: red[500]
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

const Danger = props => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <ErrorIcon className={classes.icon} /> {children}
    </div>
  );
};

export default withStyles(styles)(Danger);
