import { IsEmpty } from "utils";
import { NEW_STATUS, PERFORMANCE_STATUSES, CMOR } from "concertify/constants";
import concertify from "concertify/core";
import * as moment from "moment";

const resolve = (path, obj) => {
  return path.split(".").reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

const descendingComparator = (a, b, orderBy) => {
  if (resolve(orderBy, b) < resolve(orderBy, a)) {
    return -1;
  }
  if (resolve(orderBy, b) > resolve(orderBy, a)) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const sort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const apiMock = async (user, filters, relevance) => {
  const {
    type,
    sortBy,
    pageNumber,
    itemsPerPage,
    dateRange,
    search,
    searchParameter
  } = filters;

  const performances = await concertify.performances.getRelevantFor(
    user,
    relevance
  );

  let filteredPerformances = performances;

  if (user.cmoCode === "778") {
    filteredPerformances = performances.filter(
      p => p.PerformingArtist.Name === "metallica"
    );
  }

  if (type !== "All") {
    filteredPerformances = filteredPerformances.filter(
      p =>
        p.Status === type ||
        (type === NEW_STATUS && !PERFORMANCE_STATUSES.includes(p.Status))
    );
  }

  let dateFilteredPerformances = filteredPerformances;

  if (dateRange.from && dateRange.to) {
    dateFilteredPerformances = dateFilteredPerformances.filter(
      p =>
        p.Date >= moment(dateRange.from).format("YYYY-MM-DD") &&
        p.Date <= moment(dateRange.to).format("YYYY-MM-DD")
    );
  }

  let sortedPerformances = dateFilteredPerformances;

  if (!IsEmpty(sortBy)) {
    sortedPerformances = sort(
      filteredPerformances,
      getComparator("asc", sortBy)
    );
  }

  let searchFilteredPerformances = sortedPerformances;
  if (
    searchParameter &&
    (searchParameter !== "country" ? search.length >= 4 : true)
  ) {
    searchFilteredPerformances = searchFilteredPerformances.filter(p => {
      switch (searchParameter) {
        case "artist":
          return (p.PerformingArtist.Name || "")
            .toLowerCase()
            .includes(search.toLowerCase());
        case "city":
          return (p.Venue.Address.City || "")
            .toLowerCase()
            .includes(search.toLowerCase());
        case "country":
          return (p.Venue.Address.CountryCode || "")
            .toLowerCase()
            .includes(search.toLowerCase());
        case "venue":
          return (p.Venue.Name || "")
            .toLowerCase()
            .includes(search.toLowerCase());
        default:
          return (p.PerformingArtist.Name || "")
            .toLowerCase()
            .includes(search.toLowerCase());
      }
    });
  }

  const totalCount = searchFilteredPerformances.length;

  if (user.type === CMOR) {
    searchFilteredPerformances = searchFilteredPerformances.slice(
      (pageNumber - 1) * itemsPerPage,
      pageNumber * itemsPerPage
    );
  }
  return {
    Performances: searchFilteredPerformances,
    TotalCount: totalCount
  };
};
