import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import openSnackbar from "redux/actions/openSnackbar";
import saveSetlist from "redux/actions/saveSetlist";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export const SaveChangesButton = ({
  onSaveAsDraft,
  onConfirmSetlist,
  onSaveEmptySetlist,
  performanceId,
  confirmedButtonDisabled,
  works,
  bothDisabled,
  classes
}) => {
  const handleClick = (performanceId, status, works) => {
    if (status === "unconfirmed") {
      onSaveAsDraft(performanceId, "unconfirmed", works);
      return;
    }
    if (works.length === 0) {
      onSaveEmptySetlist();
      return;
    }
    onConfirmSetlist(performanceId, "confirmed", works);
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            className={classes.unconfirmedButton}
            onClick={e => handleClick(performanceId, "unconfirmed", works)}
            disabled={bothDisabled}
            data-role="save-unconfirmed-setlist"
          >
            Save as unconfirmed
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={e => handleClick(performanceId, "confirmed", works)}
            disabled={bothDisabled || confirmedButtonDisabled}
            data-role="save-confirmed-setlist"
          >
            Save as confirmed
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

SaveChangesButton.propTypes = {
  onSaveAsDraft: PropTypes.func,
  onConfirmSetlist: PropTypes.func
};

SaveChangesButton.defaultProps = {
  onSaveAsDraft: (x, y, z) => null,
  onConfirmSetlist: (x, y, z) => null,
  works: []
};

const mapStateToProps = state => {
  const performance = state.performanceDetailsReducer.currentPerformanceDetails;
  return {
    performanceId: performance.PerformanceId,
    works: performance && performance.Setlist && performance.Setlist.Works,
    setlistHasChanged: state.performanceDetailsReducer.setlistHasChanged
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveEmptySetlist: () =>
    dispatch(openSnackbar("error", "You cannot confirm an empty setlist.")),
  onConfirmSetlist: (performanceId, status, works) =>
    dispatch(saveSetlist(performanceId, status, works)),
  onSaveAsDraft: (performanceId, status, works) =>
    dispatch(saveSetlist(performanceId, status, works))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveChangesButton);
