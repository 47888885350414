// @flow strict

import React, { Fragment } from "react";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { ForgotPassword } from "aws-amplify-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Logo from "components/Logo";
import RootStyle from "../style";

const styles = () => ({
  ...RootStyle
});

const logger = new Logger("ForgotPassword");

class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  sendView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField
          className={classes.textField}
          key="email"
          name="email"
          variant="outlined"
          placeholder="Email"
          autoComplete="off"
          onChange={this.handleInputChange}
        />
      </Fragment>
    );
  }

  submitView() {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField type="hidden" key="code" name="code" />
        <TextField type="hidden" key="password" name="password" />
        <TextField
          className={classes.textField}
          key="code"
          name="code"
          variant="outlined"
          placeholder="Code"
          autoComplete="off"
          onChange={this.handleInputChange}
        />
        <TextField
          className={classes.textField}
          key="password"
          name="password"
          variant="outlined"
          placeholder="Password"
          autoComplete="new-password"
          type="password"
          onChange={this.handleInputChange}
        />
      </Fragment>
    );
  }

  send = event => {
    if (event) {
      event.preventDefault();
    }
    const { authData = {} } = this.props;
    this.setState({ sendLoading: true });
    const username = this.getUsernameFromInput() || authData.username;
    if (!Auth || typeof Auth.forgotPassword !== "function") {
      this.setState({ sendLoading: false });
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.forgotPassword(username)
      .then(data => {
        logger.debug(data);
        this.setState({
          delivery: data.CodeDeliveryDetails,
          sendLoading: false
        });
      })
      .catch(err => {
        this.error(err);
        this.setState({ sendLoading: false });
      });
  };

  submit = event => {
    if (event) {
      event.preventDefault();
    }
    const { authData = {} } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;
    this.setState({ submitLoading: true });
    if (!Auth || typeof Auth.forgotPasswordSubmit !== "function") {
      this.setState({ submitLoading: false });
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then(data => {
        logger.debug(data);
        this.changeState("signIn");
        this.setState({ delivery: null, submitLoading: false });
      })
      .catch(err => {
        this.error(err);
        this.setState({ submitLoading: false });
      });
  };

  showComponent(theme) {
    const { classes, authData = {} } = this.props;
    const { submitLoading, sendLoading } = this.state;
    const isSubmitView = this.state.delivery || authData.email;
    return (
      <div className={classes.formContainer}>
        <div className={classes.formSection}>
          <div className={classes.headerSection}>
            <div className={classes.logoContainer}>
              <Logo size="large" />
            </div>
            <h3 className={classes.title}>
              {isSubmitView ? "Enter new password" : "Enter your email"}
            </h3>
          </div>
          <form onSubmit={isSubmitView ? this.submit : this.send}>
            <div className={classes.bodySection}>
              {isSubmitView ? this.submitView() : this.sendView()}
            </div>
            <div className={classes.footerSection}>
              <div className={classes.footerSectionPrimaryContent}>
                {isSubmitView ? (
                  submitLoading ? (
                    <div className={classes.circularProgressOnButton}>
                      <CircularProgress size={25} color="secondary" />
                    </div>
                  ) : (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  )
                ) : sendLoading ? (
                  <div className={classes.circularProgressOnButton}>
                    <CircularProgress size={25} color="secondary" />
                  </div>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Send code
                  </Button>
                )}
              </div>
              <div className={classes.footerSectionSecondaryContent}>
                {isSubmitView ? (
                  sendLoading ? (
                    <div>
                      <CircularProgress size={21} color="secondary" />
                    </div>
                  ) : (
                    <a href="/#" className={classes.link} onClick={this.send}>
                      Resend Code
                    </a>
                  )
                ) : (
                  <a
                    href="/#"
                    className={classes.link}
                    onClick={() => {
                      super.changeState("signIn");
                    }}
                  >
                    Back to Sign in
                  </a>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomForgotPassword);
