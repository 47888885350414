// @flow strict

import React, { useState } from "react";
import type { Node } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const validate = (value, predicates) => {
  let valid = undefined;
  predicates.map(p => (valid = p(value)));
  return valid;
};

const styles = makeStyles(theme => ({
  icon: {
    marginRight: 12,
    "& svg": {
      color: theme.palette.grey[700]
    }
  }
}));

type InputProps = {
  /** Props which are directly passed to TextField component */
  textFieldProps: Object,
  /** Array of functions for input validation */
  predicates: Array<Function>,
  /** Icon component which will be displayed as end adornment on TextField */
  icon: Node
};

const Input = ({ textFieldProps, predicates, icon }: InputProps) => {
  const [inputData, setInputData] = useState({
    errors: [],
    dirty: false,
    valid: false,
    error: ""
  });
  const classes = styles();

  const onBlur = () => {
    const validity = validate(textFieldProps.value, predicates);
    setInputData({
      ...inputData,
      dirty: true,
      valid: validity === undefined,
      error: validity === undefined ? "" : validity
    });
  };

  return (
    <TextField
      fullWidth
      error={inputData.dirty && !inputData.valid}
      helperText={inputData.dirty && !inputData.valid && inputData.error}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment className={classes.icon} position="end">
            {icon}
          </InputAdornment>
        )
      }}
      {...textFieldProps}
    />
  );
};

Input.defaultProps = {
  predicates: []
};

export default Input;
