import { Logger } from "@aws-amplify/core";

import concertify from "concertify/core";
import ensureWorksHavePerformanceDuration from "concertify/transformers/ensureWorksHavePerformanceDuration";
import { LOAD_PERFORMANCE_REQUEST } from "redux/actions/types";
import { LOAD_PERFORMANCE_SUCCESS } from "redux/actions/types";
import { LOAD_PERFORMANCE_ERROR } from "redux/actions/types";
import { OPEN_SNACKBAR } from "redux/actions/types";

const logger = new Logger("loadPerformance");

const transformAndloadPerformance = transform => performanceId => async dispatch => {
  dispatch({ type: LOAD_PERFORMANCE_REQUEST });
  try {
    const performance = transform(
      await concertify.performances.getById(performanceId)
    );
    dispatch({ type: LOAD_PERFORMANCE_SUCCESS, payload: performance });
  } catch (e) {
    logger.error(e);
    dispatch({
      type: LOAD_PERFORMANCE_ERROR,
      payload: e
    });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message: "Error fetching performance. Please try again later"
    });
  }
};
export default transformAndloadPerformance(ensureWorksHavePerformanceDuration);
