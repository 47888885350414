import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useUpdateEffect } from "react-use";
import clsx from "clsx";
import * as moment from "moment";

import {
  changePerformanceRangeFilter,
  changePerformanceTypeFilter,
  changePerformanceSearchFilter,
  changePerformanceSearchParameter
} from "redux/actions/performanceFilterActions";
import { PERFORMANCE_STATUSES, CMOR, PAR } from "concertify/constants";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DateFnsUtils from "@date-io/date-fns";
import grey from "@material-ui/core/colors/grey";
import { styles } from "./styles";

const chips = [
  { value: "artist", label: "Artist name" },
  { value: "city", label: "City" },
  { value: "country", label: "Country" },
  { value: "venue", label: "Venue name" }
];

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: grey[500],
    color: "white",
    fontSize: "small"
  }
}))(Tooltip);

export const UnconnectedPerformancesSubMenu = ({
  variant,
  performanceFilters,
  onDateRangeChange,
  onSearchParameterChange,
  onSearchChange,
  onTypeChange
}) => {
  const [filters] = useState(["All", ...PERFORMANCE_STATUSES]);
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const classes = styles();

  const handleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  const onDateChange = (type, date) => {
    const preformattedDate = moment(date);
    if (preformattedDate.isValid()) {
      setDateRange({
        ...dateRange,
        [type]: date
      });
    }
  };

  const resetDateFilter = () => {
    setDateRange({
      ...dateRange,
      from: null,
      to: null
    });
  };

  const onSearchTextChange = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (performanceFilters.dateRange.from && performanceFilters.dateRange.to) {
      setDateRange({
        from: performanceFilters.dateRange.from,
        to: performanceFilters.dateRange.to
      });
    }
    if (performanceFilters.search !== "") {
      setSearch(performanceFilters.search);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useUpdateEffect(() => {
    if (
      (search.length >= 4 && search !== performanceFilters.search) ||
      (search.length === 0 && performanceFilters.search.length !== 0) ||
      performanceFilters.searchParameter === "country"
    ) {
      onSearchChange(search);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [search]);

  useUpdateEffect(() => {
    setSearch("");
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [performanceFilters.searchParameter]);

  useUpdateEffect(() => {
    if (
      dateRange.from &&
      dateRange.to &&
      (dateRange.from !== performanceFilters.dateRange.from ||
        dateRange.to !== performanceFilters.dateRange.to)
    ) {
      onDateRangeChange(dateRange);
    }
    if (dateRange.from === null && dateRange.to === null) {
      onDateRangeChange(dateRange);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dateRange]);

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justify="center"
    >
      <Grid
        item
        container
        className={classes.center}
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          {variant === CMOR && (
            <Button
              color="secondary"
              component={Link}
              to="/performances/new"
              variant="contained"
              className={classes.addPerformanceButton}
              data-role="addPerformanceButton"
            >
              + Add Performance
            </Button>
          )}
        </Grid>
        <Grid item>
          <div className={classes.filtersContainer}>
            <div className={classes.dateRange}>
              <Typography className={classes.dateRangeText}>
                Date range:
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className={classes.datePicker}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  emptyLabel="dd/mm/yyyy"
                  value={dateRange.from}
                  inputProps={{
                    "data-role": "date-from-input"
                  }}
                  InputAdornmentProps={{
                    position: "end"
                  }}
                  KeyboardButtonProps={{
                    "data-role": "date-from"
                  }}
                  onChange={date => onDateChange("from", date)}
                />
              </MuiPickersUtilsProvider>
              <Typography className={classes.dateRangeSeparator}>-</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className={classes.datePicker}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  emptyLabel="dd/mm/yyyy"
                  value={dateRange.to}
                  inputProps={{
                    "data-role": "date-to-input"
                  }}
                  InputAdornmentProps={{ position: "end" }}
                  KeyboardButtonProps={{
                    "data-role": "date-to"
                  }}
                  onChange={date => onDateChange("to", date)}
                />
              </MuiPickersUtilsProvider>
              {dateRange.from && dateRange.to ? (
                <div className={classes.clearButton}>
                  <Button
                    onClick={resetDateFilter}
                    variant="contained"
                    color="secondary"
                  >
                    CLEAR
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.filterResults}>
              <Typography className={classes.filterResultsText}>
                Filter results:
              </Typography>
              <FormControl>
                <Select
                  className={classes.filterSelect}
                  name="status-filter"
                  value={performanceFilters.type}
                  onChange={onTypeChange}
                  data-role="status-filter"
                >
                  {filters.map(filter => (
                    <MenuItem
                      value={filter}
                      className={classes.filterItem}
                      data-role={`status-filter-${filter}`}
                    >
                      {filter}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.search}>
              {searchActive ? (
                <div style={{ position: "relative" }}>
                  <FormControl className={classes.searchInput}>
                    <Input
                      value={search}
                      onChange={onSearchTextChange}
                      variant="outlined"
                      placeholder="Search"
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle-search-visibility"
                            onClick={handleSearchActive}
                            onMouseDown={() => {}}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Paper
                    className={classes.searchChipHolder}
                    data-role="chips-holder"
                  >
                    {chips.map(chip => {
                      const isActive =
                        chip.value === performanceFilters.searchParameter;
                      return (
                        <InfoTooltip title={chip.label}>
                          <Chip
                            className={clsx(
                              classes.chip,
                              isActive && classes.chipActive
                            )}
                            icon={isActive && <CheckCircleIcon />}
                            label={chip.label}
                            variant="outlined"
                            clickable
                            color={isActive ? "primary" : ""}
                            onClick={() => onSearchParameterChange(chip.value)}
                          />
                        </InfoTooltip>
                      );
                    })}
                  </Paper>
                </div>
              ) : (
                <IconButton
                  className={classes.searchButton}
                  onClick={handleSearchActive}
                  data-role="search-active-button"
                >
                  <SearchIcon fontSize="large" />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

UnconnectedPerformancesSubMenu.defaultProps = {
  filter: "All",
  variant: undefined,
  performanceFilters: {},
  onDateRangeChange: () => {},
  onTypeChange: () => {},
  onSearchChange: () => {},
  onSearchParameterChange: () => {},
  currentAuthenticatedUser: () => {},
  setKeywordFilter: () => {}
};

UnconnectedPerformancesSubMenu.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchParameterChange: PropTypes.func.isRequired,
  setKeywordFilter: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([CMOR, PAR])
};

const mapStateToProps = state => ({
  user: state.loginReducer.user,
  variant: state.loginReducer.type,
  performanceFilters: state.performanceFilterReducer
});

const mapDispatchToProps = dispatch => ({
  onDateRangeChange: dateRange =>
    dispatch(changePerformanceRangeFilter(dateRange)),
  onTypeChange: event =>
    dispatch(changePerformanceTypeFilter(event.target.value)),
  onSearchChange: search => dispatch(changePerformanceSearchFilter(search)),
  onSearchParameterChange: searchParameter =>
    dispatch(changePerformanceSearchParameter(searchParameter))
});

const CreatePerformanceView = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedPerformancesSubMenu);

export default CreatePerformanceView;
