import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: { background: theme.palette.primary.light },
  iconButton: {
    background: theme.palette.common.white,
    padding: 4,
    "& svg": {
      color: theme.palette.primary.light
    },
    "&:hover": {
      background: theme.palette.grey[400]
    }
  }
});

export const UnstyledPerformanceDetailsSubMenu = props => {
  const { onDelete, onDownload, classes } = props;
  return (
    <AppBar position="static" className={classes.root}>
      <Container disableGutters maxWidth="lg">
        <Toolbar>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row">
              <Grid
                item
                container
                style={{ width: "max-content" }}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item container spacing={2} alignItems="center">
                  {onDownload && (
                    <Tooltip title="Download as CSV">
                      <Grid item>
                        <IconButton
                          data-role="download-performance-as-csv-button"
                          className={classes.iconButton}
                          onClick={onDownload}
                        >
                          <GetAppIcon className={classes.menu} />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  )}
                  <Tooltip title="Delete">
                    <Grid item>
                      <IconButton
                        data-role="delete-performance-button"
                        className={classes.iconButton}
                        onClick={onDelete}
                      >
                        <DeleteIcon className={classes.menu} />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const PresentationalPerformanceDetailsSubMenu = withStyles(styles)(
  UnstyledPerformanceDetailsSubMenu
);

export default PresentationalPerformanceDetailsSubMenu;
