import Auth from "@aws-amplify/auth";
import axios from "axios";
import uuid from "uuid/v4";
import urlJoin from "url-join";
import { CMOR } from "./constants";
import CMOsService from "./ArtistsAndCMOs";
import { Logger } from "@aws-amplify/core";

const log = new Logger("concertify/core");

// This mapping should reflect the CMO hierarchy document sent by Teemu
const mapCMOCodeToCountry = (CMOs, cmoCode) => {
  try {
    return CMOsService.getCMOCountryCode(CMOs, cmoCode).toLowerCase();
  } catch (err) {
    console.error(err);
    return null;
  }
};

// duplicate from above ... not optimal but fastest solution
const mapCMOCodeToName = (CMOs, cmoCode) => {
  try {
    return CMOsService.getCMOName(CMOs, cmoCode);
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * return a Promise of an object representing the Performance with the
 * matching id or `null` if the performance doesn't exist.
 *
 * id -> Promise<Nullable<Performance>>
 */
const getPerformanceById = async id => {
  const targetURL = makeAPIResourceURL(`performances/${id}`);
  const response = await doGet(targetURL);
  return response.data;
};

const newAddress = (city, country, line1, line2, zipCode) => {
  return {
    City: city,
    CountryCode: country,
    Line1: line1,
    Line2: line2,
    ZipCode: zipCode
  };
};

const newPerformance = (
  artist,
  date,
  venue,
  address,
  cmoCode,
  status,
  setlist
) => {
  return {
    PerformanceId: uuid(),
    OriginMetaData: {
      SubmissionDateTime: new Date().toJSON().slice(0, 19),
      SubmitterId: "2"
    },
    PerformingArtist: {
      Name: artist
    },
    Date: new Date(date).toJSON().slice(0, 10),
    Venue: {
      Address: {
        City: address.City,
        CountryCode: address.CountryCode,
        Line1: address.Line1,
        Line2: address.Line2,
        ZipCode: address.ZipCode
      },
      Name: venue
    },
    CMOCode: cmoCode,
    Status: status,
    Setlist: setlist
  };
};

const addNewPerformance = async (
  artist,
  date,
  city,
  country,
  venue,
  line1,
  line2,
  zipCode,
  cmoCode,
  status,
  setlist
) => {
  const address = newAddress(city, country, line1, line2, zipCode);
  const targetURL = makeAPIResourceURL(`performances/`);
  const reqBody = {
    Performances: [
      newPerformance(artist, date, venue, address, cmoCode, status, setlist)
    ]
  };
  try {
    return await doPost(targetURL, reqBody);
  } catch (error) {
    console.error(`[core] Failed to POST into ${targetURL}: ${error}`);
  }
};

const updatePerformanceSetlist = async (performanceId, status, works) => {
  log.debug(
    `updating the setlist of performance with id ${performanceId} with works:`,
    works
  );
  const targetURL = makeAPIResourceURL(`performances/${performanceId}`);
  log.debug("calling API at:", targetURL);
  const reqBody = { Setlist: { Works: works, Status: status } };
  log.debug("with req body:", reqBody);
  const response = await doPut(targetURL, reqBody);
  log.debug("API responded with:", response);
  return response;
};

// operation
const asyncMakeAxiosConfig = async () => {
  const session = await Auth.currentSession();
  const token = session.idToken.jwtToken;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: token
    }
  };
  return config;
};

// operation
const makeAPIResourceURL = (resource, queryParams = {}) => {
  log.debug(
    `making base API URL for resource "${resource}" in env "${process.env.NODE_ENV}"`
  );

  const BASE_URL = urlJoin(
    process.env.REACT_APP_CONCERTIFY_API_URL,
    process.env.REACT_APP_CONCERTIFY_API_VERSION
  );

  log.debug(`API base url: ${BASE_URL}`);
  let params = [];
  for (let [key, value] of Object.entries(queryParams)) {
    params.push(key + "=" + value);
  }
  let baseAndResource = urlJoin(BASE_URL, resource);
  if (params.length > 0) {
    baseAndResource = baseAndResource + "?";
  }
  return baseAndResource + params.join("&");
};

async function doGet(targetURL) {
  const config = await asyncMakeAxiosConfig();
  return axios.get(targetURL, config);
}

async function doPost(targetURL, reqBody) {
  const config = await asyncMakeAxiosConfig();
  return axios.post(targetURL, reqBody, config);
}

async function doPut(targetURL, reqBody) {
  const config = await asyncMakeAxiosConfig();
  return axios.put(targetURL, reqBody, config);
}

async function doDelete(targetURL, reqBody) {
  let config = await asyncMakeAxiosConfig();
  config["data"] = reqBody;
  return axios.delete(targetURL, config);
}

// integration
async function getPerformancesByCountryCode(countryCode) {
  const targetURL = makeAPIResourceURL("performances", {
    CountryCode: countryCode
  });
  log.debug(
    `getting performances by country code "${countryCode}" from target API url: ${targetURL}`
  );
  const response = await doGet(targetURL);
  log.debug("API responded with:", response);
  return response.data;
}

async function getPerformancesByArtistName(artist) {
  const targetURL = makeAPIResourceURL("performances", {
    artist
  });
  log.debug(
    `getting performances by artist name "${artist}" from target API url: ${targetURL}`
  );
  const response = await doGet(targetURL);
  log.debug("API responded with:", response);
  return response.data;
}

const getRelevantFor = async (user, relevanceLevel = "domestic") => {
  log.debug(`Getting relevant performances for ${user.type} user`);

  let params = {};
  if (user.type === CMOR) {
    log.debug(`Setting relevance level to ${relevanceLevel}`);
    params["Relevance"] = relevanceLevel;
  }

  const targetURL = makeAPIResourceURL("performances", params);
  log.debug(`Calling API at ${targetURL}`);
  const response = await axios.get(targetURL, await asyncMakeAxiosConfig());
  log.debug("API responded with:", response);
  return response.data.Performances;
};

const isAuthorized = (user, performance) => {
  const isLicensingCMO = (user, performance) => true;
  const isOfRepresentedPAR = (user, performance) => true;
  const isMainCMOUser = user => true;
  const isPerformanceOwner = (user, performance) =>
    performance.OriginMetaData.SubmitterId === user.sub;
  return (
    isMainCMOUser(user) ||
    isPerformanceOwner(user, performance) ||
    isOfRepresentedPAR(user, performance) ||
    isLicensingCMO(user, performance)
  );
};

const softDeletePerformance = async (deleter, performance, deletionReason) => {
  if (!isAuthorized(deleter, performance)) {
    return Promise.resolve({
      Attributes: { Deleted: false, Reason: "Permission denied" }
    });
  }
  const targetURL = makeAPIResourceURL(
    `performances/${performance.PerformanceId}`
  );
  const reqBody = {
    DeleterId: deleter.sub,
    ReasonForDeletion: deletionReason
  };
  await doDelete(targetURL, reqBody);
  return Promise.resolve({
    Attributes: { Deleted: true }
  });
};

const updatePerformance = async (performanceId, newAttrs) => {
  const targetURL = makeAPIResourceURL(`performances/${performanceId}`);
  await doPut(targetURL, newAttrs);
};

const apiGetCommentsForPerformance = async performanceId => {
  const targetURL = makeAPIResourceURL(`comments/${performanceId}`);
  const response = await axios.get(targetURL, await asyncMakeAxiosConfig());
  return response.data.Comments;
};

const apiAddComment = async comment => {
  const { PerformanceId, ...commentWithoutPerformanceId } = comment;
  const targetURL = makeAPIResourceURL(`comments/${PerformanceId}`);
  const response = await axios.post(
    targetURL,
    { ...commentWithoutPerformanceId },
    await asyncMakeAxiosConfig()
  );
  return response;
};

const addNewWorks = async works => {
  const targetURL = makeAPIResourceURL(`works/`);
  const reqBody = { Works: works };
  try {
    return await doPost(targetURL, reqBody);
  } catch (error) {
    console.error(`[core] Failed to POST into ${targetURL}: ${error}`);
  }
};

const apiWorksQuery = async queryString => {
  const targetURL = makeAPIResourceURL("works", { q: queryString });
  const response = await axios.get(targetURL, await asyncMakeAxiosConfig());
  return response.data.Works;
};

const getByCMOCode = async cmoCode => {
  log.debug("getting CMO by CMO code");
  const targetURL = makeAPIResourceURL(`cmos/${cmoCode}`);
  log.debug(`calling API at ${targetURL}`);
  const response = await axios.get(targetURL, await asyncMakeAxiosConfig());
  log.debug("API responded with:", response);
  if (response.data.length > 1) {
    log.warn(
      "got more than one CMO result. There should be only one; using the first"
    );
  }
  return response.data;
};

const getAll = async () => {
  log.debug("getting all CMOs");
  const targetURL = makeAPIResourceURL(`cmos`);
  log.debug(`calling API at ${targetURL}`);
  const response = await axios.get(targetURL, await asyncMakeAxiosConfig());
  log.debug("API responded with:", response);
  return response.data.CMOs;
};

class PerformanceAPIResource {
  constructor(performanceId) {
    this.performanceId = performanceId;
    this.logger = new Logger("PerformanceAPIResource");
  }
  async delete(deleter, reasonForDeletion) {
    this.logger.info("deleting performance with Id:", this.performanceId);
    this.logger.info("performance is being deleted by:", deleter);
    if (!isAuthorized(deleter, performance)) {
      return Promise.resolve({
        Attributes: { Deleted: false, Reason: "Permission denied" }
      });
    }
    const targetURL = makeAPIResourceURL(`performances/${this.performanceId}`);
    const reqBody = {
      DeleterId: deleter.sub,
      ReasonForDeletion: reasonForDeletion
    };
    this.logger.debug("calling API on:", targetURL);
    const response = await doDelete(targetURL, reqBody);
    this.logger.debug("API responded with:", response);
    return response.data;
  }
}

const makeAPIResource = resourceType => resourceId =>
  new PerformanceAPIResource(resourceId);

const concertify = {
  mapCMOCodeToName: mapCMOCodeToName,
  performances: {
    getById: getPerformanceById,
    getByCountryCode: getPerformancesByCountryCode,
    getByArtistName: getPerformancesByArtistName,
    getRelevantFor: getRelevantFor,
    addNew: addNewPerformance,
    softDeleteById: softDeletePerformance,
    update: updatePerformance,
    updateSetlist: updatePerformanceSetlist
  },
  comments: {
    getCommentsForPerformance: apiGetCommentsForPerformance,
    addComment: apiAddComment
  },
  works: {
    addMany: addNewWorks,
    query: apiWorksQuery
  },
  cmos: {
    getAll: getAll,
    getByCMOCode: getByCMOCode
  },
  utils: {
    mapCMOCodeToCountry: mapCMOCodeToCountry
  },
  api: {
    performance: makeAPIResource("performance")
  }
};

export default concertify;
