import {
  SEARCH_WORKS_REQUEST,
  SEARCH_WORKS_SUCCESS,
  SEARCH_WORKS_ERROR,
  CLEAR_SEARCH_RESULTS,
  ADD_WORK
} from "redux/actions/types";

const initialState = {
  isFetching: false,
  searchResults: null,
  error: undefined
};

const worksSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_WORKS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case SEARCH_WORKS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
        isFetching: false
      };
    case SEARCH_WORKS_ERROR:
      return {
        ...state,
        error: { errorMsg: action.payload },
        isFetching: false
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: null
      };
    case ADD_WORK:
      return {
        ...state,
        searchResults: null
      };
    default:
      return state;
  }
};

export default worksSearchReducer;
