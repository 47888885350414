// @flow strict
export const UPDATE_SETLIST_WORK_DURATION = "UpdateSetlistWorkDuration";

export type UpdateSetlistWorkDurationAction = {
  type: "UpdateSetlistWorkDuration",
  index: number,
  duration: number
};

export default (
  index: number,
  duration: number
): UpdateSetlistWorkDurationAction => ({
  type: UPDATE_SETLIST_WORK_DURATION,
  index: index,
  duration: duration
});
