// @flow strict
import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@material-ui/core/InputBase";
import ListItem from "@material-ui/core/ListItem";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import { Form, Field } from "react-final-form";
import { OnBlur } from "react-final-form-listeners";
import { connect } from "react-redux";

import { titleCase } from "utils";
import { isMinutesSecondsFormat } from "components/forms/formValidation";
import { mmss2seconds } from "components/dialogs/AddWorkDialog/AddWorkDialog";
import updateSetlistWorkDuration from "redux/actions/updateSetlistWorkDuration";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    direction: "row",
    alignItems: "center",
    color: "#414141",
    boxShadow: "none",
    background: "#F0F4F7",
    padding: 10,
    borderTop: "1px solid #fff",
    borderBottom: "1px solid rgb(204, 204, 228)",
    lineHeight: "1.2em"
  },
  selected: {
    background: theme.palette.grey[300]
  },
  margin: {
    marginRight: 10
  },
  text: {
    flexGrow: 1
  },
  pill: {
    background: "#959fa4",
    padding: "5px 10px",
    borderRadius: 20,
    fontWeight: 900,
    color: "#fff",
    width: 65,
    height: 36,
    paddingTop: 8,
    paddingLeft: 12,
    textAlign: "center"
  },
  input: {
    width: 65,
    height: 36,
    borderRadius: 10,
    padding: 0,
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "1.2em",
    border: "1px solid #d9e6e6"
  },
  ISWC: {
    marginLeft: theme.spacing(1)
  },
  lowOpacity: {
    opacity: 0.3
  },
  pillInput: {
    background: "#673AB7",
    textAlign: "center",
    width: 65,
    height: 36,
    paddingTop: 5,
    paddingLeft: 12,
    borderRadius: 20,
    fontWeight: 900,
    color: "#fff"
  },
  errorTooltip: {
    background: "red"
  }
}));

const DurationInput = ({ readOnly, ...props }) => {
  const classes = useStyles();
  return (
    <InputBase
      readOnly={readOnly}
      className={
        readOnly
          ? [classes.pill, classes.margin].join(" ")
          : [classes.pillInput, classes.margin].join(" ")
      }
      {...props.input}
    />
  );
};

const ErrorTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: red[500],
    color: "white",
    fontSize: "small"
  }
}))(props => <Tooltip data-cy="error-tooltip" {...props} />);

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: grey[500],
    color: "white",
    fontSize: "small"
  }
}))(Tooltip);

type WorkProps = {
  /** Title of the musical work */
  title?: string,
  /** If true, the work's duration cannot be modified */
  readOnly: boolean,
  /** Duration of the musical work */
  duration?: number,
  /** List of rights owners associated with the musical work */
  rightsOwners: Array<string>,
  /** ISWC of the musical work */
  iswc?: string,
  /** Function to call when the "Add" button is clicked */
  onAdd?: Function,
  /** Function to call when the "X" button is clicked */
  onClose?: Function,
  /** Function call when the duration input form is submitted */
  onSubmitDuration?: Function,
  /** Function call when the work is clicked */
  onClick?: Function,
  /** Property determining if the ListItem would be focused */
  focused?: boolean
};

/** Component representing a musical work, song, or any other musical piece with information about its rights owners and other metadata. */
export const Work = ({
  title,
  rightsOwners,
  iswc,
  duration,
  readOnly,
  onAdd,
  onClose,
  onSubmitDuration,
  onClick,
  focused,
  ...rest
}: WorkProps) => {
  const classes = useStyles();
  return (
    <ListItem
      data-role="work-item"
      button
      onKeyDown={e => e.keyCode === 13 && onAdd && onAdd()}
      onClick={e => !e.keyCode && onClick && onClick()}
      autoFocus={focused}
      selected={focused}
      classes={{
        root: classes.root,
        selected: focused && classes.selected
      }}
      {...rest}
    >
      <div>
        <Form
          onSubmit={onSubmitDuration}
          initialValues={{ performanceDuration: seconds2hhmmss(duration) }}
        >
          {({ handleSubmit, form, submitting, pristine, values, invalid }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <ErrorTooltip
                  title="The input must be mm:ss (e.g. 03:40)"
                  open={invalid}
                  placement="left"
                >
                  <div>
                    <InfoTooltip
                      title="Click to edit"
                      disableHoverListener={readOnly ? true : false}
                    >
                      <div>
                        <Field
                          name="performanceDuration"
                          component={DurationInput}
                          type="text"
                          placeholder="03:30"
                          helperText="e.g. 3:15"
                          validate={isMinutesSecondsFormat}
                          readOnly={readOnly ? true : false}
                        />
                      </div>
                    </InfoTooltip>

                    {invalid ? (
                      <OnBlur name="performanceDuration">{form.reset}</OnBlur>
                    ) : (
                      <OnBlur name="performanceDuration">{handleSubmit}</OnBlur>
                    )}
                  </div>
                </ErrorTooltip>
              </div>
            </form>
          )}
        </Form>
      </div>

      <div className={classes.text}>
        <Grid container alignItems="center">
          <Typography variant="subtitle1" data-role="work-title">
            {(title && titleCase(title)) || (
              <span className={classes.lowOpacity}>Musical Work's Title</span>
            )}
          </Typography>
          <Typography
            className={classes.ISWC}
            variant="subtitle2"
            color="textSecondary"
            data-role="iswc"
          >
            {(iswc && `${iswc}`) || (
              <span className={classes.lowOpacity}>ISWC</span>
            )}
          </Typography>
        </Grid>
        <div style={{ opacity: 0.6 }} data-role="rightsOwners">
          {rightsOwners.length > 0 && rightsOwners.join(", ")}
        </div>
      </div>

      {onAdd && (
        <Button
          color="secondary"
          variant="contained"
          onClick={onAdd}
          data-role="addButton"
        >
          Add
        </Button>
      )}
      {onClose && (
        <IconButton onClick={onClose} data-role="closeButton">
          <CloseIcon />
        </IconButton>
      )}
    </ListItem>
  );
};

export const seconds2hhmmss = (sec_num: number): string => {
  if (sec_num === 0 || isNaN(sec_num)) {
    return "00:00";
  }
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  let hourSeparator = ":";

  if (hours === 0) {
    hours = "";
    hourSeparator = "";
  }
  if (minutes < 10 && hours !== 0) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  const time = hours + hourSeparator + minutes + ":" + seconds;
  return time;
};

Work.defaultProps = {
  readOnly: false,
  rightsOwners: [],
  duration: 180,
  onSubmitDuration: e => null
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmitDuration: async (values, form, callback) => {
    dispatch(
      updateSetlistWorkDuration(
        ownProps.index,
        mmss2seconds(values.performanceDuration)
      )
    );
    return undefined; // explicitly tell react-final-form the submission was successful
  }
});

export default connect(null, mapDispatchToProps)(Work);
