// @flow strict

import React from "react";
import { RequireNewPassword } from "aws-amplify-react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import Logo from "components/Logo";
import RootStyle from "../style";

const styles = () => ({
  ...RootStyle
});

class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  showComponent(theme) {
    const { classes } = this.props;
    return (
      <div className={classes.formContainer}>
        <div className={classes.formSection}>
          <div className={classes.headerSection}>
            <div className={classes.logoContainer}>
              <Logo size="large" />
            </div>
            <h3 className={classes.title}>Change Password</h3>
          </div>
          <form onSubmit={super.change}>
            <div className={classes.bodySection}>
              <TextField
                className={classes.textField}
                key="password"
                name="password"
                variant="outlined"
                placeholder="New Password"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.footerSection}>
              <div className={classes.footerSectionPrimaryContent}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Change
                </Button>
              </div>
              <div className={classes.footerSectionSecondaryContent}>
                <a
                  href="/#"
                  className={classes.link}
                  onClick={() => {
                    super.changeState("signIn");
                  }}
                >
                  Back to Sign in
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomRequireNewPassword);
