import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { TERMS_OF_SERVICE_PATH } from "concertify/constants";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2)
  },
  a: {
    color: "#fff",
    textDecoration: "none",
    fontSize: 12
  }
});

const PrivacyStatementFooter = ({ classes, ...props }) => {
  if (props.location.pathname === TERMS_OF_SERVICE_PATH) return <div />;
  return (
    <div className={classes.root}>
      <a href="/privacy-statement" target="_blank" className={classes.a}>
        Concertify Version {process.env.REACT_APP_VERSION}
        {process.env.NODE_ENV !== "production"
          ? `-${process.env.NODE_ENV} `
          : " "}
        - Privacy Statement
      </a>
    </div>
  );
};

export default withRouter(withStyles(styles)(PrivacyStatementFooter));
