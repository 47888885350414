import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import EditingItem from "forms/EditingItem";
import EventValueBox from "components/EventValueBox";

class EditingGroup extends React.Component {
  constructor(props) {
    super(props);
    if (!props.inputs) {
      throw new Error(
        'InvariantViolation: EditingGroup is being instantiated without an "inputs" prop'
      );
    }
    let intermediaryState = {};
    for (var i in this.props.inputs) {
      intermediaryState[i] = this.props.inputs[i];
    }
    this.state = {
      isEditing: false,
      inputs: intermediaryState
    };
  }

  handleSaveClick = () => {
    if (this.state.isEditing) {
      this.props.onSaveClick(this.state.inputs);
    }
    this.setState(state => ({
      isEditing: !state.isEditing
    }));
  };

  handleCancelClick = () => {
    this.setState(state => ({
      isEditing: !state.isEditing,
      inputs: { ...this.props.inputs }
    }));
  };

  handleChange = (label, value) => {
    this.setState(state => {
      state.inputs[label] = value;
      return state;
    });
  };

  renderCancelButton = () => {
    if (this.state.isEditing)
      return (
        <Button
          style={{ marginBottom: "15px", marginLeft: "5px" }}
          onClick={this.handleCancelClick}
        >
          Cancel
        </Button>
      );
  };

  renderEditButton = () => {
    if (!this.props.readOnly && this.props.buttonValue) {
      return (
        <Button onClick={this.handleSaveClick} style={{ marginBottom: "15px" }}>
          {!this.state.isEditing ? this.props.buttonValue : "Save changes"}
        </Button>
      );
    }
  };

  renderEventValueBox = () => {
    if (this.props.showEventValueBox) {
      return (
        <EventValueBox
          performance={this.props.performance}
          readOnly={this.props.readOnly}
          variant={this.props.variant}
        />
      );
    }
  };

  renderItems = () => {
    return Object.entries(this.props.inputs).map(keyValuePair => {
      const label = keyValuePair[0];
      let presetComponent = null;
      if (
        this.props.presetInputs &&
        this.props.presetInputs.hasOwnProperty(label)
      ) {
        const Component = this.props.presetInputs[label].component;
        const onChange = this.props.presetInputs[label].onChange;
        const onBlur = this.props.presetInputs[label].onBlur;
        const props = this.props.presetInputs[label].props;
        presetComponent = (
          <Component
            onChange={onChange(this)}
            onBlur={onBlur(this)}
            {...props}
          />
        );
      }
      return (
        <EditingItem
          key={presetComponent ? null : `${this.state.inputs[label]}-${label}`}
          isLoading={this.props.isLoading}
          label={label}
          value={this.state.inputs[label]}
          isEditing={this.state.isEditing}
          onBlur={e => this.handleChange(label, e.target.value)}
          presetComponent={presetComponent}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <Typography variant="h4" className={this.props.classes.masterheadline}>
          {this.props.title}
        </Typography>
        {this.renderItems()}
        {this.renderEventValueBox()}
        {this.renderEditButton()}
        {this.renderCancelButton()}
      </div>
    );
  }
}

EditingGroup.propTypes = {
  title: PropTypes.string,
  inputs: PropTypes.object.isRequired,
  onSaveClick: PropTypes.func,
  buttonValue: PropTypes.string,
  showEventValueBox: PropTypes.bool,
  licensingState: PropTypes.string,
  updateButtonAvailable: PropTypes.bool
};

EditingGroup.defaultProps = {
  title: ""
};

export default EditingGroup;
