// @flow strict

/**
 * This module exports a data loading component wrapping a presentational Performances container component.
 * For the most common use case (loading relevant live performances for the currently logged in user)
 * the exported component should be used directly, without any explicit props. If the way performances and the
 * currently logged in user are fetched needs to change, use the PresentationalPerformancesContainer wrapped in
 * a different data loading component that sets its `performances` and `userType` props.
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";

import { CMOR, PAR, PERFORMANCES_PATH } from "concertify/constants";
import { PARPerformanceEntry } from "components/performances";
import Danger from "components/Danger";
import { getPerformances } from "redux/actions/filteredPerformances";
import { IsEmpty } from "utils";
import PerformancesTable from "./PerformancesTable/PerformancesTable";

/**
 * DataPerformancesContainer handles the loading logic required to get the currently
 * authenticated user as well as his/her relevant Performances. It renders a `PresentationalPerformancesContainer`
 * with its `performances` and `userType` props set to these values.
 */
const DataPerformancesContainer = ({
  authenticatedUser,
  performanceFilters,
  onFetchPerformances,
  performances,
  userType,
  loading,
  error
}) => {
  const location = useLocation();

  useEffect(() => {
    if (!IsEmpty(authenticatedUser)) {
      if (location.pathname + location.search === PERFORMANCES_PATH)
        onFetchPerformances("domestic");
      if (location.pathname + location.search === "/performances?v=abroad")
        onFetchPerformances("abroad");
    }
    // eslint-disable-next-line
  }, [authenticatedUser, performanceFilters, location.search]);

  return (
    <PresentationalPerformancesContainer
      performances={performances}
      userType={userType}
      loading={loading}
      error={error}
    />
  );
};

/**
 * PresentationalPerformancesContainer renders either PARPerformanceEntry's or
 * CMORPerformanceEntry's from the `performances` prop, based on a `userType` prop.
 *
 * It should not be used by itself nor presented in the UI without a wrapping component that sets its `performances`
 * and `userType` props by loading data remotely. If either prop is not supplied it will render a 'loading' message.
 *
 * `performances` must be an array of Performance objects. `userType` must be one of `CMOR` or `PAR` from
 * `concertify/constants`.
 */

type PresentationalPerformancesContainerProps = {
  performances: Array<Object>,
  userType: String,
  loading: Boolean,
  error: Boolean
};

export const PresentationalPerformancesContainer = ({
  performances,
  userType,
  loading,
  error
}: PresentationalPerformancesContainerProps) => {
  if (loading) {
    return renderLoadingState();
  }

  if (error) {
    return renderErrorState("There was error during loading performances");
  }

  return (
    <div data-cy="performances-list">
      {userType === CMOR && <PerformancesTable />}
      {/* TODO: implementation for the PAR performances is pending */}
      {userType === PAR &&
        performances.map((performance, i) =>
          renderPerformanceForPAR(performance, i)
        )}
    </div>
  );
};

const renderLoadingState = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", padding: 10 }}
      data-cy="performances-list"
    >
      <CircularProgress color="secondary" />
    </div>
  );
};

const renderErrorState = e => <Danger>{e}</Danger>;

const renderPerformanceForPAR = (performance, i) => {
  console.log(performance);
  return <PARPerformanceEntry {...performance} />;
};

PresentationalPerformancesContainer.defaultProps = {
  performances: [],
  loading: false
};

PresentationalPerformancesContainer.propTypes = {
  onFetchPerformances: PropTypes.func,
  performances: PropTypes.arrayOf(PropTypes.object),
  performanceFilters: PropTypes.object,
  authenticatedUser: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  userType: PropTypes.oneOf([CMOR, PAR])
};

const mapStateToProps = state => ({
  performances: state.filteredPerformancesReducer.performances,
  performanceFilters: state.performanceFilterReducer,
  authenticatedUser: state.loginReducer.user,
  loading: state.loginReducer.loading || state.artistsAndCMOsReducer.loading,
  error:
    state.loginReducer.error ||
    state.artistsAndCMOsReducer.error ||
    state.filteredPerformancesReducer.error,
  userType: state.loginReducer.type
});

const mapDispatchToProps = dispatch => ({
  onFetchPerformances: relevance => dispatch(getPerformances(relevance))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataPerformancesContainer);
