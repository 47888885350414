import { combineReducers } from "redux";
import navReducer from "redux/reducers/navReducer";
import performanceDetailsReducer from "redux/reducers/performanceDetailsReducer";
import worksSearchReducer from "redux/reducers/worksSearchReducer";
import loginReducer from "redux/reducers/loginReducer";
import snackbarReducer from "redux/reducers/snackbarReducer";
import dialogReducer from "redux/reducers/dialogReducer";
import performanceCommentReducer from "redux/reducers/performanceCommentReducer";
import performanceFilterReducer from "./performanceFilterReducer";
import artistsAndCMOsReducer from "redux/reducers/artistsAndCMOsReducer";
import initiateDataFromServerReducer from "redux/reducers/initiateDataFromServerReducer";
import filteredPerformancesReducer from "redux/reducers/filteredPerformancesReducer";

const rootReducer = combineReducers({
  navReducer,
  performanceDetailsReducer,
  worksSearchReducer,
  loginReducer,
  snackbarReducer,
  dialogReducer,
  performanceCommentReducer,
  artistsAndCMOsReducer,
  initiateDataFromServerReducer,
  performanceFilterReducer,
  filteredPerformancesReducer
});

export default rootReducer;
