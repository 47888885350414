import {
  currentAuthenticatedUser,
  clearCurrentAuthenticatedUser
} from "redux/actions/loginActions";
import {
  loadArtistsAndCMOs,
  clearArtistsAndCMOs
} from "redux/actions/artistsAndCMOs";
import { clearPerformances } from "redux/actions/filteredPerformances";
import { clearPerformancesFilters } from "redux/actions/performanceFilterActions";

import {
  INITIATE_DATA_FROM_SERVER_SUCCESS,
  CLEAR_DATA_FROM_SERVER
} from "redux/actions/types";

export const initiateServerData = () => async dispatch => {
  await dispatch(loadArtistsAndCMOs());
  dispatch(currentAuthenticatedUser());
  dispatch({
    type: INITIATE_DATA_FROM_SERVER_SUCCESS
  });
};

export const clearServerData = () => async dispatch => {
  dispatch(clearCurrentAuthenticatedUser());
  dispatch(clearArtistsAndCMOs());
  dispatch(clearPerformances());
  dispatch(clearPerformancesFilters());
  dispatch({ type: CLEAR_DATA_FROM_SERVER });
};
