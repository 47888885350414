import React from "react";
import PropTypes from "prop-types";

import StatusBadge from "components/status/StatusBadge";
import GrayRow from "components/structural/GrayRow";
import { simpleDate } from "utils";
import {
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS,
  PERFORMANCE_STATUSES
} from "concertify/constants";

import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";

const styles = {
  column: {
    border: "1px solid red"
  },
  columnValue: {
    opacity: 0.6
  },
  missing: {
    color: "#ff6303"
  },
  link: {
    textDecoration: "none"
  }
};

const BadgeComponentFor = status => {
  switch (status) {
    case NEW_STATUS:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
    case SCHEDULED_STATUS:
      return <StatusBadge variant="deepPurple" label={SCHEDULED_STATUS} />;
    case INVOICED_STATUS:
      return <StatusBadge variant="lightBlue" label={INVOICED_STATUS} />;
    case COLLECTED_STATUS:
      return <StatusBadge variant="lightGreen" label={COLLECTED_STATUS} />;
    case DISTRIBUTED_STATUS:
      return <StatusBadge variant="green" label={DISTRIBUTED_STATUS} />;
    default:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
  }
};

const Column = withStyles(theme => ({
  column: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      paddingBottom: 10,
      borderBottom: "1px solid #e0e0e0"
    }
  },
  wrapper: {
    width: "100%"
  }
}))(({ classes, children }) => (
  <Grid
    item
    container
    lg={1}
    xs={12}
    justify="center"
    alignItems="center"
    className={classes.column}
  >
    <div className={classes.wrapper}>{children}</div>
  </Grid>
));

const CMORPerformanceEntry = ({
  classes,
  id,
  status,
  artist,
  date,
  city,
  country,
  venue,
  homeCmo,
  recipients,
  setlist,
  messages
}) => {
  return (
    <Link
      data-role="link-to-performance"
      to={`/performances/${id}`}
      className={classes.link}
    >
      <GrayRow data-cy="performance">
        <Grid container justify="space-evenly">
          <Column>{BadgeComponentFor(status)}</Column>
          <Column>
            <Typography variant="subtitle1">{artist}</Typography>
            <div className={classes.columnValue}>Artist</div>
          </Column>
          <Column>
            <Typography variant="subtitle1">{simpleDate(date)}</Typography>
            <div className={classes.columnValue}>Date</div>
          </Column>
          <Column>
            <Typography variant="subtitle1">
              {[city, country].join(", ")}
            </Typography>
            <div className={classes.columnValue}>City &amp; country</div>
          </Column>
          <Column>
            <Typography variant="subtitle1">{venue}</Typography>
            <div className={classes.columnValue}>Venue</div>
          </Column>
          <Column>
            <Typography variant="subtitle1">{homeCmo}</Typography>
            <div className={classes.columnValue}>Home CMO</div>
          </Column>
          {/*}
          <Column>
            <Typography variant="subtitle1">{recipients.join(", ")}</Typography>
            <div className={classes.columnValue}>Royalty recipients</div>
          </Column>
          */}
          <Column>
            {setlist > 0 ? (
              <Typography variant="subtitle1">{setlist} songs</Typography>
            ) : (
              <Typography variant="subtitle1" className={classes.missing}>
                Missing
              </Typography>
            )}
            <div className={classes.columnValue}>Setlist</div>
          </Column>
          <Grid
            item
            container
            lg={1}
            alignItems="center"
            justify="space-between"
          >
            <ChevronRightIcon />
          </Grid>
        </Grid>
      </GrayRow>
    </Link>
  );
};

CMORPerformanceEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(PERFORMANCE_STATUSES).isRequired,
  artist: PropTypes.string.isRequired,
  date: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  venue: PropTypes.string,
  homeCmo: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.string),
  setlist: PropTypes.number,
  messages: PropTypes.number.isRequired
};

export default withStyles(styles)(CMORPerformanceEntry);
