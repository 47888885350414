import React, { useState } from "react";

import { connect } from "react-redux";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

import RadioButtonsGroup from "./RadioButtonsGroup";
import { updatePerformance } from "redux/actions/performanceActions";
import openDialog from "redux/actions/openDialog";
import closeDialog from "redux/actions/closeDialog";
import { PERFORMANCE_STATUSES, NEW_STATUS } from "concertify/constants";

const PerformanceUpdateRadioButtons = ({
  performance,
  onChange,
  onConfirm,
  onCloseDialog
}) => {
  let currentPerformanceStatus = NEW_STATUS;
  if (PERFORMANCE_STATUSES.includes(performance.Status)) {
    currentPerformanceStatus = performance.Status;
  }
  const [currentPerformanceState, setCurrentPerformanceState] = useState(
    NEW_STATUS
  );

  return (
    <div>
      <ConfirmationDialog
        onConfirm={() => {
          onCloseDialog();
          onConfirm(performance.PerformanceId, {
            Status: currentPerformanceState
          });
        }}
        onClose={onCloseDialog}
        dialogTitle="Confirm performance status change"
        customText={`Are you sure you would like to change this performance status to "${currentPerformanceState}"?`}
      />
      <RadioButtonsGroup
        selectionOptions={PERFORMANCE_STATUSES}
        startingValue={currentPerformanceStatus}
        onChange={performanceState => {
          setCurrentPerformanceState(performanceState);
          onChange();
        }}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onChange: () => dispatch(openDialog("StatusConfirmationDialog")),
  onCloseDialog: () => dispatch(closeDialog()),
  onConfirm: async (performanceId, payload) => {
    const action = await updatePerformance(performanceId, payload);
    dispatch(action);
  }
});

const mapStateToProps = state => {
  return {
    performance: state.performanceDetailsReducer.currentPerformanceDetails
  };
};

const ConnectedPerformanceUpdateRadioButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceUpdateRadioButtons);

export default ConnectedPerformanceUpdateRadioButtons;
