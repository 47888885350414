/**
 * This module defines all the necessary components needed to render a fully-working Performance Details View.
 * Its default export is a PerformanceDetailsView component which already includes all the logic necessary to
 * fetch and display the information of a single performance given a valid `performanceId`.
 *
 * It additionally exposes a pure PresentationalPerformanceDetailsView component as a secondary export. This component
 * takes a single `performance` prop representing a Performance object and displays the Performance object's information without
 * any sort of remote calls or side-effects.
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPlaceholder from "react-placeholder";

import { withStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

import { PAR } from "concertify/constants";
import { downloadPerformanceAsCSV } from "concertify/exporting";

import AddWorkDialog from "components/dialogs/AddWorkDialog";
import PerformanceDetailsSubMenu from "components/PerformanceDetailsSubMenu";
import PerformanceStatusStepper from "components/status/PerformanceStatusStepper";
import { PerformanceSummary } from "components/performances";
import PerformanceBackgroundInfo from "components/performances/PerformanceBackgroundInfo";
import DeletePerformanceConfirmationDialog from "components/dialogs/DeletePerformanceConfirmationDialog";
import MainBody from "components/structural/MainBody";
import loadPerformance from "redux/actions/loadPerformance";
import saveSetlist from "redux/actions/saveSetlist";
import { getCMOName } from "redux/actions/artistsAndCMOs";
import openDialog from "redux/actions/openDialog";
import { DELETE_PERFORMANCE } from "redux/actions/types";
import { tryOrUndefined, IsEmpty } from "utils";

import SetlistSection from "./_SetlistSection";
import CommentsSection from "./CommentsSection";

const styles = theme => ({
  gray: {
    background: "#cfd5d8",
    marginRight: theme.spacing(2),
    color: "#62627d"
  },
  blue: {
    background: "#028dc7",
    color: "#fff"
  },
  orange: {
    background: "#ff7401",
    color: "#fff",
    "&:hover": {
      background: "#ea6500"
    }
  },
  gutter: {
    marginTop: "10px",
    marginRight: "10px"
  },
  sectionHeading: {
    textTransform: "uppercase",
    fontSize: 14,
    color: "#fff",
    opacity: 0.5,
    letterSpacing: 1
  },
  darkSection: {
    background: "#363762",
    padding: theme.spacing(2)
  },
  setlistSection: {
    padding: theme.spacing(2),
    background: "#F5F7F8"
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  danger: {
    color: red[500],
    marginLeft: theme.spacing(1)
  },
  success: {
    color: green[500],
    marginLeft: theme.spacing(1)
  },
  warning: {
    padding: theme.spacing(1),
    borderRadius: 5
  },
  addWorkButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  margins: {
    marginTop: 3,
    marginBottom: theme.spacing(2)
  },
  unconfirmedButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light300,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  clearSetlistButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: theme.palette.grey[700]
    }
  }
});

/**
 * PerformanceDetailsFetcher is a HOC component responsible for fetching information about a single Live Performance.
 * It takes a single `performanceId` prop referring to the ID of the performance to fetch.
 * In its current implementation it doesn't take any custom wrapped Component to inject props into, but rather
 * returns an instance of a PresentationalPerformancesView with its `performance` prop set to the fetched Performance.
 */
const PerformanceDetailsView = ({
  onLoadPerformance,
  onSaveSetlist,
  onGetCMOName,
  onDelete,
  userData,
  performance,
  performanceId,
  CMOs,
  cmoLoading
}) => {
  useEffect(() => {
    onLoadPerformance(performanceId);
    // eslint-disable-next-line
  }, []);

  const saveSetlist = async works =>
    await onSaveSetlist(this.props.performanceId, "", works);

  return (
    <PresentationalPerformanceDetailsView
      userType={userData.user.type}
      performance={performance}
      performanceId={performanceId}
      saveSetlist={saveSetlist}
      hideComments={userData.user.type === PAR}
      authenticatedUser={userData.user}
      toggles={{}}
      cmoLoading={cmoLoading}
      userLoading={userData.loading}
      onGetCMOName={onGetCMOName}
      deletePerformance={onDelete}
      CMOs={CMOs}
    />
  );
};

export const UnstyledPresentationalPerformanceDetailsView = props => {
  const {
    performance,
    userType,
    classes,
    performanceId,
    hideComments,
    authenticatedUser,
    cmoLoading,
    userLoading,
    onGetCMOName,
    deletePerformance,
    CMOs
  } = props;
  if (performance === undefined) {
    throw new Error(
      'PresentationalPerformanceDetailsView: an undefined "performance" prop represents an invalid state'
    );
  }

  const getPerformanceSource = performance => {
    const submitterId = tryOrUndefined(
      performance,
      p => p.OriginMetaData.SubmitterId
    );
    if (submitterId === "1") {
      return "Online ticket vendor";
    }
    const submitterCMOCode = tryOrUndefined(
      performance,
      p => p.OriginMetaData.SubmitterCMOCode
    );
    if (!submitterCMOCode) {
      return "Concertify";
    }
    try {
      return onGetCMOName(submitterCMOCode);
    } catch (e) {
      console.error(e);
    }
    return submitterCMOCode;
  };

  const fileName = `ConcertifyLivePerformance_${performance.Date ||
    "Unknown Date"}_${(performance.PerformingArtist &&
    performance.PerformingArtist.Name) ||
    "Unknown Artist"}_${(performance.Venue && performance.Venue.Name) ||
    "Unknown Venue"}_${performance.PerformanceId}`.replace(/ /g, "-");

  return (
    <MainBody
      subNavComponent={
        <PerformanceDetailsSubMenu
          onDelete={deletePerformance}
          onDownload={
            !IsEmpty(authenticatedUser) &&
            authenticatedUser.type !== PAR &&
            downloadPerformanceAsCSV(CMOs, performance, `${fileName}.csv`)
          }
        />
      }
    >
      <PerformanceStatusStepper status={performance.status} />

      <PerformanceSummary
        performance={performance}
        isLoading={Object.keys(performance).length === 0}
        cmoLoading={cmoLoading}
        source={getPerformanceSource(performance)}
        homeCMO={onGetCMOName(performance.CMOCode)}
      />

      <PerformanceBackgroundInfo
        readOnly={userType === PAR}
        performance={performance}
        isLoading={Object.keys(performance).length === 0}
        cmoLoading={cmoLoading}
        authenticatedUser={authenticatedUser}
      />

      <SetlistSection classes={classes} />
      <AddWorkDialog open={false} />

      <ReactPlaceholder showLoadingAnimation ready={!userLoading}>
        {!hideComments && <CommentsSection performanceId={performanceId} />}
      </ReactPlaceholder>

      <DeletePerformanceConfirmationDialog deleter={authenticatedUser} />
    </MainBody>
  );
};

/**
 * PresentationalPerformanceDetailsView is a functional component that takes a single `performance` prop
 * and renders a detailed view of the performance's information.
 */

UnstyledPresentationalPerformanceDetailsView.defaultProps = {
  onGetCMOName: () => {}
};

export const PresentationalPerformanceDetailsView = withStyles(styles)(
  UnstyledPresentationalPerformanceDetailsView
);

PerformanceDetailsView.propTypes = {
  onLoadPerformance: PropTypes.func.isRequired,
  onSaveSetlist: PropTypes.func.isRequired,
  performanceId: PropTypes.string.isRequired,
  performance: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  performance: state.performanceDetailsReducer.currentPerformanceDetails,
  cmoLoading: state.artistsAndCMOsReducer.loading,
  CMOs: state.artistsAndCMOsReducer.CMOs,
  userData: state.loginReducer
});

const mapDispatchToProps = dispatch => ({
  onSaveSetlist: (performanceId, status, works) =>
    dispatch(saveSetlist(performanceId, status, works)),
  onLoadPerformance: performanceId => {
    dispatch(loadPerformance(performanceId));
  },
  onGetCMOName: cmoCode => dispatch(getCMOName(cmoCode)),
  onDelete: () => {
    dispatch(openDialog(DELETE_PERFORMANCE));
  }
});

/**
 * PerformanceDetailsView takes a single `performanceId` indicating the performance that should be loaded. It
 * includes logic for fetching the performance's information, updating the Redux store with the fetched performance,
 * and displaying it by a regular redux prop to state mapping.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceDetailsView);
