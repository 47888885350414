import {
  CURRENT_AUTH_USER_REQUEST,
  CURRENT_AUTH_USER_SUCCESS,
  CURRENT_AUTH_USER_ERROR,
  CURRENT_AUTH_USER_CLEAR
} from "../actions/types";
import { COGNITO_CMORS_GROUP, CMOR, PAR } from "concertify/constants";

const initialState = {
  user: {},
  loading: false,
  type: undefined,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CURRENT_AUTH_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CURRENT_AUTH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
        type: action.payload.groups.includes(COGNITO_CMORS_GROUP) ? CMOR : PAR
      };
    case CURRENT_AUTH_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case CURRENT_AUTH_USER_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
}
