import {
  CHANGE_PERFORMANCE_RANGE_FILTER,
  CHANGE_PERFORMANCE_TYPE_FILTER,
  CHANGE_PERFORMANCE_SEARCH_FILTER,
  CHANGE_PERFORMANCE_SEARCH_PARAMETER,
  CHANGE_PERFORMANCE_ITEMS_PER_PAGE,
  CHANGE_PERFORMANCE_PAGE_NUMBER,
  CHANGE_PERFORMANCE_RELEVANCE,
  CHANGE_PERFORMANCE_SORT_BY,
  CLEAR_PERFORMANCES_FILTERS
} from "../types";

export const changePerformanceRangeFilter = dateRange => {
  return {
    type: CHANGE_PERFORMANCE_RANGE_FILTER,
    payload: { dateRange }
  };
};

export const changePerformanceTypeFilter = type => {
  return {
    type: CHANGE_PERFORMANCE_TYPE_FILTER,
    payload: type
  };
};

export const changePerformanceSearchFilter = search => {
  return {
    type: CHANGE_PERFORMANCE_SEARCH_FILTER,
    payload: search
  };
};

export const changePerformanceSearchParameter = searchParameter => {
  return {
    type: CHANGE_PERFORMANCE_SEARCH_PARAMETER,
    payload: searchParameter
  };
};

export const changePerformancePageNumber = pageNumber => {
  return {
    type: CHANGE_PERFORMANCE_PAGE_NUMBER,
    payload: pageNumber
  };
};

export const changePerformanceItemsPerPage = itemsPerPage => {
  return {
    type: CHANGE_PERFORMANCE_ITEMS_PER_PAGE,
    payload: itemsPerPage
  };
};

export const changePerformanceRelevance = relevance => {
  return {
    type: CHANGE_PERFORMANCE_RELEVANCE,
    payload: relevance
  };
};

export const changePerformanceSortBy = sortBy => {
  return {
    type: CHANGE_PERFORMANCE_SORT_BY,
    payload: sortBy
  };
};

export const clearPerformancesFilters = () => async dispatch => {
  dispatch({
    type: CLEAR_PERFORMANCES_FILTERS
  });
};
