// @flow strict

import React, { Fragment, useState } from "react";
import Auth from "@aws-amplify/auth";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

import { CMOR, PAR, PERFORMANCES_PATH } from "concertify/constants";
import Logo from "components/Logo";
import toggleDrawer from "redux/actions/toggleDrawerAction";
import { clearServerData } from "redux/actions/initiateDataFromServer";
import { changePerformanceRelevance } from "redux/actions/performanceFilterActions";

const headerStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    padding: "5px 10px",
    minHeight: 60
  },
  menuIcon: {
    color: "#fff"
  },
  button: {
    marginLeft: 16
  }
}));

type HeaderProps = {
  /** Name of CMO group of signed in user. */
  cmoName?: string,
  /** Type of signed in user cmor/par. */
  userType?: string,
  /** Name of signed in user. */
  userName?: string,
  /** Boolean value for displaying spinner. */
  loading?: boolean,
  /** Function which will be used to open sidebar. */
  onMenuOpen?: Function,
  /** Function which is called on user sign out. */
  onSignOut?: Function,
  /** Function which is called to filter performances depending on relevance level. */
  onRelevanceChange: Function
};

/**
 * Main header component which will be displayed only when user is signed in. It contains links to
 * change performances view by licensing/abroad, button for sign out, and if CMO is signed in there
 * will be one button with its name and name of CMO group.
 *
 * In mobile view there will be only button for opening sidebar.
 */

export const Header = ({
  cmoName,
  userType,
  userName,
  loading,
  onMenuOpen,
  onSignOut,
  onRelevanceChange
}: HeaderProps) => {
  const classes = headerStyles();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(
    () => location.pathname + location.search
  );

  return (
    <AppBar position="static" classes={{ root: classes.root }}>
      <Container disableGutters maxWidth="lg">
        <Grid container direction="row">
          <Grid item container xs={8} sm={4} alignItems="center">
            <Logo size="medium" />
          </Grid>

          <Grid
            item
            container
            xs={4}
            sm={8}
            alignItems="center"
            justify="flex-end"
            wrap="nowrap"
          >
            <ShowInLargeScreens>
              {loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Fragment>
                  {userType === CMOR && (
                    <Fragment>
                      <NavigationLink
                        active={currentPath === PERFORMANCES_PATH}
                        to={PERFORMANCES_PATH}
                        onClick={path => {
                          onRelevanceChange("domestic");
                          setCurrentPath(path);
                        }}
                      >
                        Licensing
                      </NavigationLink>
                      <NavigationLink
                        active={currentPath === "/performances?v=abroad"}
                        to="/performances?v=abroad"
                        onClick={path => {
                          onRelevanceChange("abroad");
                          setCurrentPath(path);
                        }}
                      >
                        Abroad
                      </NavigationLink>
                      {userName && cmoName && (
                        <Button
                          id="displayName"
                          data-name="user-cmo-button"
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                        >
                          {userName}/{cmoName}
                        </Button>
                      )}
                    </Fragment>
                  )}
                  {userType === PAR && (
                    <Fragment>
                      <NavigationLink
                        active={currentPath === PERFORMANCES_PATH}
                        to={PERFORMANCES_PATH}
                        onClick={path => setCurrentPath(path)}
                      >
                        Performances
                      </NavigationLink>
                      {userName && (
                        <Button
                          id="displayName"
                          data-name="user-cmo-button"
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                        >
                          {userName}
                        </Button>
                      )}
                    </Fragment>
                  )}
                  <Button
                    id="signOutButton"
                    className={classes.button}
                    onClick={() => {
                      if (onSignOut) onSignOut();
                      Auth.signOut();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    Sign out
                  </Button>
                </Fragment>
              )}
            </ShowInLargeScreens>

            <ShowInSmallScreens>
              <IconButton data-name="menu-button" onClick={onMenuOpen}>
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </ShowInSmallScreens>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

const mapDispatchToProps = dispatch => ({
  onMenuOpen: () => dispatch(toggleDrawer()),
  onSignOut: () => dispatch(clearServerData()),
  onRelevanceChange: relevance =>
    dispatch(changePerformanceRelevance(relevance))
});

const mapStateToProps = state => ({
  cmoName: state.loginReducer.user.cmoName,
  userType: state.loginReducer.user.type,
  userName: state.loginReducer.user.name,
  loading: state.loginReducer.loading || state.artistsAndCMOsReducer.loading
});

export default connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps
)(Header);

const ShowInLargeScreens = ({ children }) => <Hidden xsDown>{children}</Hidden>;
const ShowInSmallScreens = ({ children }) => <Hidden smUp>{children}</Hidden>;

const navigationLinkStyles = makeStyles(theme => ({
  link: {
    color: "#fff",
    borderRadius: 0,
    opacity: 0.8,
    marginLeft: 16,
    "&:hover": {
      opacity: 1
    }
  },
  activeLink: {
    boxSizing: "border-box",
    border: "1px solid #B3B3B3",
    borderRadius: 4,
    opacity: 1
  }
}));

const NavigationLink = ({ children, active, to, onClick, ...rest }) => {
  const classes = navigationLinkStyles();
  return (
    <Button
      component={Link}
      to={to}
      className={clsx(classes.link, active && classes.activeLink)}
      onClick={() => onClick(to)}
      disableRipple
      {...rest}
    >
      {children}
    </Button>
  );
};
