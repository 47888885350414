import { Logger } from "@aws-amplify/core";

import { apiMock } from "performances/utils";
import {
  LOAD_PERFORMANCES_REQUEST,
  LOAD_PERFORMANCES_SUCCESS,
  LOAD_PERFORMANCES_ERROR,
  CLEAR_PERFORMANCES,
  OPEN_SNACKBAR
} from "redux/actions/types";
import openDialog from "../openDialog";
import closeDialog from "../closeDialog";

const logger = new Logger("loadLatestPerformancesByArtist");

export const getPerformances = relevance => async (dispatch, getState) => {
  dispatch({ type: LOAD_PERFORMANCES_REQUEST });
  dispatch(openDialog("BackDropDialog"));
  try {
    const state = getState();
    const user = state.loginReducer.user;
    const filters = state.performanceFilterReducer;
    const performancesData = await apiMock(user, filters, relevance);
    dispatch({
      type: LOAD_PERFORMANCES_SUCCESS,
      payload: performancesData
    });
    await dispatch(closeDialog());
  } catch (e) {
    logger.error(e);
    dispatch({
      type: LOAD_PERFORMANCES_ERROR
    });
    dispatch(closeDialog());
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message: "Error fetching performances. Please try again later"
    });
  }
};

export const clearPerformances = () => async dispatch => {
  dispatch({
    type: CLEAR_PERFORMANCES
  });
};
