import { TOGGLE_DRAWER_ACTION } from "redux/actions/toggleDrawerAction";

const initialState = {
  navigation: {
    drawerOpen: false
  }
};

const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DRAWER_ACTION:
      return {
        navigation: {
          drawerOpen: !state.navigation.drawerOpen
        }
      };
    default:
      return state;
  }
};

export default navReducer;
