export const CREATE_PERFORMANCE = "CreatePerformance";
export const CURRENT_AUTH_USER_REQUEST = "CurrentAuthUserRequest";
export const CURRENT_AUTH_USER_SUCCESS = "CurrentAuthUserSuccess";
export const CURRENT_AUTH_USER_ERROR = "CurrentAuthUserError";
export const CURRENT_AUTH_USER_CLEAR = "CurrentAuthUserClear";

export const UPDATE_PERFORMANCE = "UpdatePerformance";
export const CLEAR_PERFORMANCE = "ClearPerformance";
export const CLEAR_SEARCH_RESULTS = "ClearSearchResults";
export const ADD_WORK = "AddWork";
export const REMOVE_WORK = "RemoveWork";
export const CLEAR_SETLIST = "ClearSetlist";

export const SEARCH_WORKS_REQUEST = "SearchWorksRequest";
export const SEARCH_WORKS_SUCCESS = "SearchWorksSuccess";
export const SEARCH_WORKS_ERROR = "SearchWorksError";

export const DELETE_PERFORMANCE_REQUEST = "DeletePerformanceRequest";
export const DELETE_PERFORMANCE_SUCCESS = "DeletePerformanceSuccess";
export const DELETE_PERFORMANCE_ERROR = "DeletePerformanceError";

export const OPEN_SNACKBAR = "OpenSnackbar";
export const CLOSE_SNACKBAR = "CloseSnackbar";

export const OPEN_DIALOG = "OpenDialog";
export const CLOSE_DIALOG = "CloseDialog";

export const DELETE_PERFORMANCE = "DeletePerformance";

export const UPDATE_PERFORMANCE_REQUEST = "UpdatePerformanceRequest";
export const UPDATE_PERFORMANCE_ERROR = "UpdatePerformanceError";
export const UPDATE_PERFORMANCE_SUCCESS = "UpdatePerformanceSuccess";

export const SELECT_COMMENTS = "SelectComments";
export const SELECT_COMMENTS_REQUEST = "SelectCommentsRequest";
export const SELECT_COMMENTS_SUCCESS = "SelectCommentsSuccess";
export const SELECT_COMMENTS_ERROR = "SelectCommentsError";

export const CREATE_COMMENT = "CreateComment";
export const CREATE_COMMENT_REQUEST = "CreateCommentRequest";
export const CREATE_COMMENT_SUCCESS = "CreateCommentSuccess";
export const CREATE_COMMENT_ERROR = "CreateCommentError";

export const SAVE_SETLIST_REQUEST = "SaveSetlistRequest";
export const SAVE_SETLIST_SUCCESS = "SaveSetlistSuccess";
export const SAVE_SETLIST_ERROR = "SaveSetlistError";

export const ADD_USERGENERATED_WORK = "AddUsergeneratedWork";

export const LOAD_PERFORMANCE_REQUEST = "LoadPerformanceRequest";
export const LOAD_PERFORMANCE_SUCCESS = "LoadPerformanceSuccess";
export const LOAD_PERFORMANCE_ERROR = "LoadPerformanceError";

export const LOAD_PERFORMANCES_REQUEST = "LoadPerformancesRequest";
export const LOAD_PERFORMANCES_SUCCESS = "LoadPerformancesSuccess";
export const LOAD_PERFORMANCES_ERROR = "LoadPerformancesError";
export const CLEAR_PERFORMANCES = "ClearPerformances";

export const LOAD_CMOS_REQUEST = "LoadCMOsRequest";
export const LOAD_CMOS_SUCCESS = "LoadCMOsSuccess";
export const LOAD_CMOS_ERROR = "LoadCMOsError";
export const CLEAR_CMOS = "ClearCMOs";

export const INITIATE_DATA_FROM_SERVER_SUCCESS =
  "InitiateDataFromServerSuccess";
export const CLEAR_DATA_FROM_SERVER = "ClearDataFromServer";

export const CHANGE_PERFORMANCE_RANGE_FILTER = "ChangePerformanceRangeFilter";
export const CHANGE_PERFORMANCE_TYPE_FILTER = "ChangePerformanceTypeFilter";
export const CHANGE_PERFORMANCE_SEARCH_FILTER = "ChangePerformanceSearchFilter";
export const CHANGE_PERFORMANCE_SEARCH_PARAMETER =
  "ChangePerformanceSearchParameter";
export const CHANGE_PERFORMANCE_RELEVANCE = "ChangePerformanceItemsRelevance";
export const CHANGE_PERFORMANCE_SORT_BY = "ChangePerformanceSortBy";
export const CHANGE_PERFORMANCE_PAGE_NUMBER = "ChangePerformancePageNumber";
export const CHANGE_PERFORMANCE_ITEMS_PER_PAGE =
  "ChangePerformanceItemsPerPage";
export const CLEAR_PERFORMANCES_FILTERS = "ClearPerformancesFilters";

export const REUSE_SETLIST = "ReuseSetlist";

export const LOAD_LATEST_PERFORMANCES_BY_ARTIST_REQUEST =
  "LoadLatestPerformancesByArtistRequest";
export const LOAD_LATEST_PERFORMANCES_BY_ARTIST_SUCCESS =
  "LoadLatestPerformancesByArtistSuccess";
export const LOAD_LATEST_PERFORMANCES_BY_ARTIST_ERROR =
  "LoadLatestPerformancesByArtistError";
