import { Logger } from "aws-amplify";
import {
  DELETE_PERFORMANCE_REQUEST,
  DELETE_PERFORMANCE_SUCCESS,
  DELETE_PERFORMANCE_ERROR,
  OPEN_SNACKBAR,
  CLOSE_DIALOG
} from "./types";

import concertify from "concertify/core";

const logger = new Logger("deletePerformance");

export const deletePerformance = (
  deleter,
  performanceId,
  reasonForDeletion
) => async dispatch => {
  try {
    dispatch({ type: DELETE_PERFORMANCE_REQUEST });

    await concertify.api
      .performance(performanceId)
      .delete(deleter, reasonForDeletion);

    dispatch({ type: DELETE_PERFORMANCE_SUCCESS });
    dispatch({ type: CLOSE_DIALOG });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "success",
      message: "Performance deleted successfully"
    });
  } catch (e) {
    logger.error(e);
    dispatch({ type: DELETE_PERFORMANCE_ERROR, payload: e.message });
    dispatch({ type: CLOSE_DIALOG });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message:
        "Something went wrong while trying to delete this performance. Please try again later."
    });
  }
};

export default deletePerformance;
