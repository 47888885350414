import React from "react";
import parseGooglePlace from "parse-google-place";
import PropTypes from "prop-types";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import SearchIcon from "@material-ui/icons/Search";

import Input from "./Input";

class GoogleMapsSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      value: this.props.value
    };
  }

  handleInputChange = e => {
    this.setState({ search: e.target.value, value: e.target.value });
    this.props.handleTyping(e);
  };

  handleSelectSuggest = (address, place) => {
    this.setState({ search: "", value: place.terms[0].value });
    this.props.handleSelect &&
      this.props.handleSelect(parseGooglePlace(address), address, place);
  };

  render() {
    const { search, value } = this.state;
    return (
      <ReactGoogleMapLoader
        params={{
          key: this.props.apiKey,
          libraries: "places,geocode"
        }}
        render={googleMaps =>
          googleMaps && (
            <ReactGooglePlacesSuggest
              autocompletionRequest={{ input: search }}
              googleMaps={googleMaps}
              onSelectSuggest={this.handleSelectSuggest}
            >
              <Input
                textFieldProps={{
                  type: "text",
                  value: value,
                  onChange: this.handleInputChange,
                  ...this.props.inputProps
                }}
                icon={<SearchIcon />}
              />
            </ReactGooglePlacesSuggest>
          )
        }
      />
    );
  }
}

GoogleMapsSuggest.propTypes = {
  googleMaps: PropTypes.object,
  apiKey: PropTypes.string.isRequired
};

export default GoogleMapsSuggest;
