// @flow strict

import React from "react";
import type { Node } from "react";
import {
  Authenticator,
  ConfirmSignUp,
  RequireNewPassword,
  ForgotPassword,
  Greetings,
  SignIn,
  SignUp,
  VerifyContact
} from "aws-amplify-react";
import CustomSignIn from "./CustomSignIn";
import CustomSignUp from "./CustomSignUp";
import CustomForgotPassword from "./CustomForgotPassword/";
import CustomConfirmSignUp from "./CustomConfirmSignUp";
import CustomRequireNewPassword from "./CustomRequireNewPassword";
import theme from "../ConcertifyMuiTheme";

type LoginProps = {
  /** Wrapped main application component and helper components. */
  children?: Node
};

const ConcertifyTheme = {
  container: {
    backgroundColor: "#2E2E64",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  formContainer: {
    margin: 0,
    paddingTop: "15%"
  },
  sectionHeader: {
    color: theme.palette.common.white
  },
  inputLabel: {
    color: theme.palette.common.white
  },
  formSection: {
    background: "transparent",
    boxShadow: "none"
  },
  sectionFooter: {
    display: "block"
  },
  button: {
    minWidth: 380
  },
  toast: {
    backgroundColor: "#EB4A41"
  }
};

/** Component that is incorporating AWS Amplify Authenticator wrapper HOC for adding Login/Register functionality to
 * application with custom styled UI components, adopted to Material-UI styling.
 */

const Login = ({ children }: LoginProps) => {
  return (
    <Authenticator
      usernameAttributes="email"
      hide={[
        SignUp,
        SignIn,
        Greetings,
        VerifyContact,
        ForgotPassword,
        ConfirmSignUp,
        RequireNewPassword
      ]}
      theme={ConcertifyTheme}
    >
      <CustomConfirmSignUp />
      <CustomSignIn />
      <CustomSignUp />
      <CustomForgotPassword />
      <CustomRequireNewPassword />
      {children}
    </Authenticator>
  );
};

export default Login;
