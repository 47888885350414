import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import dinero from "dinero.js";

export const AuthContext = React.createContext();

export const withSession = Component => {
  const session = {
    loggedIn: localStorage.getItem("loggedIn"),
    user: {
      id: localStorage.getItem("user.id"),
      firstName: localStorage.getItem("user.firstName"),
      email: localStorage.getItem("user.email"),
      type: localStorage.getItem("user.type"),
      artists: localStorage.getItem("user.artists"),
      activeArtist: localStorage.getItem("user.activeArtist"),
      cmo: localStorage.getItem("user.cmo")
    }
  };
  return props => <Component session={session} {...props} />;
};

export const IsEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const toArray = object => Object.keys(object).map(key => object[key]);

export const sum = array => array.reduce((a, b) => a + b);

// remove the time portion of a datetime object and return only DD-MM-YYY
export const simpleDate = dateStr => {
  if (dateStr === undefined) {
    return dateStr;
  }
  return moment(dateStr).format("Do MMMM YYYY");
};

export const titleCase = string => {
  if (!string) {
    return string;
  }
  return string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const sleep = ms => new Promise(res => setTimeout(res, ms));

export const getPropertyOrUndefined = (object, prop) => {
  try {
    return object[prop];
  } catch (e) {
    return undefined;
  }
};

export const breakpoints = {
  xs: "(max-width:600px)",
  sm: "(min-width:600px)",
  md: "(min-width:960px)",
  lg: "(min-width:1280px)",
  xl: "(min-width:960px)"
};

export const subheadings = {
  Name: "Name",
  CountryCode: "Country",
  City: "City",
  Line1: "Address Line 1",
  Line2: "Address Line 2",
  State: "State",
  Phone: "Phone",
  ZipCode: "Postal Code",
  Website: "Website",
  RoyaltyRecipientCMOS: "Royalty Recipient CMOS"
};

export const countries = {
  af: "Afghanistan",
  al: "Albania",
  dz: "Algeria",
  as: "American Samoa",
  ad: "Andorra",
  ao: "Angola",
  ai: "Anguilla",
  aq: "Antarctica",
  ag: "Antigua and Barbuda",
  ar: "Argentina",
  am: "Armenia",
  aw: "Aruba",
  au: "Australia",
  at: "Austria",
  az: "Azerbaijan",
  bs: "Bahamas",
  bh: "Bahrain",
  bd: "Bangladesh",
  bb: "Barbados",
  by: "Belarus",
  be: "Belgium",
  bz: "Belize",
  bj: "Benin",
  bm: "Bermuda",
  bt: "Bhutan",
  bo: "Bolivia, Plurinational State of",
  bq: "Bonaire, Sint Eustatius and Saba",
  ba: "Bosnia and Herzegovina",
  bw: "Botswana",
  bv: "Bouvet Island",
  br: "Brazil",
  io: "British Indian Ocean Territory",
  bn: "Brunei Darussalam",
  bg: "Bulgaria",
  bf: "Burkina Faso",
  bi: "Burundi",
  kh: "Cambodia",
  cm: "Cameroon",
  ca: "Canada",
  cv: "Cape Verde",
  ky: "Cayman Islands",
  cf: "Central African Republic",
  td: "Chad",
  cl: "Chile",
  cn: "China",
  cx: "Christmas Island",
  cc: "Cocos (Keeling) Islands",
  co: "Colombia",
  km: "Comoros",
  cg: "Congo",
  cd: "Congo, the Democratic Republic of the",
  ck: "Cook Islands",
  cr: "Costa Rica",
  ci: "Côte d'Ivoire",
  hr: "Croatia",
  cu: "Cuba",
  cw: "Curacao",
  cy: "Cyprus",
  cz: "Czech Republic",
  dk: "Denmark",
  dj: "Djibouti",
  dm: "Dominica",
  do: "Dominican Republic",
  ec: "Ecuador",
  eg: "Egypt",
  sv: "El Salvador",
  gq: "Equatorial Guinea",
  er: "Eritrea",
  ee: "Estonia",
  et: "Ethiopia",
  fk: "Falkland Islands (Malvinas)",
  fo: "Faroe Islands",
  fj: "Fiji",
  fi: "Finland",
  fr: "France",
  gf: "French Guiana",
  pf: "French Polynesia",
  tf: "French Southern Territories",
  ga: "Gabon",
  gm: "Gambia",
  ge: "Georgia",
  de: "Germany",
  gh: "Ghana",
  gi: "Gibraltar",
  gr: "Greece",
  gl: "Greenland",
  gd: "Grenada",
  gp: "Guadeloupe",
  gu: "Guam",
  gt: "Guatemala",
  gg: "Guernsey",
  gn: "Guinea",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  ht: "Haiti",
  hm: "Heard Island and McDonald Islands",
  va: "Holy See (Vatican City State)",
  hn: "Honduras",
  hk: "Hong Kong",
  hu: "Hungary",
  is: "Iceland",
  in: "India",
  id: "Indonesia",
  ir: "Iran, Islamic Republic of",
  iq: "Iraq",
  ie: "Ireland",
  im: "Isle of Man",
  il: "Israel",
  it: "Italy",
  jm: "Jamaica",
  jp: "Japan",
  je: "Jersey",
  jo: "Jordan",
  kz: "Kazakhstan",
  ke: "Kenya",
  ki: "Kiribati",
  kp: "Korea, Democratic People's Republic of",
  kr: "Korea, Republic of",
  kw: "Kuwait",
  kg: "Kyrgyzstan",
  la: "Lao People's Democratic Republic",
  lv: "Latvia",
  lb: "Lebanon",
  ls: "Lesotho",
  lr: "Liberia",
  ly: "Libya",
  li: "Liechtenstein",
  lt: "Lithuania",
  lu: "Luxembourg",
  mo: "Macao",
  mk: "Macedonia, the Former Yugoslav Republic of",
  mg: "Madagascar",
  mw: "Malawi",
  my: "Malaysia",
  mv: "Maldives",
  ml: "Mali",
  mt: "Malta",
  mh: "Marshall Islands",
  mq: "Martinique",
  mr: "Mauritania",
  mu: "Mauritius",
  yt: "Mayotte",
  mx: "Mexico",
  fm: "Micronesia, Federated States of",
  md: "Moldova, Republic of",
  mc: "Monaco",
  mn: "Mongolia",
  me: "Montenegro",
  ms: "Montserrat",
  ma: "Morocco",
  mz: "Mozambique",
  mm: "Myanmar",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  nl: "Netherlands",
  nc: "New Caledonia",
  nz: "New Zealand",
  ni: "Nicaragua",
  ne: "Niger",
  ng: "Nigeria",
  nu: "Niue",
  nf: "Norfolk Island",
  mp: "Northern Mariana Islands",
  no: "Norway",
  om: "Oman",
  pk: "Pakistan",
  pw: "Palau",
  ps: "Palestine, State of",
  pa: "Panama",
  pg: "Papua New Guinea",
  py: "Paraguay",
  pe: "Peru",
  ph: "Philippines",
  pn: "Pitcairn",
  pl: "Poland",
  pt: "Portugal",
  pr: "Puerto Rico",
  qa: "Qatar",
  re: "Rénion",
  ro: "Romania",
  ru: "Russian Federation",
  rw: "Rwanda",
  bl: "Saint Barthálemy",
  sh: "Saint Helena, Ascension and Tristan da Cunha",
  kn: "Saint Kitts and Nevis",
  lc: "Saint Lucia",
  mf: "Saint Martin (French part)",
  pm: "Saint Pierre and Miquelon",
  vc: "Saint Vincent and the Grenadines",
  ws: "Samoa",
  sm: "San Marino",
  st: "Sao Tome and Principe",
  sa: "Saudi Arabia",
  sn: "Senegal",
  rs: "Serbia",
  sc: "Seychelles",
  sl: "Sierra Leone",
  sg: "Singapore",
  sx: "Sint Maarten (Dutch part)",
  sk: "Slovakia",
  si: "Slovenia",
  sb: "Solomon Islands",
  so: "Somalia",
  za: "South Africa",
  gs: "South Georgia and the South Sandwich Islands",
  ss: "South Sudan",
  es: "Spain",
  lk: "Sri Lanka",
  sd: "Sudan",
  sr: "Suriname",
  sj: "Svalbard and Jan Mayen",
  sz: "Swaziland",
  se: "Sweden",
  ch: "Switzerland",
  sy: "Syrian Arab Republic",
  tw: "Taiwan, Province of China",
  tj: "Tajikistan",
  tz: "Tanzania, United Republic of",
  th: "Thailand",
  tl: "Timor-Leste",
  tg: "Togo",
  tk: "Tokelau",
  to: "Tonga",
  tt: "Trinidad and Tobago",
  tn: "Tunisia",
  tr: "Turkey",
  tm: "Turkmenistan",
  tc: "Turks and Caicos Islands",
  tv: "Tuvalu",
  ug: "Uganda",
  ua: "Ukraine",
  ae: "United Arab Emirates",
  gb: "United Kingdom",
  us: "United States",
  um: "United States Minor Outlying Islands",
  uy: "Uruguay",
  uz: "Uzbekistan",
  vu: "Vanuatu",
  ve: "Venezuela, Bolivarian Republic of",
  vn: "Viet Nam",
  vg: "Virgin Islands, British",
  vi: "Virgin Islands, U.S.",
  wf: "Wallis and Futuna",
  eh: "Western Sahara",
  ye: "Yemen",
  zm: "Zambia",
  zw: "Zimbabwe"
};

export const getCountryName = countryCode => {
  const countryProp = countryCode.toLowerCase();
  if (countries.hasOwnProperty(countryProp)) {
    return countries[countryProp];
  } else {
    throw new Error(`No such country ${countryProp} (from ${countryCode})`);
  }
};

export const tryOrUndefined = (arg, func) => {
  if (arg === undefined) {
    return undefined;
  }
  try {
    return func(arg);
  } catch (e) {
    return undefined;
  }
};

export const addProtocolToURL = targetURL => {
  let myURL = targetURL;
  if (!targetURL.startsWith("https://") && !targetURL.startsWith("http://")) {
    myURL = "http://" + targetURL;
  }
  return myURL;
};

export const formatMoney = (value, currency) => {
  try {
    return dinero({ amount: value * 100, currency: currency }).toFormat(
      "$0,0.00"
    );
  } catch (e) {
    return value;
  }
};

export const trimStringToLength = (string, length) => {
  if (string.length > length) {
    return (
      <Tooltip title={string} placement="top-end">
        <span>{string.substr(0, length).concat("...")}</span>
      </Tooltip>
    );
  } else {
    return string;
  }
};

// This is just a temporary solution for verification until the backend part is implemented.
export const verifyTermsOfServiceAcceptance = email => {
  try {
    // eslint-disable-next-line no-useless-escape
    const regex = /^([\S\.]+)@([\S\.]+)$/;
    const domains = [
      "teosto.fi",
      "imro.ie",
      "prsformusic.com",
      "mindyourrights.fi"
    ];
    const emailDomain = regex.exec(email);
    const acceptedTos = localStorage.getItem("ConcertifyAcceptedTOS");
    const acceptedDate = localStorage.getItem("ConcertifyAcceptedTOSdate");
    if (domains.includes(emailDomain[2])) return true;
    if (!acceptedTos) return false;
    return !!(acceptedTos && acceptedDate < acceptedDate + 30);
  } catch (e) {
    return e;
  }
};
