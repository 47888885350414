import React from "react";
import { connect } from "react-redux";
import { TermsOfServiceDialog } from "../../dialogs/TermsOfServiceDialog/TermsOfServiceDialog";

export const TermsOfServiceView = () => {
  const onAccept = () => {
    localStorage.setItem("ConcertifyAcceptedTOS", true);
    localStorage.setItem("ConcertifyAcceptedTOSdate", new Date());
    window.location.href = "/performances";
  };
  return (
    <div>
      <TermsOfServiceDialog onAccept={onAccept} open={true} />
    </div>
  );
};

TermsOfServiceView.defaultProps = {
  open: false
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "TermsOfService"
  };
};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceView);
