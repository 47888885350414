import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#08081e",
      main: "#181843",
      light: "#363762"
    },
    secondary: {
      main: "#8100ff",
      light: "#9426ff",
      light300: "#a74dff",
      light200: "#c080ff",
      light100: "#d9b3ff"
    },
    grayscale: {
      white: "#ffffff",
      black: "#000000",
      black400: "#262626",
      black300: "#4d4d4d",
      black200: "#808080",
      black100: "#b3b3b3",
      black50: "#e0e0e0"
    }
  }
});

export default theme;
