// @flow strict

import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import MainBody from "components/structural/MainBody";
import PerformancesSubMenu from "components/PerformancesSubMenu/PerformancesSubMenu";
import { PerformancesContainer } from "components/performances";

const styles = {
  root: {
    display: "flex",
    flex: 1,
    background: "#D9DFE3"
  }
};

const UnconnectedPerformancesView = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Grid
        container
        style={{ background: "#EDEFF0" }}
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <MainBody subNavComponent={<PerformancesSubMenu />}>
          <PerformancesContainer />
        </MainBody>
      </Grid>
    </div>
  );
};

const PerformancesView = connect(null, null)(UnconnectedPerformancesView);

export default withRouter(withStyles(styles)(PerformancesView));
