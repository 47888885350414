// @flow strict

import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";

import {
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS,
  INVOICED_STATUS,
  NEW_STATUS,
  SCHEDULED_STATUS
} from "concertify/constants";
import { getCMOName } from "redux/actions/artistsAndCMOs";
import {
  changePerformancePageNumber,
  changePerformanceItemsPerPage,
  changePerformanceSortBy
} from "redux/actions/performanceFilterActions";
import { getPropertyOrUndefined, simpleDate, titleCase } from "utils";
import { styles } from "./styles";
import StatusBadge from "../../status/StatusBadge";
import Typography from "@material-ui/core/Typography";

const BadgeComponentFor = status => {
  switch (status) {
    case NEW_STATUS:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
    case SCHEDULED_STATUS:
      return <StatusBadge variant="deepPurple" label={SCHEDULED_STATUS} />;
    case INVOICED_STATUS:
      return <StatusBadge variant="lightBlue" label={INVOICED_STATUS} />;
    case COLLECTED_STATUS:
      return <StatusBadge variant="lightGreen" label={COLLECTED_STATUS} />;
    case DISTRIBUTED_STATUS:
      return <StatusBadge variant="green" label={DISTRIBUTED_STATUS} />;
    default:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
  }
};

const resultsPerPageOptions = [25, 50, 100];

const headCells = [
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: true
  },
  {
    id: "PerformingArtist.Name",
    numeric: false,
    disablePadding: true,
    label: "Artist",
    sortable: true
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: true,
    label: "Date",
    sortable: true
  },
  {
    id: "Venue.Address.City",
    numeric: false,
    disablePadding: true,
    label: "City",
    sortable: true
  },
  {
    id: "Venue.Address.CountryCode",
    numeric: false,
    disablePadding: true,
    label: "Country",
    sortable: true
  },
  {
    id: "Venue.Name",
    numeric: false,
    disablePadding: true,
    label: "Venue",
    sortable: true
  },
  {
    id: "CMOCode",
    disablePadding: true,
    numeric: true,
    label: "Licensing CMO",
    sortable: false
  },
  { id: "", disablePadding: true, label: "# of songs", sortable: false }
];

const pluralize = (val, word, plural = word + "s") => {
  const _pluralize = (num, word, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === "object")
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

type PerformancesTableProps = {
  performances: Array<Object>,
  onGetCMOName: Function,
  onPageNumberChange: Function,
  onItemsPerPageChange: Function,
  onSortByChange: Function,
  pageNumber: number,
  itemsPerPage: number,
  totalCount: number,
  loading: Boolean,
  sortBy: String
};

export const PerformancesTable = ({
  performances,
  onGetCMOName,
  onPageNumberChange,
  onItemsPerPageChange,
  onSortByChange,
  pageNumber,
  itemsPerPage,
  totalCount,
  loading,
  sortBy
}: PerformancesTableProps) => {
  const classes = styles();

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="performances-table">
          <TableHead>
            <TableRow className={classes.headerRow}>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  className={classes.headerCell}
                  align="center"
                  sortDirection={sortBy === headCell.id && "asc"}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={sortBy === headCell.id}
                      direction="asc"
                      onClick={() => onSortByChange(headCell.id)}
                      IconComponent={KeyboardArrowDownIcon}
                      data-role={`sort-performances-by-${headCell.label}`}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <span>{headCell.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-role="performances-table-body">
            {!loading && totalCount === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  <Typography
                    data-role="no-relevant-performances"
                    variant="body1"
                  >
                    There are currently no performances we consider to be
                    relevant to you.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              performances.map((performance, i) => (
                <TableRow
                  hover
                  component={Link}
                  to={`/performances/${performance.PerformanceId}`}
                  key={i}
                  className={classes.contentRow}
                  data-role="link-to-performance"
                >
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-status"
                  >
                    {BadgeComponentFor(performance.Status)}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performing-artist-name"
                  >
                    {titleCase(
                      getPropertyOrUndefined(
                        performance.PerformingArtist,
                        "Name"
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-date"
                  >
                    {simpleDate(performance.Date)}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-city"
                  >
                    {titleCase(
                      getPropertyOrUndefined(performance.Venue.Address, "City")
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-country-code"
                  >
                    {getPropertyOrUndefined(
                      performance.Venue.Address,
                      "CountryCode"
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-venue"
                  >
                    {performance.Venue.Name}
                  </TableCell>
                  <TableCell className={classes.rowCell} align="center">
                    {onGetCMOName(performance.CMOCode)}
                  </TableCell>
                  <TableCell className={classes.rowCell} align="center">
                    {(performance.Setlist &&
                      performance.Setlist.Works &&
                      performance.Setlist.Works.length) ||
                      0}{" "}
                    {pluralize(
                      (performance.Setlist &&
                        performance.Setlist.Works &&
                        performance.Setlist.Works.length) ||
                        0,
                      "song"
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <Grid
          className={classes.paginationContainer}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid className={classes.paginationWrapper} item container>
            <Pagination
              className={classes.pagination}
              data-role="pagination"
              color="secondary"
              count={Math.ceil(totalCount / itemsPerPage)}
              page={pageNumber}
              onChange={onPageNumberChange}
            />
          </Grid>
          <Grid
            className={classes.itemsPerPageWrapper}
            item
            container
            spacing={1}
            direction="row"
            alignItems="center"
          >
            <Grid className={classes.itemsPerPageText} item>
              Results per page:
            </Grid>
            <Grid item>
              <Select
                className={classes.itemsPerPageSelect}
                data-role="results-per-page-selector"
                value={itemsPerPage}
                onChange={onItemsPerPageChange}
                variant="outlined"
                disabled={totalCount === 0}
              >
                {resultsPerPageOptions.map(option => (
                  <MenuItem
                    data-role={`results-per-page-${option}`}
                    value={option}
                  >
                    {option.toString()}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </TableContainer>
    </div>
  );
};

PerformancesTable.defaultProps = {
  onGetCMOName: () => {},
  onPageNumberChange: () => {},
  onItemsPerPageChange: () => {},
  onSortByChange: () => {},
  itemsPerPage: 25,
  totalCount: 0
};

const mapStateToProps = state => ({
  performances: state.filteredPerformancesReducer.performances,
  loading: state.filteredPerformancesReducer.loading,
  pageNumber: state.performanceFilterReducer.pageNumber,
  itemsPerPage: state.performanceFilterReducer.itemsPerPage,
  sortBy: state.performanceFilterReducer.sortBy,
  totalCount: state.filteredPerformancesReducer.totalCount
});

const mapDispatchToProps = dispatch => ({
  onGetCMOName: code => dispatch(getCMOName(code)),
  onPageNumberChange: (e, pageNumber) =>
    dispatch(changePerformancePageNumber(pageNumber)),
  onItemsPerPageChange: e => {
    dispatch(changePerformancePageNumber(1));
    dispatch(changePerformanceItemsPerPage(e.target.value));
  },
  onSortByChange: sortBy => dispatch(changePerformanceSortBy(sortBy))
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformancesTable);
