import { OPEN_SNACKBAR } from "redux/actions/types";
import { CLOSE_SNACKBAR } from "../actions/types";

const initialState = {
  isOpen: false,
  variant: null,
  message: null
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return { isOpen: true, variant: action.variant, message: action.message };
    case CLOSE_SNACKBAR:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default snackbarReducer;
