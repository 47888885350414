import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import MenuItem from "@material-ui/core/MenuItem";

import { connect } from "react-redux";
import addWorkToSetlist from "redux/actions/addWorkToSetlist";
import closeDialog from "redux/actions/closeDialog";
import { ADD_USERGENERATED_WORK } from "redux/actions/types";
import { required } from "components/forms/formValidation";
import { validateISWC } from "components/forms/formValidation";
import { validateIPI } from "components/forms/formValidation";
import { requiredAndIsMinutesSecondsFormat } from "components/forms/formValidation";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";

import Work from "components/works/Work";
import XClosableDialog from "../XClosableDialog";

const useStyles = makeStyles(theme => ({
  work: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export const mmss2seconds = value =>
  moment(value, "mm:ss").diff(moment().startOf("day"), "seconds");

export const formObjToWork = obj => {
  if (obj !== undefined) {
    let newObject = {};
    let rightsOwners = [];

    for (let key of Object.keys(obj)) {
      if (key.startsWith("author")) {
        let [index, fieldName] = key.split("-").slice(1);
        index = Number(index);

        if (rightsOwners[index] == null) {
          let rightsOwnerEntry = {};
          rightsOwnerEntry[fieldName] = obj[key];
          rightsOwners.splice(index, 0, rightsOwnerEntry);
        } else {
          rightsOwners[index][fieldName] = obj[key];
        }
      } else {
        newObject[key] = obj[key];
      }
    }
    newObject["RightsOwners"] = rightsOwners;

    return newObject;
  }
  return null;
};

export const updateWorkObj = work => {
  if (work !== undefined && Object.entries(work).length !== 0) {
    const performers = [];
    performers.push(work["originalPerformer"]);
    const updatedWork = {
      Title: work["title"],
      OriginalDuration: mmss2seconds(work["duration"]),
      RightsOwners: work["RightsOwners"],
    };
    if (work["ISWC"]) updatedWork["ISWC"] = work["ISWC"];
    if (work["originalPerformer"]) updatedWork["Performers"] = performers;
    return updatedWork;
  }
  return work;
};

export const AddWorkDialog = ({ onSubmit, initialValues, open }) => {
  const classes = useStyles();
  const [
    numberOfAdditionalAuthorRows,
    setNumberOfAdditionalAuthorRows,
  ] = useState(0);

  return (
    <Form onSubmit={onSubmit || (e => null)} initialValues={initialValues}>
      {({ handleSubmit, values, pristine, invalid }) => (
        <XClosableDialog open={open} title={"Add musical work"}>
          <DialogContent>
            <form
              onSubmit={event => {
                try {
                  handleSubmit(event);
                  setNumberOfAdditionalAuthorRows(0);
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <Typography variant="subtitle2">Work information</Typography>
              <Grid
                container
                direction="column"
                spacing={1}
                className={classes.gutterBottom}
              >
                <Grid container item spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Field
                      validate={required}
                      fullWidth
                      type="text"
                      name="title"
                      component={TextField}
                      label="Title*"
                      helperText="This field is required."
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      fullWidth
                      type="text"
                      name="originalPerformer"
                      component={TextField}
                      label="Performer"
                      helperText="e.g. Elvis Presley"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      data-role="duration-field"
                      validate={requiredAndIsMinutesSecondsFormat}
                      fullWidth
                      type="text"
                      name="duration"
                      placeholder="3:35"
                      component={TextField}
                      label="Duration* (mm:ss)"
                      helperText="e.g. 3:15"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      validate={validateISWC}
                      fullWidth
                      type="text"
                      name="ISWC"
                      component={TextField}
                      label="ISWC"
                      helperText="e.g. T-001.712.778-4"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Typography variant="subtitle2">Authors</Typography>
              <Grid container direction="column" spacing={1}>
                <Grid container item spacing={1} alignItems="center">
                  <Grid item xs={3}>
                    <Field
                      validate={required}
                      fullWidth
                      type="text"
                      name="author-0-LastName"
                      component={TextField}
                      label="Last Name*"
                      placeholder="Doe"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      fullWidth
                      type="text"
                      name="author-0-FirstName"
                      component={TextField}
                      label="First name"
                      placeholder="John"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      validate={validateIPI}
                      fullWidth
                      type="text"
                      name="author-0-IPI"
                      component={TextField}
                      label="IPI Number"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="author-0-CAERole"
                      component={Select}
                      label="Select a Role"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="C">Composer</MenuItem>
                      <MenuItem value="A">Lyrics Writer</MenuItem>
                      <MenuItem value="CA">
                        {"Composer & Lyrics Writer"}
                      </MenuItem>
                      <MenuItem value="AR">Arranger</MenuItem>
                      <MenuItem value="E">Publisher</MenuItem>
                      <MenuItem value="SE">Sub Publisher</MenuItem>
                      <MenuItem value="ES">Substituted Publisher</MenuItem>
                      <MenuItem value="AD">Adaptor</MenuItem>
                      <MenuItem value="TR">Translator</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item>
                    {numberOfAdditionalAuthorRows === 0 && (
                      <Button
                        className={classes.work}
                        onClick={e =>
                          setNumberOfAdditionalAuthorRows(
                            numberOfAdditionalAuthorRows + 1
                          )
                        }
                      >
                        Add another author
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {[...Array(numberOfAdditionalAuthorRows).keys()].map(i => (
                  <Grid key={i} container item spacing={1} alignItems="center">
                    <Grid item xs={3}>
                      <Field
                        validate={required}
                        fullWidth
                        type="text"
                        name={`author-${i + 1}-LastName`}
                        component={TextField}
                        label="Last Name*"
                        placeholder="Doe"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        fullWidth
                        type="text"
                        name={`author-${i + 1}-FirstName`}
                        component={TextField}
                        label="First name"
                        placeholder="John"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        validate={validateIPI}
                        fullWidth
                        type="text"
                        name={`author-${i + 1}-IPI`}
                        component={TextField}
                        label="IPI Number"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Field
                        name={`author-${i + 1}-Role`}
                        component={Select}
                        label="Select a Role"
                        formControlProps={{ fullWidth: true }}
                      >
                        <MenuItem value="C">Composer</MenuItem>
                        <MenuItem value="A">Lyrics Writer</MenuItem>
                        <MenuItem value="CA">
                          {"Composer & Lyrics Writer"}
                        </MenuItem>
                        <MenuItem value="AR">Arranger</MenuItem>
                        <MenuItem value="E">Publisher</MenuItem>
                        <MenuItem value="SE">Sub Publisher</MenuItem>
                        <MenuItem value="ES">Substituted Publisher</MenuItem>
                        <MenuItem value="AD">Adaptor</MenuItem>
                        <MenuItem value="TR">Translator</MenuItem>
                      </Field>
                    </Grid>
                    <Grid item>
                      {numberOfAdditionalAuthorRows === i + 1 && (
                        <Button
                          className={classes.work}
                          onClick={e =>
                            setNumberOfAdditionalAuthorRows(
                              numberOfAdditionalAuthorRows + 1
                            )
                          }
                        >
                          Add another author
                        </Button>
                      )}
                      {i === numberOfAdditionalAuthorRows - 1 && (
                        <Button
                          onClick={e => {
                            setNumberOfAdditionalAuthorRows(
                              numberOfAdditionalAuthorRows - 1
                            );
                            for (let key of Object.keys(values)) {
                              if (key.includes(i - 1)) {
                                delete values[key];
                              }
                            }
                          }}
                        >
                          Remove one author
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="subtitle2">Preview</Typography>
              <Work
                className={classes.work}
                title={values.title}
                duration={mmss2seconds(values.duration)}
                iswc={values.ISWC}
                readOnly
                rightsOwners={formObjToWork(values).RightsOwners.map(o =>
                  `${o.FirstName || ""} ${o.LastName || ""}`.trim()
                )}
              />
              <Button
                disabled={pristine || invalid}
                data-role="submit-form-button"
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.gutterBottom}
              >
                Add to setlist
              </Button>
            </form>
          </DialogContent>
        </XClosableDialog>
      )}
    </Form>
  );
};

AddWorkDialog.defaultProps = {
  open: false,
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === ADD_USERGENERATED_WORK,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: async (values, form, callback) => {
    const updatedWork = updateWorkObj(formObjToWork(values));
    await dispatch(addWorkToSetlist(updatedWork));
    await dispatch(closeDialog());
    setTimeout(form.reset);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkDialog);
