import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ReactPlaceholder from "react-placeholder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { CMOR } from "concertify/constants";
import MainBody from "components/structural/MainBody";
import Input from "components/forms/Input";
import GoogleMapsSuggest from "components/forms/GoogleMapsSuggest";
import { currentAuthenticatedUser } from "redux/actions/loginActions";
import { createPerformance } from "redux/actions/performanceActions";
import { countries, IsEmpty } from "utils";

const styles = theme => ({
  subWrapper: {
    display: "flex",
    flex: 1,
    padding: "10px 20px",
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    borderRadius: 2
  },
  title: {
    marginTop: "10px",
    lineHeight: "1.4em"
  },
  formPaper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 2
  },
  selectComponent: {
    marginBottom: 3
  },
  datePickerComponent: {
    marginTop: theme.spacing(1)
  },
  fieldWrapper: {
    height: 64
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2)
  }
});

export const NewPerformance = ({
  classes,
  user,
  artist,
  date,
  venue,
  city,
  country,
  addressLine1,
  addressLine2,
  zipCode,
  organizer,
  handleChange,
  handleChangeVenue,
  handleChangeDate,
  handleAutocompleteChange
}) => {
  return (
    <Grid container direction="column">
      <h1 className={classes.title}>New performance</h1>
      <form>
        <Grid
          className={classes.formPaper}
          container
          direction="row"
          justify="space-around"
        >
          <Grid container item xs={5} direction="column">
            <Grid item className={classes.fieldWrapper}>
              <ReactPlaceholder
                showLoadingAnimation
                ready={!IsEmpty(user)}
                rows={1}
              >
                {user && user.type === CMOR && (
                  <Autocomplete
                    data-role="artist-autocomplete"
                    options={user.performingArtists}
                    value={artist || null}
                    getOptionLabel={option => option.Name}
                    onChange={handleAutocompleteChange("artist")}
                    renderOption={option => <span>{option.Name}</span>}
                    renderInput={params => (
                      <Input
                        textFieldProps={{
                          label: "Performing Artist",
                          required: true,
                          ...params
                        }}
                      />
                    )}
                  />
                )}
              </ReactPlaceholder>
            </Grid>
            <Grid
              item
              container
              justify="center"
              direction="column"
              className={classes.fieldWrapper}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePickerComponent}
                  id="performanceDate"
                  name="date"
                  required={true}
                  variant="inline"
                  format="dd/MM/yyyy"
                  emptyLabel="dd/mm/yyyy *"
                  value={!date ? null : date}
                  autoOk={true}
                  inputProps={{
                    "data-role": "date-from-input"
                  }}
                  InputAdornmentProps={{
                    position: "end"
                  }}
                  KeyboardButtonProps={{
                    "data-role": "date-from"
                  }}
                  htmlFor="performanceDate"
                  onChange={handleChangeDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item className={classes.fieldWrapper}>
              <GoogleMapsSuggest
                apiKey={process.env.REACT_APP_GCP_MAPS_API_KEY}
                inputProps={{
                  id: "performanceVenue",
                  name: "venue",
                  value: venue || "",
                  required: true,
                  htmlFor: "performanceVenue",
                  placeholder: "Find venue",
                  label: "Venue",
                  autoComplete: "off"
                }}
                handleTyping={handleChange}
                handleSelect={handleChangeVenue}
              />
            </Grid>
            <Grid item className={classes.fieldWrapper}>
              <Input
                textFieldProps={{
                  type: "text",
                  id: "performanceAddressLine1",
                  name: "addressLine1",
                  label: "Address Line 1",
                  htmlFor: "performanceAddressLine1",
                  placeholder: "Enter address line 1",
                  autoComplete: "off",
                  onChange: handleChange,
                  value: addressLine1 || "",
                  required: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={5} direction="column">
            <Grid item className={classes.fieldWrapper}>
              <Input
                textFieldProps={{
                  type: "text",
                  id: "performanceAddressLine2",
                  name: "addressLine2",
                  label: "Address Line 2",
                  htmlFor: "performanceAddressLine2",
                  placeholder: "Enter address line 2",
                  autoComplete: "off",
                  onChange: handleChange,
                  value: addressLine2 || ""
                }}
              />
            </Grid>
            <Grid item className={classes.fieldWrapper}>
              <Input
                textFieldProps={{
                  type: "text",
                  id: "performanceZipCode",
                  name: "zipCode",
                  label: "Zip Code",
                  htmlFor: "performanceZipCode",
                  placeholder: "Enter zip code",
                  autoComplete: "off",
                  onChange: handleChange,
                  value: zipCode || "",
                  required: true
                }}
              />
            </Grid>
            <Grid item className={classes.fieldWrapper}>
              <Input
                textFieldProps={{
                  type: "text",
                  id: "performanceCity",
                  name: "city",
                  label: "City",
                  htmlFor: "performanceCity",
                  placeholder: "Enter city",
                  onChange: handleChange,
                  value: city || "",
                  required: true
                }}
              />
            </Grid>
            <Grid item className={classes.fieldWrapper}>
              <Autocomplete
                data-role="country-autocomplete"
                options={Object.entries(countries)}
                value={country || null}
                getOptionLabel={option => option[1]}
                onChange={handleAutocompleteChange("country")}
                renderOption={option => <span>{option[1]}</span>}
                renderInput={params => (
                  <Input
                    textFieldProps={{
                      label: "Country",
                      required: true,
                      ...params
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

class UnconnectedCreatePerformanceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artist: "",
      date: this.props.date || null,
      venue: this.props.venue || "",
      organizer: this.props.organizer || "",
      city: "",
      country: null,
      addressLine1: "",
      addressLine2: "",
      zipCode: ""
    };
  }

  doSave = async () => {
    const performance = {
      artist: this.state.artist.Name,
      date: this.state.date,
      city: this.state.city,
      country: this.state.country[0].toUpperCase(),
      venue: this.state.venue,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      zipCode: this.state.zipCode,
      cmoCode: this.props.user.cmoCode
    };

    try {
      const newPerformance = await this.props.createPerformance(performance);
      this.props.history.push(`/performances/${newPerformance.PerformanceId}`);
    } catch (e) {
      throw new Error(e);
    }
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((oldState, oldProps) => {
      let newState = {};
      newState[name] = value;
      return newState;
    });
  };

  handleChangeDate = date => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    this.setState({ date: formattedDate });
  };

  handleAutocompleteChange = name => (event, value) => {
    this.setState({ [name]: value });
  };

  handleChangeVenue = (parsed, address, place) => {
    if (
      place &&
      place.structured_formatting &&
      place.structured_formatting.main_text
    ) {
      this.setState({
        venue: place.structured_formatting.main_text,
        city: parsed.city,
        country: [
          parsed.countryShort.toLowerCase(),
          countries[parsed.countryShort.toLowerCase()]
        ],
        addressLine1: parsed.address,
        addressLine2: parsed.stateShort.toLowerCase(),
        zipCode: parsed.zipCode
      });
    }
  };

  isDisabled = () =>
    !this.state.artist ||
    !this.state.date ||
    !this.state.venue ||
    !this.state.addressLine1 ||
    !this.state.zipCode ||
    !this.state.city ||
    !this.state.country;

  render() {
    const { classes } = this.props;
    return (
      <MainBody hideSubNav={true}>
        <Grid
          container
          justify="flex-start"
          direction="column"
          alignItems="center"
          className={classes.subWrapper}
        >
          <NewPerformance
            classes={classes}
            user={this.props.user}
            artist={this.state.artist}
            date={this.state.date}
            venue={this.state.venue}
            city={this.state.city}
            country={this.state.country}
            addressLine1={this.state.addressLine1}
            addressLine2={this.state.addressLine2}
            zipCode={this.state.zipCode}
            organizer={this.state.organizer}
            handleChange={this.handleChange}
            handleChangeVenue={this.handleChangeVenue}
            handleChangeDate={this.handleChangeDate}
            handleAutocompleteChange={this.handleAutocompleteChange}
          />
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            xs={11}
            className={classes.buttonsWrapper}
          >
            <Grid item>
              <Button
                id="CancelAddNewPerformanceButton"
                variant="contained"
                onClick={this.props.history.goBack}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="SubmitAddNewPerformanceFormButton"
                disabled={this.isDisabled()}
                variant="contained"
                color="secondary"
                onClick={this.doSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MainBody>
    );
  }
}

UnconnectedCreatePerformanceView.propTypes = {
  currentAuthenticatedUser: PropTypes.func.isRequired,
  createPerformance: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeStep: PropTypes.number,
  date: PropTypes.object,
  venue: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.loginReducer.user
});

const CreatePerformanceView = connect(mapStateToProps, {
  currentAuthenticatedUser,
  createPerformance
})(UnconnectedCreatePerformanceView);

export default withRouter(withStyles(styles)(CreatePerformanceView));
