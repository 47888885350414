import { OPEN_DIALOG, CLOSE_DIALOG } from "redux/actions/types";

const initialState = {
  isOpen: false,
  variant: null
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        isOpen: true,
        variant: action.variant
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false,
        variant: null
      };
    default:
      return state;
  }
};

export default dialogReducer;
