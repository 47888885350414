import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import Header from "components/Header";
import { initiateServerData } from "redux/actions/initiateDataFromServer";
import MainWrapper from "./MainWrapper";

const styles = makeStyles(() => {
  return {
    root: {
      display: "flex",
      height: "auto",
      flex: 1,
      flexDirection: "column",
      background: "#D9DFE3",
      lineHeight: "1.2em"
    },
    subNav: {
      minHeight: 30,
      background: "#373762"
    }
  };
});

export const MainBody = ({
  subNavComponent,
  children,
  hideNav,
  hideSubNav,
  loaded,
  onLoad
}) => {
  useEffect(() => {
    if (!loaded) onLoad();
    // eslint-disable-next-line
  }, []);

  const classes = styles();
  return (
    <div className={classes.root}>
      {!hideNav && <Header />}
      {!hideSubNav && (
        <AppBar position="static" className={classes.subNav}>
          {subNavComponent}
        </AppBar>
      )}

      <MainWrapper>{children}</MainWrapper>
    </div>
  );
};

MainBody.defaultProps = {
  hideNav: false,
  hideSubNav: false
};

MainBody.propTypes = {
  children: PropTypes.object,
  subNavComponent: PropTypes.object,
  hideNav: PropTypes.bool,
  hideSubNav: PropTypes.bool,
  onLoad: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(initiateServerData())
});

const mapStateToProps = state => ({
  loaded: state.initiateDataFromServerReducer.loaded
});

export default connect(mapStateToProps, mapDispatchToProps)(MainBody);
