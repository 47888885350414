import { connect } from "react-redux";

import searchWorks from "redux/actions/searchWorks";
import WorksSearchInput from "./WorksSearchInput";

const mapStateToProps = state => ({
  searching: state.worksSearchReducer.isFetching
});

const mapDispatchToProps = dispatch => ({
  onSearch: query => dispatch(searchWorks(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksSearchInput);
