// @flow strict

import { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import createActivityDetector from "activity-detector";
import { useDispatch } from "react-redux";
import openDialog from "redux/actions/openDialog";

export function useIsIdle(options: Object) {
  const dispatch = useDispatch();

  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on("idle", () => {
      Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(user => {
          const expirationDate = String(
            new Date(new Date().getTime() + 28800000)
          );
          localStorage.setItem("sessionExpires", expirationDate);
        })
        .catch(err => localStorage.removeItem("sessionExpires"));
    });
    activityDetector.on("active", () => {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      })
        .then(user => {
          const session = localStorage.getItem("sessionExpires");
          if (session && new Date(session).getTime() < new Date().getTime()) {
            dispatch(openDialog("SessionExpiration"));
          }
          const expirationDate = String(
            new Date(new Date().getTime() + 28800000)
          );
          localStorage.setItem("sessionExpires", expirationDate);
        })
        .catch(err => localStorage.removeItem("sessionExpires"));
    });
    return () => activityDetector.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
