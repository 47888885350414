import React from "react";

import { connect } from "react-redux";
import { DELETE_PERFORMANCE } from "redux/actions/types";
import ReduxDeletePerformanceForm from "components/forms/DeletePerformanceForm";
import XClosableDialog from "../XClosableDialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export const DeletePerformanceConfirmationDialog = ({ open, deleter }) => {
  return (
    <XClosableDialog open={open} title={"Delete performance?"}>
      <DialogContent>
        <DialogContentText>
          This performance will no longer be visible on Concertify. If you are
          sure you want to proceed, please choose one of the following reasons
          for deleting the performance:
        </DialogContentText>
        <ReduxDeletePerformanceForm deleter={deleter} />
      </DialogContent>
    </XClosableDialog>
  );
};
DeletePerformanceConfirmationDialog.defaultProps = {
  open: false
};

// Redux version

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === DELETE_PERFORMANCE
  };
};

export default connect(
  mapStateToProps,
  null
)(DeletePerformanceConfirmationDialog);
