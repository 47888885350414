import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    }
  };
});

export { styles };
