import { Logger } from "aws-amplify";
import concertify from "concertify/core";
import {
  CREATE_PERFORMANCE,
  OPEN_SNACKBAR,
  UPDATE_PERFORMANCE_REQUEST,
  UPDATE_PERFORMANCE_SUCCESS,
  UPDATE_PERFORMANCE_ERROR,
  UPDATE_PERFORMANCE,
  CLOSE_DIALOG
} from "./types";

const logger = new Logger("performanceActions");

export const createPerformance = performance => async dispatch => {
  const result = await concertify.performances.addNew(
    performance.artist,
    performance.date,
    performance.city,
    performance.country,
    performance.venue,
    performance.addressLine1,
    performance.addressLine2,
    performance.zipCode,
    performance.cmoCode
  );
  const newPerformance = result.data.Summary[0];
  dispatch({
    type: CREATE_PERFORMANCE,
    payload: newPerformance
  });
  return newPerformance;
};

export const updatePerformance = (performanceId, payload) => async dispatch => {
  try {
    dispatch({ type: UPDATE_PERFORMANCE_REQUEST });

    await concertify.performances.update(performanceId, payload);

    dispatch({ type: UPDATE_PERFORMANCE, payload: payload });
    dispatch({ type: UPDATE_PERFORMANCE_SUCCESS });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "success",
      message: "Performance successfully updated"
    });
    dispatch({ type: CLOSE_DIALOG });
    dispatch({
      type: UPDATE_PERFORMANCE,
      payload: payload
    });
  } catch (err) {
    logger.error(err);
    dispatch({ type: UPDATE_PERFORMANCE_ERROR, payload: err.message });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message:
        "Something went wrong while trying to update this performance. Please try again later."
    });
  }
};
