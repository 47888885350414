const getCMOCountryCode = (CMOs, cmoCode) => {
  const CMO = CMOs.find(cmo => cmo.CMOCode === cmoCode);
  if (CMO) {
    return CMO.CountryCode.toLowerCase();
  } else {
    return "unknown";
  }
};

const getCMOName = (CMOs, cmoCode) => {
  const CMO = CMOs.find(cmo => cmo.CMOCode === cmoCode);
  if (CMO) {
    return CMO.CMOName;
  } else {
    return "unknown";
  }
};

const getCMOPerformingArtists = (CMOs, cmoCode) => {
  const CMO = CMOs.find(cmo => cmo.CMOCode === cmoCode);
  if (CMO) {
    return CMO.PerformingArtists;
  } else {
    return [];
  }
};

const getCMOByCountryCode = (CMOs, countryCode) => {
  const CMO = CMOs.find(cmo => cmo.CountryCode === countryCode);
  if (CMO) {
    return CMO;
  } else {
    return null;
  }
};

const isLicensingCMOR = (CMOs, cmorUser, performance) => {
  return (
    getCMOCountryCode(CMOs, cmorUser.cmoCode) ===
    performance.Venue.Address.CountryCode.toLowerCase()
  );
};

const CMOsService = {
  getCMOCountryCode: getCMOCountryCode,
  getCMOName: getCMOName,
  getCMOPerformingArtists: getCMOPerformingArtists,
  getCMOByCountryCode: getCMOByCountryCode,
  isLicensingCMOR: isLicensingCMOR
};

export default CMOsService;
