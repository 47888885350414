// @flow strict

import React from "react";
import { SignIn } from "aws-amplify-react";
import { Hub } from "aws-amplify";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Logo from "components/Logo";
import RootStyle from "../style";

const styles = () => ({
  ...RootStyle,
  forgotPasswordContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    margin: 0,
    width: 380
  }
});

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      infoMessage: {
        primary: "",
        secondary: ""
      }
    };
  }

  listener = data => {
    switch (data.payload.event) {
      case "forgotPasswordSubmit":
        this.setState({
          infoMessage: {
            primary: "You have successfully reset your password",
            secondary: "You can now sign in"
          }
        });
        break;
      case "confirmSignUp":
        this.setState({
          infoMessage: {
            primary: "You have successfully registered an account",
            secondary: "You can now sign in"
          }
        });
        break;
      case "signIn":
        Hub.remove("auth");
        break;
      default:
        this.setState({ infoMessage: "" });
        break;
    }
  };

  showComponent(theme): null | * {
    const { classes } = this.props;
    const { loading, infoMessage } = this.state;

    Hub.listen("auth", this.listener);

    return (
      <div className={classes.formContainer}>
        <div className={classes.formSection}>
          <div className={classes.headerSection}>
            <div className={classes.logoContainer}>
              <Logo size="large" />
            </div>
            <div className={classes.infoMessageContainer}>
              <Typography variant="h5" className={classes.infoMessage}>
                {infoMessage.primary}
              </Typography>
              <Typography variant="h5" className={classes.infoMessage}>
                {infoMessage.secondary}
              </Typography>
            </div>
          </div>
          <form onSubmit={e => super.signIn(e)}>
            <div className={classes.bodySection}>
              <TextField
                className={classes.textField}
                name="email"
                variant="outlined"
                placeholder="Email"
                onChange={this.handleInputChange}
              />
              <TextField
                className={classes.textField}
                name="password"
                variant="outlined"
                placeholder="Password"
                type="password"
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.forgotPasswordContent}>
              <a
                href="/#"
                className={classes.link}
                onClick={() => {
                  super.changeState("forgotPassword");
                }}
              >
                Forgot password?
              </a>
            </div>
            <div className={classes.footerSection}>
              <div className={classes.footerSectionPrimaryContent}>
                {loading ? (
                  <div className={classes.circularProgressOnButton}>
                    <CircularProgress size={25} color="secondary" />
                  </div>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Login
                  </Button>
                )}
              </div>
              <div className={classes.footerSectionSecondaryContent}>
                <span>No account?</span>
                <a
                  href="/#"
                  className={classes.link}
                  onClick={() => {
                    super.changeState("signUp");
                  }}
                >
                  Create account
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomSignIn);
