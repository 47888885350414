// @flow strict

import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";

import {
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS
} from "concertify/constants";

const tooltipStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: 5,
    padding: "10px 20px"
  },
  icon: {
    color: theme.palette.primary.light
  }
}));

type TooltipStatusInfoProps = {
  /** Status for which tooltip needs to be displayed. */
  status: string,
  /** Component which will be hovered to display tooltip. */
  children: Node
};

/**
 * Custom Tooltip component which shows more information about performance status.
 *
 */

const TooltipStatusInfo = ({
  status,
  children,
  ...rest
}: TooltipStatusInfoProps) => {
  const classes = tooltipStyles();
  const helpTextFor = {
    [NEW_STATUS]: "The live performance is new and fully unprocessed",
    [SCHEDULED_STATUS]:
      "The licensing society has acknowledged that they will be taking care of processing the live performance",
    [INVOICED_STATUS]:
      "The licensing society has confirmed that they have sent an invoice to the live event organizer",
    [COLLECTED_STATUS]:
      "The licensing society has confirmed that they have received payment from the live event organizer",
    [DISTRIBUTED_STATUS]:
      "The licensing society has confirmed that they have executed royalty calculations and are ready to or have transferred the payments to all receiving societies"
  };
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip
      }}
      data-role="custom-tooltip"
      title={
        <Grid container spacing={1}>
          <Grid item container>
            <Grid item container xs={2} alignItems="center">
              <InfoIcon classes={{ root: classes.icon }} />
            </Grid>
            <Grid
              item
              container
              xs={8}
              justify="flex-start"
              alignItems="center"
            >
              <span data-role="title-text">{status.toUpperCase()}</span>
            </Grid>
          </Grid>
          <Grid item>
            <span data-role="info-text">
              {helpTextFor[status]
                ? helpTextFor[status]
                : "No info for this status"}
            </span>
          </Grid>
        </Grid>
      }
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

TooltipStatusInfo.defaultProps = {
  status: ""
};

export default TooltipStatusInfo;
