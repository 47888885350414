import { Logger } from "@aws-amplify/core";

import concertify from "concertify/core";
import CMOsService from "concertify/ArtistsAndCMOs";
import {
  LOAD_CMOS_REQUEST,
  LOAD_CMOS_SUCCESS,
  LOAD_CMOS_ERROR,
  CLEAR_CMOS,
  OPEN_SNACKBAR
} from "redux/actions/types";

const logger = new Logger("artistsAndCMOs");

export const loadArtistsAndCMOs = () => async dispatch => {
  dispatch({ type: LOAD_CMOS_REQUEST });
  try {
    const CMOs = await concertify.cmos.getAll();
    dispatch({ type: LOAD_CMOS_SUCCESS, payload: CMOs });
  } catch (e) {
    logger.error(e);
    dispatch({
      type: LOAD_CMOS_ERROR
    });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message: "Error fetching CMOs"
    });
  }
};

export const clearArtistsAndCMOs = () => async dispatch => {
  dispatch({
    type: CLEAR_CMOS
  });
};

export const getCMOName = cmoCode => (dispatch, getState) => {
  try {
    const state = getState();
    return CMOsService.getCMOName(state.artistsAndCMOsReducer.CMOs, cmoCode);
  } catch (e) {
    logger.error(e);
  }
};

export const getCMOCountryCode = cmoCode => (dispatch, getState) => {
  try {
    const state = getState();
    return CMOsService.getCMOCountryCode(
      state.artistsAndCMOsReducer.CMOs,
      cmoCode
    );
  } catch (e) {
    logger.error(e);
  }
};

export const getCMOPerformingArtists = cmoCode => (dispatch, getState) => {
  try {
    const state = getState();
    return CMOsService.getCMOPerformingArtists(
      state.artistsAndCMOsReducer.CMOs,
      cmoCode
    );
  } catch (e) {
    logger.error(e);
  }
};

export const getCMOByCountryCode = countryCode => (dispatch, getState) => {
  try {
    const state = getState();
    return CMOsService.getCMOByCountryCode(
      state.artistsAndCMOsReducer.CMOs,
      countryCode
    );
  } catch (e) {
    logger.error(e);
  }
};

export const isLicensingCMOR = () => (dispatch, getState) => {
  try {
    const state = getState();
    const user = state.loginReducer.user;
    const performance =
      state.performanceDetailsReducer.currentPerformanceDetails;
    return CMOsService.isLicensingCMOR(
      state.artistsAndCMOsReducer.CMOs,
      user,
      performance
    );
  } catch (e) {
    logger.error(e);
  }
};
