import Auth from "@aws-amplify/auth";
import { Logger } from "@aws-amplify/core";

import {
  CMOR,
  PAR,
  COGNITO_CMORS_GROUP,
  COGNITO_PARS_GROUP
} from "concertify/constants";

const log = new Logger("UserService");

const signOutUser = () => {
  localStorage.removeItem("sessionExpires");
  Auth.signOut().then(() => {});
};

const currentAuthenticatedUser = async () => {
  /**
   * Get the currently authenticated user and adapt the Identity provider's result's interface to
   * the User interface before returning it.
   */

  const user = await Auth.currentAuthenticatedUser();
  log.debug("normalizing current authenticated user");
  let userWithNormalizedKeys = _normalizeCustomProperties(user.attributes);
  userWithNormalizedKeys["token"] = user.signInUserSession.idToken.jwtToken;
  const userGroups = user.signInUserSession.idToken.payload["cognito:groups"];
  if (!userGroups) {
    log.error(
      `cannot proceed - the authenticated user is not in any Cognito group. Please make sure that this user is in either the ${COGNITO_CMORS_GROUP} group or the ${COGNITO_PARS_GROUP} group`
    );
  }
  userWithNormalizedKeys["groups"] = userGroups;
  userWithNormalizedKeys["type"] =
    userWithNormalizedKeys.groups.includes(COGNITO_CMORS_GROUP) === true
      ? CMOR
      : PAR;
  userWithNormalizedKeys["username"] = user.username;
  const normalizedUser = { ...userWithNormalizedKeys };
  log.debug("returning normalized authenticated user:", normalizedUser);
  return normalizedUser;
};

const _normalizeCustomProperties = object => {
  /**
   * Return an object originally containing keys in the format of `custom:keyName` to an object with just `keyName` keys.
   * Useful for completely abstracting away Cognito from the React layer.
   *
   * Example:
   * {"custom:type": "par"} => {"type": "par"}
   */
  const newPairs = Object.keys(object).map(key => {
    return { [key.replace("custom:", "")]: object[key] };
  });
  return Object.assign({}, ...newPairs);
};

const UserService = {
  currentAuthenticatedUser: currentAuthenticatedUser,
  signOutUser: signOutUser
};

export default UserService;
