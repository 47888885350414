import concertify from "concertify/core";
import { Logger } from "aws-amplify";

import {
  SELECT_COMMENTS,
  CREATE_COMMENT,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  OPEN_SNACKBAR,
  CREATE_COMMENT_ERROR,
  SELECT_COMMENTS_REQUEST,
  SELECT_COMMENTS_SUCCESS,
  SELECT_COMMENTS_ERROR
} from "./types";

const logger = new Logger("deletePerformance");

export const selectCommentsForPerformance = performanceId => async dispatch => {
  try {
    dispatch({ type: SELECT_COMMENTS_REQUEST });
    const comments = await concertify.comments.getCommentsForPerformance(
      performanceId
    );
    dispatch({
      type: SELECT_COMMENTS,
      payload: comments
    });
    dispatch({ type: SELECT_COMMENTS_SUCCESS });
  } catch (e) {
    logger.error(e);
    dispatch({ type: SELECT_COMMENTS_ERROR, payload: e.message });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "warning",
      message:
        "There was a problem fetching the comments for this performance. Please try again later."
    });
  }
};

export const createCommentForPerformance = newComment => async dispatch => {
  try {
    dispatch({ type: CREATE_COMMENT_REQUEST });

    const result = await concertify.comments.addComment(newComment);
    const comment = result.data;
    dispatch({
      type: CREATE_COMMENT,
      payload: comment
    });

    dispatch({ type: CREATE_COMMENT_SUCCESS });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "success",
      message: "Thank you. Your comment was successfully submitted."
    });
  } catch (e) {
    logger.error(e);
    dispatch({ type: CREATE_COMMENT_ERROR, payload: e.message });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message:
        "Something went wrong while submitting your comment. Please try again later."
    });
  }
};
