import React from "react";

import { PERFORMANCES_PATH } from "concertify/constants";
import GrayRow from "components/structural/GrayRow";
import StatusBadge from "components/status/StatusBadge";
import { simpleDate, titleCase, tryOrUndefined } from "utils";
import {
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS
} from "concertify/constants";

import { withStyles } from "@material-ui/core/styles";
import MusicNoteIcon from "@material-ui/icons/MusicNoteOutlined";
import { Link } from "react-router-dom";
import { formatMoney } from "utils";

import urlJoin from "url-join";

const BadgeComponentFor = status => {
  switch (status) {
    case NEW_STATUS:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
    case SCHEDULED_STATUS:
      return <StatusBadge variant="deepPurple" label={SCHEDULED_STATUS} />;
    case INVOICED_STATUS:
      return <StatusBadge variant="lightBlue" label={INVOICED_STATUS} />;
    case COLLECTED_STATUS:
      return <StatusBadge variant="lightGreen" label={COLLECTED_STATUS} />;
    case DISTRIBUTED_STATUS:
      return <StatusBadge variant="green" label={DISTRIBUTED_STATUS} />;
    default:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
  }
};

/**
 * Component representing a Performance, showing only fields relevant to a PAR
 */

const styles = {
  link: {
    textDecoration: "none"
  },
  info: {
    marginLeft: 10,
    flex: 1
  },
  date: {
    opacity: 0.6
  },
  worksNo: {
    display: "flex",
    justifyConter: "center",
    background: "#96a6ae",
    borderRadius: 16,
    color: "#fff",
    padding: "6px 8px",
    textAlign: "center",
    fontSize: 14,
    lineHeight: 1.4,
    minWidth: 40,
    marginLeft: 15
  },
  number: {
    marginTop: 4
  },
  icon: {
    width: 14
  },
  eventValueContainer: {},
  eventValue: {
    borderBottom: "1px solid #c5cfd3",
    paddingBottom: 2,
    fontSize: 14,
    lineHeight: 1.4,
    textAlign: "center"
  },
  eventValueDate: {
    fontSize: 11,
    textAlign: "center"
  }
};

const PARPerformanceEntry = ({
  classes,
  PerformanceId,
  Status,
  PerformingArtist,
  Date,
  Venue,
  Setlist,
  License
}) => (
  <Link
    data-role="link-to-performance"
    className={classes.link}
    to={urlJoin(PERFORMANCES_PATH, PerformanceId)}
  >
    <GrayRow data-cy="performance">
      {BadgeComponentFor(Status)}

      <div className={classes.info}>
        <b>{PerformingArtist && PerformingArtist.Name}</b>
        <div
          id="performanceDate"
          className={classes.Date}
          data-role="performance-date"
        >
          {Date ? simpleDate(Date) : "No date"}
        </div>
        <div id="venueName">
          {tryOrUndefined(Venue, v => titleCase(v.Name)) || "No venue name"}
        </div>
        <div id="cityAndCountry">
          {tryOrUndefined(Venue, v => titleCase(v.Address.City + " / ")) ||
            "No city / "}
          {tryOrUndefined(Venue, v => v.Address.CountryCode.toUpperCase()) ||
            "No country"}
        </div>
      </div>
      {License && (
        <div id="licenseValue">
          <div id="eventValue" className={classes.eventValue}>
            {License.NetDistributable
              ? formatMoney(License.NetDistributable, License.Currency)
              : formatMoney(License.EstimatedValue, License.Currency) ||
                "Missing value, "}
            {tryOrUndefined(License, l => " " + l.Currency.toUpperCase()) ||
              "missing currency"}
          </div>
          <div id="eventValueDate" className={classes.eventValueDate}>
            {License.PaymentDate
              ? simpleDate(License.PaymentDate)
              : tryOrUndefined(License, l =>
                  simpleDate(l.EstimatedValueDate)
                ) || "Missing value submission date"}
          </div>
        </div>
      )}
      {Setlist !== undefined && (
        <div id="worksNo" className={classes.worksNo}>
          <MusicNoteIcon />
          <div className={classes.number}>
            {tryOrUndefined(Setlist, s =>
              Array.isArray(s) ? s.length : s.Works.length
            )}
          </div>
        </div>
      )}
    </GrayRow>
  </Link>
);

PARPerformanceEntry.defaultProps = {
  PerformanceId: "#",
  Status: NEW_STATUS
};

export default withStyles(styles)(PARPerformanceEntry);
