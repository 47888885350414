// # FIXME: [MYR-817] ensureWorksHavePerformanceDuration: change declaration order to bottom-top to fix linting warnings
/**
 * Return a copy of objWithSetlist where all elements
 * of `objWithSetlist.Setlist.Works` have a performanceDuration
 * attribute.
 */
const ensureWorksHavePerformanceDuration = objWithSetlist =>
  applyToWorks(objWithSetlist, setWorkPerformanceDuration);

/**
 * Return a copy of objWithSetlist with workTransformer applied
 * to each element of objWithSetlist.Setlist.Works, or the original
 * objWithSetlist if it doesn't have eitehr a Setlist or Setlist.Works property.
 */
export const applyToWorks = (objWithSetlist, workTransformer) => {
  if (objWithSetlist.Setlist == null || objWithSetlist.Setlist.Works == null) {
    return objWithSetlist;
  }
  return {
    ...objWithSetlist,
    Setlist: {
      ...objWithSetlist.Setlist,
      Works: objWithSetlist.Setlist.Works.map(workTransformer)
    }
  };
};

/**
 * Return a new Work object with the property PerformanceDuration set
 * to work.OriginalDuration or initialized to 0 if work.OriginalDuration
 * is undefined.
 */
export const setWorkPerformanceDuration = work => {
  if (work.PerformanceDuration != null) {
    return work;
  }
  return {
    ...work,
    PerformanceDuration: toNumberOrUndefined(work.OriginalDuration) || 180
  };
};

/**
 * Return the numeric representation of val if it can be cast into a number,
 * else return undefined
 */
export const toNumberOrUndefined = val => {
  const result = Number(val);
  if (isNaN(result)) {
    return undefined;
  }
  return result;
};

export default ensureWorksHavePerformanceDuration;
