import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => {
  return {
    root: {
      background: "#373762",
      padding: theme.spacing.unit,
      [theme.breakpoints.down("md")]: {
        padding: 10
      }
    },
    center: {
      [theme.breakpoints.up("lg")]: {
        width: 1240
      }
    },
    filters: {
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(2)
      }
    },
    button: {
      color: "hsla(0, 0%, 100%, .8)",
      maxHeight: 35
    },
    current: {
      color: "#fff",
      background: "#8100ff"
    },
    addPerformanceButton: {
      boxShadow: "none",
      backgroundColor: "#9426FF",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#8100ff"
      }
    },
    filtersContainer: {
      display: "flex",
      flexDirection: "row",
      color: "#fff"
    },
    dateRange: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    dateRangeText: {
      marginRight: 12
    },
    dateRangeSeparator: {
      margin: "0 8px"
    },
    datePicker: {
      backgroundColor: "#fff",
      borderRadius: 4,
      "& button": {
        padding: 0
      },
      "& input": {
        padding: "9px",
        width: 90
      }
    },
    filterResults: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    filterSelect: {
      width: 120,
      backgroundColor: "#fff",
      borderRadius: 4,
      textTransform: "capitalize",
      padding: "2px 0 2px 9px"
    },
    filterItem: {
      textTransform: "capitalize"
    },
    filterResultsText: {
      marginLeft: 16,
      marginRight: 12
    },
    search: {
      marginLeft: 24,
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    searchButton: {
      padding: 0,
      color: "#fff"
    },
    searchInput: {
      width: 220,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: "#fff",
      borderRadius: "4px",
      "& button": {},
      "& input": {
        padding: "9px"
      }
    },
    searchChipHolder: {
      position: "absolute",
      backgroundColor: "#fff",
      width: "100%",
      height: "auto",
      padding: 12,
      boxSizing: "border-box",
      borderRadius: "0 0 4px 4px"
    },
    chip: {
      margin: 6,
      maxWidth: 94,
      "& .MuiChip-label": {
        paddingLeft: 6,
        paddingRight: 6
      }
    },
    chipActive: {
      color: "#8100FF",
      borderColor: "#8100FF",
      backgroundColor: "#F0E0FF"
    },
    clearButton: {
      paddingLeft: 16
    }
  };
});

export { styles };
