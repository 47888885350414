import {
  CHANGE_PERFORMANCE_RANGE_FILTER,
  CHANGE_PERFORMANCE_TYPE_FILTER,
  CHANGE_PERFORMANCE_SEARCH_FILTER,
  CHANGE_PERFORMANCE_SEARCH_PARAMETER,
  CHANGE_PERFORMANCE_SORT_BY,
  CHANGE_PERFORMANCE_PAGE_NUMBER,
  CHANGE_PERFORMANCE_ITEMS_PER_PAGE,
  CHANGE_PERFORMANCE_RELEVANCE,
  CLEAR_PERFORMANCES_FILTERS
} from "../actions/types";

const initialState = {
  dateRange: {
    from: null,
    to: null
  },
  search: "",
  searchParameter: "artist",
  type: "All",
  sortBy: "",
  relevance: "",
  pageNumber: 1,
  itemsPerPage: 25
};

const performanceFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PERFORMANCE_RANGE_FILTER:
      const { from, to } = action.payload.dateRange;
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          from: from,
          to: to
        }
      };
    case CHANGE_PERFORMANCE_TYPE_FILTER:
      return {
        ...state,
        type: action.payload
      };
    case CHANGE_PERFORMANCE_SEARCH_FILTER:
      return {
        ...state,
        search: action.payload
      };
    case CHANGE_PERFORMANCE_SEARCH_PARAMETER:
      return {
        ...state,
        search: "",
        searchParameter: action.payload
      };
    case CHANGE_PERFORMANCE_RELEVANCE:
      return {
        ...state,
        pageNumber: 1,
        relevance: action.payload
      };
    case CHANGE_PERFORMANCE_SORT_BY:
      return {
        ...state,
        sortBy: action.payload
      };
    case CHANGE_PERFORMANCE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload
      };
    case CHANGE_PERFORMANCE_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      };
    case CLEAR_PERFORMANCES_FILTERS:
      return initialState;
    default:
      return state;
  }
};

export default performanceFilterReducer;
