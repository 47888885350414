import {
  LOAD_CMOS_REQUEST,
  LOAD_CMOS_SUCCESS,
  LOAD_CMOS_ERROR,
  CLEAR_CMOS
} from "redux/actions/types";

const initialState = {
  CMOs: [],
  loading: false,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_CMOS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_CMOS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        CMOs: action.payload
      };
    case LOAD_CMOS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case CLEAR_CMOS:
      return initialState;
    default:
      return state;
  }
}
