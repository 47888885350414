import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => {
  return {
    root: {
      paddingTop: 5
    }
  };
});

export { styles };
