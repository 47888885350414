import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import ReactPlaceholder from "react-placeholder";

const useStyles = makeStyles(theme => ({
  root: {
    textTransform: "uppercase",
    width: 85,
    fontSize: 10,
    textAlign: "center",
    fontWeight: 400,
    color: "#fff",
    height: 17,
    lineHeight: "17px",
    padding: " 1px 1px 3px 1px",
    borderRadius: 2,
    margin: "auto"
  },
  gray: {
    background: "gray"
  },
  orange: {
    background: "#FF6302"
  },
  deepPurple: {
    background: "#5848F7"
  },
  lightBlue: {
    background: "#00C9C3"
  },
  green: {
    background: "#028924"
  },
  lightGreen: {
    background: "#70Ce7D"
  },
  draft: {
    background: "#00C9C3"
  },
  requested: {
    background: "#FF0000"
  },
  waiting: {
    background: "#FF6302"
  }
}));

const StatusBadge = ({ variant, label, loading }) => {
  const classes = useStyles();
  return (
    <ReactPlaceholder showLoadingAnimation ready={!loading}>
      <div className={[classes.root, classes[variant]].join(" ")}>
        <b>{label}</b>
      </div>
    </ReactPlaceholder>
  );
};

StatusBadge.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf([
    "orange",
    "deepPurple",
    "lightBlue",
    "lightGreen",
    "green",
    "gray"
  ])
};

StatusBadge.defaultProps = {
  label: "unknown",
  variant: "gray",
  loading: false
};

export default StatusBadge;
